import React, { useEffect, useState } from "react";
import Navbar from "../component/shared/Navbar";
import ProfilePicture from "../component/shared/ProfilePicture";
import { IconCount, IconButton } from "../component/button";
import { SelectInputSongsDetailsCustom } from "../component/form/InputField";
import { appstore, dot_gif, googleplay } from "../assets/images";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTrackByPermalink,
  handleLikeSong,
  postComment,
} from "../redux/actions";
import { icon_logo } from "../assets/logo";
import axiosInstance from "../redux/helpers/axios";
import { useAudio } from "../component/context/audio";
import { useModal } from "../component/context/modal";
import toast from "react-hot-toast";

const Divider = ({ marginTop }) => (
  <div
    className="border-b-[0.25px] border-b-gray-800 mt-4"
    style={{ marginTop: marginTop }}
  />
);

const SongDetails = () => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    setTrackList,
    setCurrentTrackIndex,
    currentTrack,
  } = useAudio();

  const { isVisible, showModal, hideModal } = useModal();

  const { permalink } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));

  const [relatedTracks, setRelatedTracks] = useState([]);
  const [artistTracks, setArtistTracks] = useState([]);
  const [liked, setLiked] = useState(false);
  const [comment, setComment] = useState("");

  useEffect(() => {
    if (permalink !== undefined || permalink !== null) {
      dispatch(getTrackByPermalink({ permalink: permalink }));
    }
  }, [permalink]);

  const song = useSelector((state) => state.song);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (song?.track !== null) {
      axiosInstance
        .get(
          `/song/public/get-related-tracks?genre=${
            song?.track?.genre
          }&limit=${5}&currentTrack=${song?.track?._id}`
        )
        .then((res) => {
          setRelatedTracks(res?.data?.tracks);
        })
        .catch((err) => {
          setRelatedTracks([]);
        });
    }
  }, [song?.track]);

  useEffect(() => {
    if (song?.track !== null) {
      axiosInstance
        .get(
          `/song/public/get-artist-tracks?id=${
            song?.track?.user?._id
          }&limit=${5}&currentTrack=${song?.track?._id}`
        )
        .then((res) => {
          setArtistTracks(res?.data?.tracks);
        })
        .catch((err) => {
          setArtistTracks([]);
        });
    }
  }, [song?.track]);

  useEffect(() => {
    const hasLiked = song?.track?.likes?.some(
      (like) => like.user === user?._id
    );
    setLiked(hasLiked);
  }, [song?.track]);

  const D = new Date(song?.track?.createdAt);

  function timeAgo(milliseconds) {
    const now = Date.now();
    const diff = now - milliseconds;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  }

  const handlePlayPause = () => {
    if (isPlaying && song?.track?._id === currentTrack) {
      pauseAudio();
    } else if (!isPlaying && song?.track?._id === currentTrack) {
      playAudio();
      showModal();
    } else {
      setTrackList([song?.track]);
      setCurrentTrackIndex(0);
      showModal();
      // playAudio();
    }
  };

  const handleLike = () => {
    const data = {
      song: song?.track?._id,
      img: user?.avatar?.url,
      type: liked ? "dislike" : "like",
    };
    dispatch(handleLikeSong(data));
  };

  const handleComment = (e) => {
    e.preventDefault();
    if (comment !== "") {
      const data = {
        song: song?.track?._id,
        img: user?.avatar?.url,
        comment: comment,
        name: user?.name,
      };
      dispatch(postComment(data));
      setComment("");
    }
  };

  let musicbuttons = [
    {
      id: 0,
      name: liked ? "Liked" : "Like",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        handleLike();
      },
      svg: liked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="red"
          className="size-3"
        >
          <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          className="size-3"
        >
          <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
        </svg>
      ),
    },
    {
      id: 1,
      name: "Repost",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="size-3"
        >
          <path
            fillRule="evenodd"
            d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 0 1 3.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 1 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 0 0-4.392-4.392 49.422 49.422 0 0 0-7.436 0A4.756 4.756 0 0 0 3.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 1 0 1.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 0 1 3.01-3.01c1.19-.09 2.392-.135 3.605-.135Zm-6.97 6.22a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 0 0 4.392 4.392 49.413 49.413 0 0 0 7.436 0 4.756 4.756 0 0 0 4.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 0 0-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 0 1-3.01 3.01 47.953 47.953 0 0 1-7.21 0 3.256 3.256 0 0 1-3.01-3.01 47.759 47.759 0 0 1-.1-1.759L6.97 15.53a.75.75 0 0 0 1.06-1.06l-3-3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      id: 2,
      name: "Share",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-3"
        >
          <path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
        </svg>
      ),
    },
    {
      id: 3,
      name: "Copy Link",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-3"
        >
          <path
            fillRule="evenodd"
            d="M10.986 3H12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1.014A2.25 2.25 0 0 1 7.25 1h1.5a2.25 2.25 0 0 1 2.236 2ZM9.5 4v-.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75V4h3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      id: 4,
      name: "More",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-3"
        >
          <path d="M2 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM12.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
        </svg>
      ),
    },
  ];

  return (
    <div className="bg-globalBg  min-h-screen pb-4">
      {/* HEADER */}

      <div className=" bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="mx-auto max-w-[1280px] text-white ">
        {song?.loading ? (
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        ) : (
          <>
            {song?.error !== null ? (
              <div className=" min-h-screen flex flex-col justify-center items-center text-white">
                <img src={icon_logo} alt="" height={110} width={110} />
                <div className="mt-2 font-bold text-[48px]">
                  Track not found
                </div>
                <div className="uppercase text-[11px]">
                  We can’t seem to find the track you are looking for.
                </div>
                <Link
                  to={"/"}
                  className="mt-12 hover:scale-95 transition-all ease-in-out font-semibold text-black px-6 py-2 mx-auto rounded-full bg-white"
                >
                  Home
                </Link>
              </div>
            ) : (
              <>
                {/* HEADER PART FOR LARGE AND MEDIUM DEVICE */}
                <div
                  style={{
                    backgroundImage: `url(${encodeURI(song?.track?.img)})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  className="pt-5 lg:block md:block sm:hidden justify-center gap-6 rounded-lg "
                >
                  <div
                    // style={{
                    //   backgroundImage: `url(${song?.track?.img})`,
                    //   backgroundPosition: "center",
                    //   backgroundSize: "cover",
                    // }}
                    className="py-4 lg:px-4 md:px-4 sm:px-0 mx-auto lg:h-[520px] lg:w-[620px] flex flex-col rounded-lg justify-end items-center"
                  >
                    <div
                      style={{
                        background: `linear-gradient(180deg, ${song?.bg?.hex} 0%, ${song?.bg?.hex}80 50%)`,
                      }}
                      className="flex justify-between w-full gap-4 rounded-lg p-2"
                    >
                      <div className=" flex gap-2">
                        {/* PLAY PAUSE BTN */}
                        {isPlaying && currentTrack === song?.track?._id ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-20 hover:scale-95 transition-all ease-in-out"
                            onClick={handlePlayPause}
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="size-20 hover:scale-95 transition-all ease-in-out"
                            onClick={handlePlayPause}
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}

                        {/* TRACK NAME AND ARTIST NAME */}
                        <div className="">
                          <p className="p-1 w-fit bg-dark lg:text-2xl md:text-xl sm:text-lg uppercase rounded-lg">
                            {song?.track?.title?.slice(0, 20)}..
                          </p>
                          <p className="mt-1 w-fit p-1 bg-dark lg:text-xl md:text-lg sm:text-md uppercase rounded-lg">
                            {song?.track?.user?.name}
                          </p>
                        </div>
                      </div>

                      {/* TRACK DURATION AND TAGS */}

                      <div>
                        <p className="p-1 lg:text-lg md:text-sm sm:text-sm ">
                          {timeAgo(D.getTime())}
                        </p>
                        <p className="w-fit text-nowrap mt-1 px-6 py-1.5 bg-gray-400 rounded-full lg:text-md md:text-xs sm:text-xs  font-semibold tracking-tighter">
                          #{song?.track?.tags[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                  {artistTracks.length > 0 && (
                    <div
                      style={{
                        background: `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)) `,
                      }}
                      className="pb-5 rounded-b-lg"
                    >
                      <div className="flex justify-around my-5">
                        Popular Release
                      </div>
                      <div className=" flex justify-center items-center w-full h-[220px] gap-5">
                        {artistTracks.map((track) => (
                          <div
                            className="mb-5"
                            onClick={() =>
                              navigate(`/track/${track?.permalink}`)
                            }
                          >
                            <div
                              className="h-[180px] w-[180px] rounded-lg"
                              style={{
                                background: `url(${encodeURI(track?.img)})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                              }}
                            ></div>
                            <div className="flex flex-col justify-center items-center mt-2">
                              <p className="text-[13px]">
                                {track.title?.slice(0, 20)}
                              </p>
                              <p className="text-[10px]">{track.user.name}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* <ProfilePicture
                    url={song?.track?.img}
                    name={song?.track?.title}
                    isRounded={false}
                    //   height={340}
                    //   width={340}
                    className="lg:h-[520px] lg:w-[520px] md:h-[220px] md:w-[220px] sm:h-[160px] sm:w-[160px] lg:text-[160px] md:text-[160px] sm:text-[160px] font-bold tracking-tighter"
                  /> */}
                </div>
                {/* HEADER PART FOR LARGE AND SMALL/MOBILE DEVICE */}
                <div className=" p-4  lg:hidden md:hidden sm:flex flex-col gap-6">
                  {/* IMAGE */}
                  <div className="w-full">
                    <div
                      style={{
                        paddingTop: "100%",
                        backgroundImage: `url(${encodeURI(song?.track?.img)})`,
                      }}
                      className="h-full bg-cover bg-center text-[160px] font-bold tracking-tighter rounded"
                    />
                  </div>

                  {/* TRACKS INFO */}
                  <div className="flex justify-between gap-4">
                    {/* TRACK NAME , ARTIST NAME AND TRACK UPLOAD TIME */}
                    <div className="">
                      <p className="p-1 w-fit text-lg uppercase">
                        {song?.track?.title?.slice(0, 40)}...
                      </p>
                      <p className="mt-1 w-fit p-1 text-sm uppercase">
                        {song?.track?.user?.name}
                      </p>
                      <p className="p-1 text-xs ">{timeAgo(D.getTime())}</p>
                    </div>

                    {/* PLAY PAUSE BTN */}
                    {isPlaying && currentTrack === song?.track?._id ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-16 hover:scale-95 transition-all ease-in-out"
                        onClick={handlePlayPause}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="size-16 hover:scale-95 transition-all ease-in-out"
                        onClick={handlePlayPause}
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    )}
                  </div>

                  {/* TAGS */}
                  <div className="flex flex-wrap items-start">
                    {song?.track?.tags?.map((tag, index) => {
                      return (
                        <p
                          key={index}
                          className="w-fit text-nowrap mt-1 px-6 py-1.5 bg-gray-400 rounded-full lg:text-md md:text-xs sm:text-xs  font-semibold tracking-tighter"
                        >
                          #{tag}
                        </p>
                      );
                    })}
                  </div>
                </div>
                {/* NAVBAR FOR POPULAR TRACKS, COMMENTS AND STUFFS */}
                <div className="lg:px-8 md:px-6 sm:px-4 ">
                  <div className="flex lg:flex-row md:flex-col sm:flex-col justify-between items-start mt-4">
                    {/* ARTIST, COMMENTS SECTION ###LEFT SIDE STUFF */}

                    <div className="lg:w-[894px]  md:w-full sm:w-full lg:pr-4 md:pr-4 sm:pr-0">
                      <div className=" flex justify-between lg:flex-row md:flex sm:flex-col gap-4 w-full sm:items-center">
                        {/* TRACK RELATED BTN */}
                        <div className="lg:flex md:flex sm:flex gap-3">
                          {" "}
                          {musicbuttons?.map((btn, index) => {
                            if (btn?.name !== "" || btn?.icon !== "")
                              return <IconButton btn={btn} key={index} />;
                          })}
                        </div>
                        {/* TRACK STATS */}
                        <div className="flex gap-2 items-center lg:mt-0 md:mt-0 sm:mt-5">
                          {/* PLAY COUNT */}
                          <IconCount
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
                              </svg>
                            }
                            count={song?.track?.count}
                            fontSize={14}
                            fontColor={"#999999"}
                          />
                          {/* REACT COUNT */}
                          <IconCount
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
                              </svg>
                            }
                            count={song?.track?.likes?.length || 0}
                            fontSize={14}
                            fontColor={"#999999"}
                          />
                          {/* TRACK REPOST */}
                          <IconCount
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="size-4"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            }
                            count={song?.track?.reposts?.length || 0}
                            fontSize={14}
                            fontColor={"#999999"}
                          />
                        </div>
                      </div>
                      <Divider />

                      <div className="mt-4 flex lg:flex-row md:flex-row sm:flex-col  gap-6">
                        {/* ARTIST BASIC INFO */}
                        <div className="flex lg:flex-col md:flex-col sm:flex-row lg:items-start md:items-start sm:items-center gap-4">
                          <ProfilePicture
                            url={song?.track?.user?.avatar?.url}
                            name={song?.track?.user?.name}
                            isRounded={true}
                            //   height={340}
                            //   width={340}
                            className="lg:h-[120px] lg:w-[120px] md:h-[120px] md:w-[120px] sm:h-[72px] sm:w-[72px] lg:text-[60px] md:text-[60px] sm:text-[60px] font-bold tracking-tighter"
                          />

                          {/* ARTIST NAME, STATS */}
                          <div>
                            {/* ARTIST NAME */}
                            <h1
                              className="text-[16px] uppercase mb-1 cursor-pointer hover:underline"
                              onClick={() =>
                                navigate(
                                  `/public/artist/${song?.track?.user?._id}`
                                )
                              }
                            >
                              {song?.track?.user?.name}
                            </h1>
                            {/* FOLLOWER SONGS STAT */}
                            <div className="flex gap-2">
                              <IconCount
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                    className="size-4"
                                  >
                                    <path d="M8.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10.9 12.006c.11.542-.348.994-.9.994H2c-.553 0-1.01-.452-.902-.994a5.002 5.002 0 0 1 9.803 0ZM14.002 12h-1.59a2.556 2.556 0 0 0-.04-.29 6.476 6.476 0 0 0-1.167-2.603 3.002 3.002 0 0 1 3.633 1.911c.18.522-.283.982-.836.982ZM12 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                                  </svg>
                                }
                                count={song?.track?.user?.followers?.length}
                                fontSize={12}
                                fontColor={"#999999"}
                              />
                              <IconCount
                                icon={
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 16 16"
                                    fill="currentColor"
                                    className="size-4"
                                  >
                                    <path d="M14 1.75a.75.75 0 0 0-.89-.737l-7.502 1.43a.75.75 0 0 0-.61.736v2.5c0 .018 0 .036.002.054V9.73a1 1 0 0 1-.813.983l-.58.11a1.978 1.978 0 0 0 .741 3.886l.603-.115c.9-.171 1.55-.957 1.55-1.873v-1.543l-.001-.043V6.3l6-1.143v3.146a1 1 0 0 1-.813.982l-.584.111a1.978 1.978 0 0 0 .74 3.886l.326-.062A2.252 2.252 0 0 0 14 11.007V1.75Z" />
                                  </svg>
                                }
                                count={song?.tracksReleased || 0}
                                fontSize={12}
                                fontColor={"#999999"}
                              />
                            </div>
                          </div>

                          {/* FOLLOW BTN */}
                          <button className="lg:ml-0 md:ml-0 sm:ml-auto lg:px-2 md:px-2 sm:px-4 lg:py-1 md:py-1 sm:py-2 h-fit bg-secondary lg:rounded md:rounded sm:rounded-full  flex items-center gap-1">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              className="lg:size-4 md:size-4 sm:size-5"
                            >
                              <path d="M8.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 13c.552 0 1.01-.452.9-.994a5.002 5.002 0 0 0-9.802 0c-.109.542.35.994.902.994h8ZM12.5 3.5a.75.75 0 0 1 .75.75v1h1a.75.75 0 0 1 0 1.5h-1v1a.75.75 0 0 1-1.5 0v-1h-1a.75.75 0 0 1 0-1.5h1v-1a.75.75 0 0 1 .75-.75Z" />
                            </svg>
                            <p className="lg:text-[12px] md:lg:text-[12px] sm:text-[15px]">
                              Follow
                            </p>
                          </button>
                          {/* REPORT BTN */}
                          <button className=" mt-2 px-2 py-1  rounded lg:flex md:flex sm:hidden items-center gap-1">
                            {" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                              fill="currentColor"
                              className="size-4"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.5 1.709a.75.75 0 0 1 1 0 8.963 8.963 0 0 0 4.84 2.217.75.75 0 0 1 .654.72 10.499 10.499 0 0 1-5.647 9.672.75.75 0 0 1-.694-.001 10.499 10.499 0 0 1-5.647-9.672.75.75 0 0 1 .654-.719A8.963 8.963 0 0 0 7.5 1.71ZM8 5a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2A.75.75 0 0 1 8 5Zm0 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="text-[12px]">Report</p>
                          </button>
                        </div>

                        {/* COMMENTS */}
                        <div className="w-full lg:block md:block sm:block">
                          <div className="flex justify-between items-center">
                            <div className="text-[16px] text-[#999999] flex gap-2 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 16 16"
                                fill="currentColor"
                                className="size-5"
                              >
                                <path d="M1 8.74c0 .983.713 1.825 1.69 1.943.764.092 1.534.164 2.31.216v2.351a.75.75 0 0 0 1.28.53l2.51-2.51c.182-.181.427-.286.684-.294a44.298 44.298 0 0 0 3.837-.293C14.287 10.565 15 9.723 15 8.74V4.26c0-.983-.713-1.825-1.69-1.943a44.447 44.447 0 0 0-10.62 0C1.712 2.435 1 3.277 1 4.26v4.482Z" />
                              </svg>
                              <p>{song?.track?.comments?.length} comments</p>
                            </div>

                            <div>
                              <SelectInputSongsDetailsCustom
                                name={""}
                                // onChange={onChange}
                                // value={value_mm}
                                options={["Newest", "Oldest"]}
                                classNames={"text-[15px]"}
                                width={120}
                                // style={{ borderColor: warning_mm ? "#ef4444" : "#6b7280" }}
                              />
                            </div>
                          </div>
                          <Divider marginTop={12} />

                          <CommentField
                            user={user}
                            comment={comment}
                            setComment={setComment}
                            handleCommnet={handleComment}
                          />

                          <div className="mt-6 flex flex-col gap-4 ">
                            {song?.track?.comments?.length > 0 ? (
                              <>
                                {song?.track?.comments?.map((cmnt, index) => {
                                  return <CommentDiv cmnt={cmnt} key={index} />;
                                })}
                              </>
                            ) : (
                              <>
                                <div className="flex justify-center text-xs mt-6">
                                  Be the first to comment!
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* SONG PROFILE DETAILS ###RIGHT SIDE STUFF*/}
                    <div className="block lg:min-w-[322px] md:min-w-full sm:min-w-full lg:pl-4 md:pl-0 sm:pl-0 lg:mt-0 md:mt-8 sm:mt-8 lg:border-l-[0.25px] md:border-l-0 sm:border-l-0 border-l-gray-800 ">
                      {/* RELATED TRACKS  */}
                      <div>
                        <div className="flex text-gray-400 justify-between items-center ">
                          <p className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="size-6"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.636 4.575a.75.75 0 0 1 0 1.061 9 9 0 0 0 0 12.728.75.75 0 1 1-1.06 1.06c-4.101-4.1-4.101-10.748 0-14.849a.75.75 0 0 1 1.06 0Zm12.728 0a.75.75 0 0 1 1.06 0c4.101 4.1 4.101 10.75 0 14.85a.75.75 0 1 1-1.06-1.061 9 9 0 0 0 0-12.728.75.75 0 0 1 0-1.06ZM7.757 6.697a.75.75 0 0 1 0 1.06 6 6 0 0 0 0 8.486.75.75 0 0 1-1.06 1.06 7.5 7.5 0 0 1 0-10.606.75.75 0 0 1 1.06 0Zm8.486 0a.75.75 0 0 1 1.06 0 7.5 7.5 0 0 1 0 10.606.75.75 0 0 1-1.06-1.06 6 6 0 0 0 0-8.486.75.75 0 0 1 0-1.06ZM9.879 8.818a.75.75 0 0 1 0 1.06 3 3 0 0 0 0 4.243.75.75 0 1 1-1.061 1.061 4.5 4.5 0 0 1 0-6.364.75.75 0 0 1 1.06 0Zm4.242 0a.75.75 0 0 1 1.061 0 4.5 4.5 0 0 1 0 6.364.75.75 0 0 1-1.06-1.06 3 3 0 0 0 0-4.243.75.75 0 0 1 0-1.061ZM10.875 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                                clipRule="evenodd"
                              />
                            </svg>{" "}
                            <span>Related Tracks </span>
                          </p>
                          <button className="text-[14px]"> View all</button>
                        </div>
                        <Divider marginTop={6} />
                        {relatedTracks?.length > 0 ? (
                          <div className="my-2 flex flex-col lg:gap-2.5 md:gap-4 sm:gap-4">
                            {relatedTracks?.map((track, index) => {
                              return <RelatedTrack key={index} track={track} />;
                            })}
                          </div>
                        ) : (
                          <div className="flex justify-center mt-4 text-xs">
                            <p> Related tracks not found.</p>
                          </div>
                        )}
                      </div>
                      {/* IN PLAYLIST  */}

                      {/* <div className="mt-8">
                        <div className="flex text-gray-400 justify-between items-center ">
                          <p className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="size-5"
                            >
                              <path d="M2 4.25A2.25 2.25 0 0 1 4.25 2h6.5A2.25 2.25 0 0 1 13 4.25V5.5H9.25A3.75 3.75 0 0 0 5.5 9.25V13H4.25A2.25 2.25 0 0 1 2 10.75v-6.5Z" />
                              <path d="M9.25 7A2.25 2.25 0 0 0 7 9.25v6.5A2.25 2.25 0 0 0 9.25 18h6.5A2.25 2.25 0 0 0 18 15.75v-6.5A2.25 2.25 0 0 0 15.75 7h-6.5Z" />
                            </svg>{" "}
                            <span>In Playlist </span>
                          </p>
                          <button className="text-[14px]"> View all</button>
                        </div>
                        <Divider marginTop={6} />
                        <div className="my-2 flex flex-col gap-2.5">
                          {song?.inPlaylist?.map((track, index) => {
                            return (
                              <InPlaylistTrack key={index} track={track} />
                            );
                          })}
                        </div>
                      </div> */}

                      {/* LIKES */}
                      <div className="mt-8">
                        <div className="flex text-gray-400 justify-between items-center ">
                          <p className="flex gap-1 justify-center items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="size-5"
                            >
                              <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
                            </svg>{" "}
                            <span>
                              {song?.track?.likes?.length || 0} Likes{" "}
                            </span>
                          </p>
                          <button className="text-[14px]"> View all</button>
                        </div>
                        <Divider marginTop={6} />
                        {song?.track?.likes?.length > 0 ? (
                          <div className="my-2 flex justify-center">
                            {song?.track?.likes
                              ?.slice(-10)
                              .map((react, index) => {
                                if (index < 10)
                                  return (
                                    <ProfilePicture
                                      key={index}
                                      style={{
                                        marginLeft: index !== 0 && "-10px",
                                      }}
                                      url={react?.img}
                                      name={react?.name}
                                      isRounded={true}
                                      //   height={340}
                                      //   width={340}
                                      className="border-2 border-white lg:h-[40px] lg:w-[40px] md:h-[40px] md:w-[40px] sm:h-[40px] sm:w-[40px] lg:text-[18px] md:text-[18px] sm:text-[18px] font-bold tracking-tighter"
                                    />
                                  );
                                else return null;
                              })}
                          </div>
                        ) : (
                          <div className="flex justify-center mt-4 text-xs">
                            <p> No likes yet.</p>
                          </div>
                        )}
                      </div>

                      {/* REPOST */}
                      <div className="mt-8">
                        <div className="flex text-gray-400 justify-between items-center ">
                          <p className="flex gap-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="size-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 4.5c1.215 0 2.417.055 3.604.162a.68.68 0 0 1 .615.597c.124 1.038.208 2.088.25 3.15l-1.689-1.69a.75.75 0 0 0-1.06 1.061l2.999 3a.75.75 0 0 0 1.06 0l3.001-3a.75.75 0 1 0-1.06-1.06l-1.748 1.747a41.31 41.31 0 0 0-.264-3.386 2.18 2.18 0 0 0-1.97-1.913 41.512 41.512 0 0 0-7.477 0 2.18 2.18 0 0 0-1.969 1.913 41.16 41.16 0 0 0-.16 1.61.75.75 0 1 0 1.495.12c.041-.52.093-1.038.154-1.552a.68.68 0 0 1 .615-.597A40.012 40.012 0 0 1 10 4.5ZM5.281 9.22a.75.75 0 0 0-1.06 0l-3.001 3a.75.75 0 1 0 1.06 1.06l1.748-1.747c.042 1.141.13 2.27.264 3.386a2.18 2.18 0 0 0 1.97 1.913 41.533 41.533 0 0 0 7.477 0 2.18 2.18 0 0 0 1.969-1.913c.064-.534.117-1.071.16-1.61a.75.75 0 1 0-1.495-.12c-.041.52-.093 1.037-.154 1.552a.68.68 0 0 1-.615.597 40.013 40.013 0 0 1-7.208 0 .68.68 0 0 1-.615-.597 39.785 39.785 0 0 1-.25-3.15l1.689 1.69a.75.75 0 0 0 1.06-1.061l-2.999-3Z"
                                clipRule="evenodd"
                              />
                            </svg>{" "}
                            <span>{song?.track?.reposts?.length} Reposts </span>
                          </p>
                          <button className="text-[14px]"> View all</button>
                        </div>
                        <Divider marginTop={6} />
                        {song?.track?.reposts?.length > 0 ? (
                          <div className="my-2 flex ">
                            {song?.track?.reposts
                              ?.slice(-10)
                              .map((react, index) => {
                                if (index < 10)
                                  return (
                                    <ProfilePicture
                                      key={index}
                                      style={{
                                        marginLeft: index !== 0 && "-10px",
                                      }}
                                      url={react?.img}
                                      name={react?.name}
                                      isRounded={true}
                                      //   height={340}
                                      //   width={340}
                                      className="border-2 border-white lg:h-[40px] lg:w-[40px] md:h-[40px] md:w-[40px] sm:h-[40px] sm:w-[40px] lg:text-[18px] md:text-[18px] sm:text-[18px] font-bold tracking-tighter"
                                    />
                                  );
                                else return null;
                              })}
                          </div>
                        ) : (
                          <div className="flex justify-center mt-4 text-xs">
                            <p> No reposts yet.</p>
                          </div>
                        )}
                      </div>

                      {/* GO MOBILE */}
                      <div className="mt-8">
                        <div className="flex text-gray-400 justify-between items-center ">
                          <p className="flex gap-1">
                            <span>Go mobile </span>
                          </p>
                        </div>
                        <Divider marginTop={6} />
                        <div className="my-4 flex gap-2">
                          <img src={appstore} width={100} alt="" />
                          <img src={googleplay} width={100} alt="" />
                        </div>

                        <Divider marginTop={12} />

                        <p className="lg:max-w-[200px] md:max-w-full  sm:max-w-full  text-[12px] text-[#999] mt-6">
                          Legal ⁃ Privacy ⁃ Cookie Policy ⁃ Cookie Manager ⁃
                          Imprint ⁃ Artist Resources ⁃ Blog ⁃ Charts ⁃
                          Transparency Reports Language: English (US)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {/* DASH */}
      </div>
    </div>
  );
};

export default SongDetails;

const RelatedTrack = ({ track }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex lg:gap-2 md:gap-4 sm:gap-2 items-center cursor-pointer "
      onClick={() => navigate(`/track/${track?.permalink}`)}
    >
      <ProfilePicture
        url={track?.img}
        name={track?.name}
        isRounded={false}
        //   height={340}
        //   width={340}
        className="lg:h-[50px] lg:w-[50px] md:h-[94px] md:w-[94px] sm:h-[72px] sm:w-[72px] lg:text-[24px] md:text-[24px] sm:text-[24px] font-bold tracking-tighter"
      />

      <div>
        <p className="text-[#999999] lg:text-[14px] md:text-[14px] sm:text-[14px] ">
          {track?.user?.name}
        </p>
        <p className="lg:text-[14px] md:text-[18px] sm:text-[14px]">
          {track?.title?.split("").length > 30
            ? `${track?.title?.slice(0, 30)}...`
            : track?.title}
        </p>
        <div className="flex gap-2 items-center">
          <IconCount
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
              >
                <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
              </svg>
            }
            count={track?.count || 0}
            fontSize={12}
            fontColor={"#999999"}
          />
          <IconCount
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
              >
                <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
              </svg>
            }
            count={track?.likes?.length || 0}
            fontSize={12}
            fontColor={"#999999"}
          />
          <IconCount
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
              >
                <path
                  fillRule="evenodd"
                  d="M8 3.5c-.771 0-1.537.022-2.297.066a1.124 1.124 0 0 0-1.058 1.028l-.018.214a.75.75 0 1 1-1.495-.12l.018-.221a2.624 2.624 0 0 1 2.467-2.399 41.628 41.628 0 0 1 4.766 0 2.624 2.624 0 0 1 2.467 2.399c.056.662.097 1.329.122 2l.748-.748a.75.75 0 1 1 1.06 1.06l-2 2.001a.75.75 0 0 1-1.061 0l-2-1.999a.75.75 0 0 1 1.061-1.06l.689.688a39.89 39.89 0 0 0-.114-1.815 1.124 1.124 0 0 0-1.058-1.028A40.138 40.138 0 0 0 8 3.5ZM3.22 7.22a.75.75 0 0 1 1.061 0l2 2a.75.75 0 1 1-1.06 1.06l-.69-.69c.025.61.062 1.214.114 1.816.048.56.496.996 1.058 1.028a40.112 40.112 0 0 0 4.594 0 1.124 1.124 0 0 0 1.058-1.028 39.2 39.2 0 0 0 .018-.219.75.75 0 1 1 1.495.12l-.018.226a2.624 2.624 0 0 1-2.467 2.399 41.648 41.648 0 0 1-4.766 0 2.624 2.624 0 0 1-2.467-2.399 41.395 41.395 0 0 1-.122-2l-.748.748A.75.75 0 1 1 1.22 9.22l2-2Z"
                  clipRule="evenodd"
                />
              </svg>
            }
            count={track?.reposts?.length || 0}
            fontSize={12}
            fontColor={"#999999"}
          />
          <IconCount
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
              >
                <path d="M1 8.74c0 .983.713 1.825 1.69 1.943.764.092 1.534.164 2.31.216v2.351a.75.75 0 0 0 1.28.53l2.51-2.51c.182-.181.427-.286.684-.294a44.298 44.298 0 0 0 3.837-.293C14.287 10.565 15 9.723 15 8.74V4.26c0-.983-.713-1.825-1.69-1.943a44.447 44.447 0 0 0-10.62 0C1.712 2.435 1 3.277 1 4.26v4.482Z" />
              </svg>
            }
            count={track?.comments?.length}
            fontSize={12}
            fontColor={"#999999"}
          />
        </div>
      </div>
    </div>
  );
};
const InPlaylistTrack = ({ track }) => {
  return (
    <div className="flex gap-2 items-start">
      <ProfilePicture
        url={track?.img}
        name={track?.name}
        isRounded={false}
        //   height={340}
        //   width={340}
        className="lg:h-[50px] lg:w-[50px] md:h-[50px] md:w-[50px] sm:h-[50px] sm:w-[50px] lg:text-[24px] md:text-[24px] sm:text-[24px] font-bold tracking-tighter"
      />

      <div>
        <p className="text-[#999999] text-[14px]">{track?.name}</p>
        <p className="text-[14px] ">{track?.subName}</p>
        <div className="flex gap-2 items-center">
          <IconCount
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="size-4"
              >
                <path d="M2 6.342a3.375 3.375 0 0 1 6-2.088 3.375 3.375 0 0 1 5.997 2.26c-.063 2.134-1.618 3.76-2.955 4.784a14.437 14.437 0 0 1-2.676 1.61c-.02.01-.038.017-.05.022l-.014.006-.004.002h-.002a.75.75 0 0 1-.592.001h-.002l-.004-.003-.015-.006a5.528 5.528 0 0 1-.232-.107 14.395 14.395 0 0 1-2.535-1.557C3.564 10.22 1.999 8.558 1.999 6.38L2 6.342Z" />
              </svg>
            }
            count={track?.react}
            fontSize={12}
            fontColor={"#999999"}
          />
        </div>
      </div>
    </div>
  );
};

const CommentDiv = ({ cmnt }) => {
  const D = new Date(cmnt?.time);
  return (
    <div className="flex justify-between items-center gap-2">
      <div className="flex gap-2">
        <ProfilePicture
          url={cmnt?.img}
          name={cmnt?.name}
          isRounded={true}
          //   height={340}
          //   width={340}
          className="lg:h-[48px] lg:w-[48px] md:h-[48px] md:w-[48px] sm:h-[48px] sm:w-[48px] lg:text-[18px] md:text-[18px] sm:text-[18px] font-bold tracking-tighter"
        />

        <div>
          <div className="flex gap-2">
            <p className="text-[14px] font-bold">{cmnt?.name}</p>
            <p className="text-[#999999] text-[12px]">
              {". "}
              {D.toDateString()}
            </p>
          </div>
          <p className="text-[16px] my-1">{cmnt?.comment}</p>
          {/* <button className=" text-[#999] text-[14px]"> Reply</button> */}
        </div>
      </div>

      {/* <div className="flex flex-col justify-center items-center">
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-6 hover:text-[#F00]"
          >
            <path d="m11.645 20.91-.007-.003-.022-.012a15.247 15.247 0 0 1-.383-.218 25.18 25.18 0 0 1-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0 1 12 5.052 5.5 5.5 0 0 1 16.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 0 1-4.244 3.17 15.247 15.247 0 0 1-.383.219l-.022.012-.007.004-.003.001a.752.752 0 0 1-.704 0l-.003-.001Z" />
          </svg>
        </button>

        <p>{cmnt?.react}</p>
      </div> */}
    </div>
  );
};

const CommentField = ({ user, comment, setComment, handleCommnet }) => {
  return (
    <div className="flex items-center p-2 border-t border-gray-200">
      {/* Profile Picture */}
      <img
        src={user?.avatar?.url} // replace with actual image URL
        alt="Profile"
        className="w-10 h-10 rounded-full mr-2"
      />

      {/* Comment Input */}
      <input
        type="text"
        placeholder="Write a comment"
        className="flex-1 px-4 py-2 rounded-full bg-gray-400 text-gray-100 placeholder-gray-100 focus:outline-none"
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />

      {/* Send Button */}
      <button
        className="ml-2"
        onClick={handleCommnet}
        disabled={comment === "" ? true : false}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6 h-6 w-6 text-gray-500 hover:text-gray-700 transition-colors"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
          />
        </svg>
      </button>
    </div>
  );
};
