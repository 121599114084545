import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CheckBoxOnly } from "../../component/form/InputField";
import { BtnFilled } from "../../component/button";

let notificationsOptions = [
  {
    id: 1,
    title: "Music & Artist Recommendations",
    subtitle: "Music and new releases from artists you follow or might like",
  },
  {
    id: 2,
    title: "Podcast & Show Recommendations",
    subtitle: "Podcasts and shows we think you might like",
  },
  {
    id: 3,
    title: "Audiobooks",
    subtitle: "Audiobooks from authors you follow or might like",
  },
  {
    id: 4,
    title: "In-person Concerts & Events",
    subtitle: "Updates about in-person events we think you’ll like",
  },
  {
    id: 5,
    title: "Livestreams & Virtual Events",
    subtitle: "Updates about virtual events we think you'll like",
  },
  {
    id: 6,
    title: "Upvibez Features & Tips",
    subtitle: "News and tips to get the most out of your Upvibez services",
  },
  {
    id: 7,
    title: "Upvibez Offers & Bundles",
    subtitle: "Special offers for Upvibez Premium and our partners",
  },
  {
    id: 8,
    title: "Upvibez Experiences Made for You",
    subtitle: "Fun Upvibez experiences based on your listening",
  },
  {
    id: 9,
    title: "News & Cultural Moments",
    subtitle:
      "Notifications related to news, society, and other notable moments",
  },
  {
    id: 10,
    title: "Artist & Creator Merchandise",
    subtitle: "The latest merch from your favorite creators",
  },
  { id: 11, title: "Surveys", subtitle: "Feedback surveys to help us improve" },
];

const Notifications = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState([
    { id: 1, email: false, push: false },
    { id: 2, email: false, push: false },
    { id: 3, email: false, push: false },
    { id: 4, email: false, push: false },
    { id: 5, email: false, push: false },
    { id: 6, email: false, push: false },
    { id: 7, email: false, push: false },
    { id: 8, email: false, push: false },
    { id: 9, email: false, push: false },
    { id: 10, email: false, push: false },
    { id: 11, email: false, push: false },
  ]);

  const handleChangesEmail = (e) => {
    let temFormData = formData?.map((item) => {
      if (item?.id == e?.target?.name) {
        return { ...item, email: !item.email };
      }
      return item;
    });
    setFormData(temFormData);
    // console.log(temFormData);
  };

  const handleChangesPush = (e) => {
    let temFormData = formData?.map((item) => {
      if (item?.id == e?.target?.name) {
        return { ...item, push: !item.push };
      }
      return item;
    });
    setFormData(temFormData);
    // console.log(temFormData);
  };

  const handleSaveNotification = () => {
    console.log(formData);
  };
  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Notification settings
        </h1>
        <div>
          <p>
            Pick the notifications you want to get via push or email. These
            preferences only apply to push and email.
          </p>

          <div className="flex justify-between items-center gap-4 mt-6">
            <div></div>
            <div className="flex items-center gap-4">
              <div className="flex flex-col justify-center items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.3}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>

                <h2 className="font-semibold text-[14px]">Email</h2>
              </div>
              <div className="flex flex-col justify-center items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2.3}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>

                <h2 className="font-semibold text-[14px]">Push</h2>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 mt-4">
            {notificationsOptions?.map((op, index) => {
              return (
                <div
                  key={index}
                  className="w-full flex justify-between items-center"
                >
                  <div>
                    <h2 className="text-[16px] font-medium">{op?.title}</h2>
                    <p className="text-[14px] text-gray-500">{op?.subtitle}</p>
                  </div>

                  <div className="flex gap-2">
                    <CheckBoxOnly
                      name={op?.id}
                      value={formData[op?.id - 1]?.email}
                      onChange={handleChangesEmail}
                    />
                    <CheckBoxOnly
                      name={op?.id}
                      value={formData[op?.id - 1]?.push}
                      onChange={handleChangesPush}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex justify-between items-center gap-2">
            <div></div>
            <div className="flex items-center gap-2 mt-6">
              <BtnFilled
                text={"Cancel"}
                onClick={() => navigate(-1)}
                width={110}
                bgColor={""}
                textColor={"white"}
              />
              <BtnFilled
                text={"Save"}
                onClick={handleSaveNotification}
                width={120}
                bgColor={"#6813f2"}
                textColor={"white"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
