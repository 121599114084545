import { songConstant } from "../actions/constants";

const initState = {
  song: {},
  loading: false,
  error: null,
  uploadStatus: false,
  tracks: [],
  userTracks: [],
  track: null,
  featuredTracks: [],
  featuredArtists: [],
  tracksByGenre: [],
  bg: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case songConstant.SONG_UPLOAD_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
        uploadStatus: false,
      };
      break;
    case songConstant.SONG_UPLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        song: action.payload,
        error: null,
        uploadStatus: true,
      };
      break;
    case songConstant.SONG_UPLOAD_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        uploadStatus: false,
      };
      break;
    case songConstant.SONG_BY_ARTIST_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.SONG_BY_ARTIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        tracks: action.payload,
        error: null,
      };
      break;
    case songConstant.SONG_BY_ARTIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.SONG_BY_USER_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.SONG_BY_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        userTracks: action.payload,
        error: null,
      };
      break;
    case songConstant.SONG_BY_USER_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.SONG_BY_PERMALINK_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.SONG_BY_PERMALINK_SUCCESS:
      state = {
        ...state,
        loading: false,
        track: action.payload.track,
        bg: action.payload.bg,
        error: null,
      };
      break;
    case songConstant.SONG_BY_PERMALINK_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.SONG_RESET:
      state = {
        ...initState,
      };
      break;
    case songConstant.SONG_BY_GENRE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.SONG_BY_GENRE_SUCCESS:
      state = {
        ...state,
        loading: false,
        tracksByGenre: action.payload.tracks,
        bg: action.payload.bg,
        error: null,
      };
      break;
    case songConstant.SONG_BY_GENRE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.FEATURED_ARTISTS_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.FEATURED_ARTISTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        featuredArtists: action.payload,
        error: null,
      };
      break;
    case songConstant.FEATURED_ARTISTS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.FEATURED_TRACKS_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case songConstant.FEATURED_TRACKS_SUCCESS:
      state = {
        ...state,
        loading: false,
        featuredTracks: action.payload,
        error: null,
      };
      break;
    case songConstant.FEATURED_TRACKS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.HANDLE_LIKE_REQUEST:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;
    case songConstant.HANDLE_LIKE_SUCCESS:
      state = {
        ...state,
        loading: false,
        track: action.payload,
        error: null,
      };
      break;
    case songConstant.HANDLE_LIKE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case songConstant.POST_COMMENT_REQUEST:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;
    case songConstant.POST_COMMENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        track: action.payload,
        error: null,
      };
      break;
    case songConstant.POST_COMMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
  }
  return state;
};
