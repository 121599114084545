import React, { useEffect, useState } from "react";
import { icon_logo, logopngw } from "../../assets/logo";
import { BtnFilled, BtnOutline } from "../../component/button";
import {
  DateOfBirth,
  PasswordInputFieldWithCondotion,
  PolicyCheckout,
  RadioInputFieldWithSecondaryLabel,
  TextInputField1,
  TextInputFieldWithSecondaryLabel,
} from "../../component/form/InputField";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken, signUp } from "../../redux/actions";
import toast from "react-hot-toast";
import axiosInstance from "../../redux/helpers/axios";
import { dot_gif } from "../../assets/images";

const genderOptions = [
  {
    id: "Man",
    label: "Man",
    value: "Man",
    isDisabled: false,
  },
  {
    id: "Woman",
    label: "Woman",
    value: "Woman",
    isDisabled: false,
  },
  {
    id: "Prefer not to say",
    label: "Prefer not to say",
    value: "Prefer not to say",
    isDisabled: false,
  },
];

let userTypeOptions = [
  {
    id: "User",
    label: "User",
    value: "user",
    msg: "Enjoy streaming music, follow your favorite artists, and create personal playlists.",
    isDisabled: true,
  },
  {
    id: "Artist",
    label: "Artist",
    value: "artist",
    msg: "Share your music with the world, grow your audience, and track your streams.",
    isDisabled: false,
  },
  {
    id: "Business",
    label: "Business",
    value: "business",
    msg: "Promote your brand, curate music for your business, and engage with the community.",
    isDisabled: true,
  },
];

const Divider = () => {
  return <div className="border-t-[0.45px] border-t-gray-600 w-[320px] my-8" />;
};

const DividerWithText = ({ text }) => {
  return (
    <div className="flex justify-center items-center w-[320px] my-8">
      <div className="border-t-[0.45px] border-t-gray-600 w-full "></div>
      <p className="text-[14px] px-2">{text}</p>
      <div className="border-t-[0.45px] border-t-gray-600 w-full "></div>
    </div>
  );
};

const InvitationSignup = () => {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);
  const [type, setType] = useState();
  const [token, setToken] = useState("");

  useEffect(() => {
    setToken(searchParams.get("token"));
  }, []);

  useEffect(() => {
    setLoading(true);
    if (token) {
      axiosInstance
        .post(`/public/invitation/validate-invitation`, { token: token })
        .then((res) => {
          const { invitation } = res.data;
          setFormData({
            ...formData,
            ["email"]: invitation?.email,
            ["name"]: invitation?.name,
            ["refId"]: invitation?.user,
          });
          setLoading(false);
          setValid(true);
        })
        .catch((err) => {
          const { data } = err?.response;
          toast.error(`${data.msg}`);
          setLoading(false);
          setValid(false);
        });
    } else {
      setLoading(false);
      setValid(false);
    }
  }, [token]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [conOne, setConeOne] = useState(false);
  const [conTwo, setConTwo] = useState(false);
  const [conThree, setConThree] = useState(false);
  const [stageNo, setStageNo] = useState(1);
  const [isEmailWrote, setEmailWrote] = useState(false);
  const [isNameWrote, setNameWrote] = useState(false);
  const [isDayWrote, setDayWrote] = useState(false);
  const [isMonthWrote, setMonthWrote] = useState(false);
  const [isYearWrote, setYearWrote] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);
  const [isAccountTypeSelected, setAccountTypeSelected] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    day: "",
    month: "",
    year: "",
    gender: "",
    policy_1: false,
    policy_2: false,
    usertype: "",
    refId: "",
  });

  const handleSignup = () => {
    dispatch(signUp(formData));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
    if (name === "name") setNameWrote((prevState) => true);
    if (name === "password") setPasswordWrote((prevState) => true);
    if (name === "usertype") setAccountTypeSelected((prevState) => true);
    if (name === "day") setDayWrote((prevState) => true);
    if (name === "month") setMonthWrote((prevState) => true);
    if (name === "year") setYearWrote((prevState) => true);
  };

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.authenticate) {
      navigate("/profile");
    }
  }, [auth]);

  useEffect(() => {
    const minLength = 10;
    const letterRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    const numberOrSpecialCharRegex = /[0-9#?!&]/;

    if (formData.password.length < minLength) {
      setConThree(false);
    } else {
      setConThree(true);
    }

    if (!letterRegex.test(formData.password)) {
      setConeOne(false);
    } else {
      setConeOne(true);
    }
    if (!numberOrSpecialCharRegex.test(formData.password)) {
      setConTwo(false);
    } else {
      setConTwo(true);
    }
  }, [formData.password]);

  return (
    <div className="bg-dark flex flex-col items-center text-white min-h-screen">
      <div className="flex flex-col justify-between items-center py-6 m-8 rounded-xl  w-6/12">
        {loading ? (
          <div className="flex flex-col justify-center items-center min-h-screen">
            <div className="text-[25px]">Validating Invitation</div>
            <img src={dot_gif} className="h-[100px]" />
          </div>
        ) : (
          <>
            {valid ? (
              <div className="flex flex-col justify-between items-center ">
                <img
                  src={icon_logo}
                  width={70}
                  height={70}
                  alt=""
                  className="hover:cursor-pointer"
                  onClick={() => navigate("/")}
                />
                {stageNo > 0 && (
                  <div className="w-[420px] mt-3">
                    <div className=" w-full grid grid-cols-4">
                      <div
                        className="w-full border"
                        style={{
                          borderColor: stageNo > 0 ? "#6813f2" : "#6b7280",
                        }}
                      ></div>
                      <div
                        className="w-full border"
                        style={{
                          borderColor: stageNo > 1 ? "#6813f2" : "#6b7280",
                        }}
                      ></div>
                      <div
                        className="w-full border"
                        style={{
                          borderColor: stageNo > 2 ? "#6813f2" : "#6b7280",
                        }}
                      ></div>
                      <div
                        className="w-full border"
                        style={{
                          borderColor: stageNo > 3 ? "#6813f2" : "#6b7280",
                        }}
                      ></div>
                    </div>

                    {/* STEP COUNT */}
                    <div className="mt-5 flex gap-2 items-center w-full">
                      <button
                        onClick={() =>
                          stageNo <= 1
                            ? setStageNo(stageNo)
                            : setStageNo((prevStage) => prevStage - 1)
                        }
                        className="w-[50px] flex justify-end"
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          />
                        </svg>
                      </button>
                      <div className="w-[320px]">
                        <p className="text-[16px] font-bold">
                          Step {stageNo} of 4
                        </p>
                        <p className="text-[16px] font-semibold">
                          {stageNo === 1
                            ? "Create a password"
                            : stageNo === 2
                            ? "You are creating account for"
                            : stageNo === 3
                            ? "Tell us about yourself"
                            : stageNo === 4
                            ? "Terms & Conditions"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {stageNo === 1 && (
                  <div className="mt-6">
                    <div className="flex flex-col justify-between items-center ">
                      <PasswordInputFieldWithCondotion
                        // type={"password"}
                        name={"password"}
                        label={"Password"}
                        placeholder={"Password"}
                        value={formData?.password}
                        onChange={handleInputChange}
                        con1={conOne}
                        con2={conTwo}
                        con3={conThree}
                        style={{ marginBottom: 12 }}
                        warning={isPasswordWrote && formData?.password === ""}
                        warningText={"Please choose a password."}
                      />

                      <BtnFilled
                        text={"Next"}
                        onClick={() => setStageNo((prevStage) => prevStage + 1)}
                        width={320}
                        bgColor={"#6813f2"}
                        textColor={"white"}
                        isDisabled={!isPasswordWrote}
                      />
                    </div>
                  </div>
                )}
                {stageNo === 2 && (
                  <div className="mt-6">
                    <div className="flex flex-col justify-between items-center ">
                      <RadioInputFieldWithSecondaryLabel
                        name={"usertype"}
                        label={"Select an account type:"}
                        placeholder={""}
                        value={formData?.usertype}
                        onChange={handleInputChange}
                        style={{ marginBottom: 28 }}
                        options={userTypeOptions}
                        // isOptionsHorizontal={true}
                        warning={
                          isAccountTypeSelected && formData?.usertype === ""
                        }
                        warningText={"Please choose a password."}
                      />

                      <BtnFilled
                        text={"Next"}
                        onClick={() => setStageNo((prevStage) => prevStage + 1)}
                        width={320}
                        bgColor={"#6813f2"}
                        textColor={"white"}
                        isDisabled={!isAccountTypeSelected}
                      />
                    </div>
                  </div>
                )}
                {stageNo === 3 && (
                  <div className="mt-6">
                    <div className="flex flex-col justify-between items-center ">
                      <TextInputFieldWithSecondaryLabel
                        // type={"password"}
                        name={"name"}
                        label={"Name"}
                        label2={"This name will appear on your profile"}
                        placeholder={""}
                        value={formData?.name}
                        onChange={handleInputChange}
                        warning={isNameWrote && formData?.name === ""}
                        warningText={"Enter a name for your profile."}
                        style={{ marginBottom: 12 }}
                      />

                      <DateOfBirth
                        // type={"password"}
                        name_dd={"day"}
                        name_mm={"month"}
                        name_yy={"year"}
                        label={"Date of birth"}
                        label2={
                          "Why do we need your date of birth? Learn more."
                        }
                        placeholder_dd={"dd"}
                        placeholder_mm={"month"}
                        placeholder_yy={"yyyy"}
                        value_dd={formData?.day}
                        value_mm={formData?.month}
                        value_yy={formData?.year}
                        onChange={handleInputChange}
                        warning_dd={isDayWrote && formData?.day === ""}
                        warning_mm={isMonthWrote && formData?.month === ""}
                        warning_yy={isYearWrote && formData?.year === ""}
                        warningText_dd={"Please enter your date of birth."}
                        warningText_mm={"Select your birth month."}
                        warningText_yy={
                          "Please enter the year of your birth date using four digits (e.g., 1990)."
                        }
                        style={{ marginBottom: 12 }}
                      />

                      <RadioInputFieldWithSecondaryLabel
                        name={"gender"}
                        label={"Gender"}
                        label2={
                          "We use your gender to help personalize our content recommendations and ads for you."
                        }
                        placeholder={""}
                        value={formData?.gender}
                        onChange={handleInputChange}
                        style={{ marginBottom: 28 }}
                        options={genderOptions}
                        isOptionsHorizontal={true}
                      />

                      <BtnFilled
                        text={"Next"}
                        onClick={() => setStageNo((prevStage) => prevStage + 1)}
                        width={320}
                        bgColor={"#6813f2"}
                        textColor={"white"}
                        isDisabled={
                          !isDayWrote &&
                          !isMonthWrote &&
                          !isYearWrote &&
                          !isNameWrote
                        }
                      />
                    </div>
                  </div>
                )}
                {stageNo === 4 && (
                  <div className="mt-6">
                    <div className="flex flex-col justify-between items-center ">
                      <PolicyCheckout
                        // type={"password"}
                        name={"policy_1"}
                        label={
                          "I would prefer not to receive marketing messages from Upvibez"
                        }
                        value={formData?.policy_1}
                        onChange={handleInputChange}
                        style={{ marginBottom: 12 }}
                        textColor={""}
                      />
                      <PolicyCheckout
                        // type={"password"}
                        name={"policy_2"}
                        label={
                          "Share my registration data with Upvibez's content providers for marketing purposes."
                        }
                        value={formData?.policy_2}
                        onChange={handleInputChange}
                        style={{ marginBottom: 12 }}
                        textColor={""}
                      />

                      <div className="w-[320px] text-[14px] mt-6">
                        <p>
                          By clicking on sign-up, you agree to Upvibez's{" "}
                          <Link
                            target="_blank"
                            to={"/terms-and-condition"}
                            className="text-primary underline"
                          >
                            {" "}
                            Terms and Conditions of Use.
                          </Link>
                          {/* <a href=""></a>  */}
                        </p>
                        <p className="mt-4">
                          To learn more about how Upvibez collects, uses, shares
                          and protects your personal data, please see{" "}
                          <Link
                            target="_blank"
                            to={"/privacy-policy"}
                            className="text-primary underline"
                          >
                            {" "}
                            Upvibez's Privacy Policy.
                          </Link>
                          {/* <a href="" className="text-primary underline">
                     
                    </a> */}
                        </p>
                      </div>

                      <BtnFilled
                        text={"Signup"}
                        onClick={handleSignup}
                        width={320}
                        bgColor={"#6813f2"}
                        textColor={"white"}
                        style={{ marginTop: 24 }}
                        // isDisabled={!(formData?.policy_2 && formData?.policy_1)}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="flex flex-col justify-center items-center min-h-screen">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-10 mb-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                  />
                </svg>

                <div className="text-[25px]">
                  Your invitation is not valid or
                </div>
                <div className="text-[25px]">has been expired.</div>
                {/* <img src={dot_gif} className="h-[100px]" /> */}
              </div>
            )}
          </>
        )}

        <div className="mt-6">
          <p className="text-[11px] w-[320px] text-center text-gray-500">
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvitationSignup;
