import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../component/shared/Footer";
import Navbar from "../component/shared/Navbar";
import { TextInputField1 } from "../component/form/InputField";
import { BtnFilled } from "../component/button";

const Reedem = () => {
  const navigate = useNavigate();
  const [isValid, setValid] = useState(true);
  const [formData, setFormData] = useState({
    code: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContinue = () => {};

  return (
    <>
      <div className="min-h-screen bg-white">
        <div className=" bg-dark sticky top-0 z-50">
          <Navbar />
        </div>
        <div className="min-h-[580px]  px-4 py-6 max-w-[480px] mx-auto">
          <div className="">
            <div className="text-dark ">
              <h1 className="text-[36px] font-bold tracking-tighter mt-2 mb-4">
                Redeem your code
              </h1>
              <h2 className="mt-3 mb-6 text-[14px]">
                Enter the PIN on the back of your gift card, or enter the
                premium code from your in-store receipt.
              </h2>
              <div>
                {!isValid && (
                  <div className="flex items-start gap-1 p-4 rounded bg-red-200 mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="red"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>

                    <p className="text-red-500 text-[14px] mt-0.5">
                      Unfortunately this Premium code does not seem to be valid.
                      Try again or contact customer service.
                    </p>
                  </div>
                )}

                <TextInputField1
                  type={"text"}
                  name={"code"}
                  label={"Enter your premium code"}
                  placeholder={""}
                  value={formData?.code}
                  onChange={handleInputChange}
                  labelColor={"black"}
                  inputStyle={{ backgroundColor: "#fff", color: "#000" }}
                  style={{ width: "100%" }}
                />

                <div className="flex flex-col justify-center items-center mt-6">
                  <BtnFilled
                    text={"Continue"}
                    onClick={handleContinue}
                    width={140}
                    bgColor={"#6813f2"}
                    textColor={"white"}
                    isDisabled={formData?.code === ""}
                  />

                  <Link
                    to="/account/payment-method"
                    className="text-[16px] underline mt-6"
                  >
                    Want to use another payment option?
                  </Link>
                  <Link
                    to="/terms-and-condition"
                    className="text-[12px] underline mt-4"
                  >
                    Terms and conditions apply
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Reedem;
