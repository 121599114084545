import React from "react";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";

const PrivacyPolicy = () => {
  return (
    <div className="bg-globalBg  min-h-screen text-white">
      <div className="bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="bg-darkk py-4 px-4 mx-auto mt-4 max-w-[720px]">
        <h1 className="text-[20px] mb-6 text-center">
          PRIVACY POLICY FOR UPVIBEZ PORTAL
        </h1>

        <p className="text-[13px] mb-4">
          This Privacy Policy explains how Upvibez Portal ("Upvibez," "we," or
          "us"), a company registered under number 1404379, with its address at
          RMT RESIDENCE, Dubai, United Arab Emirates, collects, uses, and shares
          personal data. By using our Software as a Service (SaaS) streaming
          platform or our Website (www.upvibez.com), you agree to the collection
          and use of information in accordance with this Privacy Policy.
        </p>
        <p className="text-[13px] mb-4">
          This Privacy Policy covers both the application available on app
          stores and the website.
        </p>

        <h2 className="text-[16px] mb-2">1. About Upvibez</h2>
        <p className="text-[13px] mb-4">
          Upvibez is a SaaS streaming application that focuses on the upload and
          categorization of DJ sets by music genre, mood, atmosphere, and era to
          enhance user experience. The application can also be accessed via the
          Website, where users will be required to complete the necessary
          registration process, which includes the collection of relevant data.
        </p>

        <h2 className="text-[16px] mb-2">2. Data We Collect</h2>
        <p className="text-[13px] mb-4">
          When you register for an account or use our services, we collect the
          following types of personal data:
        </p>
        <ul className="list-disc ml-6 text-[13px] mb-4">
          <li>
            Registration and Payment Data: Full name, address, email, phone
            number.
          </li>
          <li>
            Payment Data: Your payment information, which will be shared with
            Stripe for processing.
          </li>
          <li>
            Additionally, if you provide consent, we may collect your
            geolocation data to enhance the services offered.
          </li>
          <li>
            Identity Verification: In exceptional cases, we may request identity
            documents or selfie-style photos to verify your identity.
          </li>
        </ul>

        <h2 className="text-[16px] mb-2">3. Use of Collected Data</h2>
        <p className="text-[13px] mb-4">
          The data we collect will be used for the following purposes:
        </p>
        <ul className="list-disc ml-6 text-[13px] mb-4">
          <li>Set up an account for you.</li>
          <li>Personalize your account.</li>
          <li>
            Provide the Upvibez app when you download it onto your device.
          </li>
          <li>Access our website.</li>
          <li>
            Enable you to share a link to Upvibez content with someone else.
          </li>
          <li>
            Provide various optional features of the Upvibez platform to enhance
            your experience.
          </li>
          <li>
            To evaluate and develop new features, technologies, and
            improvements.
          </li>
          <li>To comply with a legal obligation that we are subject to.</li>
          <li>For marketing or advertising purposes.</li>
          <li>To fulfill contractual obligations with third parties.</li>
          <li>To establish, exercise, or defend legal claims.</li>
          <li>
            To process your payment, when we use your personal data to let you
            purchase an Upvibez subscription.
          </li>
        </ul>
        <p className="text-[13px] mb-4">
          Cookies: On the Website, operational and tracking cookies will be
          collected; however, you can disable them through your browser
          settings. Please note that some features of our SaaS platform may not
          function properly if cookies are disabled.
        </p>

        <h2 className="text-[16px] mb-2">4. Data Retention</h2>
        <p className="text-[13px] mb-4">
          We retain your personal data only for as long as necessary to provide
          you with the Upvibez service and for Upvibez’s legitimate and
          essential business purposes, such as:
        </p>
        <ul className="list-disc ml-6 text-[13px] mb-4">
          <li>Maintaining the performance of Upvibez.</li>
          <li>
            Making data-driven business decisions about new features and
            offerings.
          </li>
          <li>Complying with our legal obligations.</li>
          <li>Resolving disputes.</li>
          <li>
            Once your personal data is no longer necessary for these purposes,
            we will delete or anonymize it.
          </li>
        </ul>
        <p className="text-[13px] mb-4">
          The data will be stored on a dedicated server, specifically contracted
          for this purpose.
        </p>

        <h2 className="text-[16px] mb-2">5. Sharing of Data</h2>
        <p className="text-[13px] mb-4">
          We will not share your personal data with third parties except as
          necessary to provide our services, such as sharing payment details
          with Stripe. We take precautions to ensure that any third parties we
          work with adhere to strict data protection standards.
        </p>
        <p className="text-[13px] mb-4">
          The data collected may be shared with the relevant app store (iOS,
          Android, or similar) for the purpose of ensuring compliance with
          platform requirements, facilitating downloads, updates, and providing
          a seamless user experience. This sharing of information is done in
          accordance with the privacy policies of the respective app stores.
        </p>
        <p className="text-[13px] mb-4">
          Your data may be shared with authorities and/or in response to court
          orders when required by law. This does not constitute a data breach
          but rather the fulfillment of our legal obligations in compliance with
          applicable regulations.
        </p>

        <h2 className="text-[16px] mb-2">6. Terms of Use</h2>
        <p className="text-[13px] mb-4">
          Please note that this Privacy Policy is not the Terms of Use, which is
          a separate document. The Terms of Use outline the legal contract
          between you and Upvibez for using the Service. It also describes the
          rules of Upvibez and your user rights. You can review the Terms of Use
          for more information about your legal rights and obligations.
        </p>

        <h2 className="text-[16px] mb-2">7. Contact Us</h2>
        <p className="text-[13px] mb-4">
          If you have any questions about this Privacy Policy or how your data
          is handled, please contact us at inquiries@upvibez.com.
        </p>

        <h2 className="text-[16px] mb-2">8. Data Protection Officer (DPO)</h2>
        <p className="text-[13px] mb-4">
          Upvibez has appointed a Data Protection Officer (DPO) to oversee
          compliance with this Privacy Policy and applicable data protection
          laws. If you have any questions, concerns, or requests regarding the
          handling of your personal data, you may contact our DPO at
          inquires@upvibez.com.
        </p>

        <h2 className="text-[16px] mb-2">9. Policy Changes</h2>
        <p className="text-[13px] mb-4">
          This Privacy Policy is a living document and may be updated from time
          to time, with or without prior notice to users, in order to reflect
          changes in our practices or for other operational, legal, or
          regulatory reasons. We encourage users to review this policy
          periodically. This version was last updated on September 7, 2024.
        </p>

        <h2 className="text-[16px] mb-2">10. Report Unauthorized Use</h2>
        <p className="text-[13px] mb-4">
          If you become aware that your data is being misused or accessed
          without your consent, please contact us immediately at the email
          address provided above. We take such matters seriously and will take
          appropriate action to investigate and address any unauthorized use of
          your personal information.
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
