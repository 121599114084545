import { CloseCross } from "../assets/SvgIcons";
import LeftNav from "../component/home/LeftNav";
import { linkArray } from "../assets/data/homeFooterLink";

const MobileDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  //   const [subcatOpen, setSubCat] = useState(-1);
  return (
    <>
      {/* Drawer Toggle Button - you can keep this in Navbar instead */}

      {/* Drawer Overlay */}
      {isDrawerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsDrawerOpen(false)}
        ></div>
      )}

      {/* Drawer Content */}
      <div
        className={`h-screen overflow-y-scroll fixed inset-y-0 left-0 sm:w-10/12 md:w-1/4 bg-dark shadow-lg transform rounded-tr-[16px] rounded-br-[16px] ${
          isDrawerOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-50`}
      >
        <div className="flex justify-between items-center gap-2 p-4">
          <button
            className="btn btn-circle text-white "
            onClick={() => setIsDrawerOpen(false)}
          >
            {CloseCross}
          </button>

          {/* <strong className="text-[14px] text-white ">Menu</strong> */}

          {/* <img
            src={logopngw}
            height={37}
            width={120}
            alt=""
            className="cursor-pointer"
          /> */}
          {/* <div className="w-2/12" /> */}
        </div>

        <nav>
          <LeftNav deviceScreenType={"lg"} linkArray={linkArray} />
        </nav>
        {/* <nav className="mt-2">
          {dummyNavLinks?.map((navlink, index) => {
            if (navlink.url && !navlink.subcategories) {
              return (
                <Link
                  key={index}
                  to={navlink?.url}
                  className={`flex justify-between gap-1 items-center w-full border-b-2 px-4 py-2.5 ${
                    index === subcatOpen
                      ? "border-b-primary font-bold"
                      : "border-b-white"
                  }  `}
                >
                  {navlink?.title}
                </Link>
              );
            } else
              return (
                <>
                  <button
                    key={index}
                    onClick={() =>
                      index === subcatOpen ? setSubCat(-1) : setSubCat(index)
                    }
                    className={`flex justify-between gap-1 items-center w-full border-b-2 px-4 py-2.5  ${
                      index === subcatOpen
                        ? "border-b-primary font-bold"
                        : "border-b-white"
                    } `}
                  >
                    {navlink?.title}{" "}
                    {index === subcatOpen ? ChevronUpMicro : ChevronDownMicro}
                  </button>

                  {index === subcatOpen && (
                    <MoreSubLinks subcatOpen={subcatOpen} />
                  )}
                </>
              );
          })}
        </nav> */}
      </div>
    </>
  );
};

export default MobileDrawer;

// const MoreSubLinks = ({ subcatOpen }) => {
//   return (
//     <div className="">
//       <div
//         className={` transition-all text-[14px] duration-500 ease-in-out overflow-hidden ${
//           subcatOpen !== -1 ? "max-h-[800px] p-4" : "max-h-0 p-0"
//         }`}
//       >
//         <div
//           className={`  transition-opacity duration-300 ${
//             subcatOpen !== -1 ? "opacity-100" : "opacity-0"
//           }`}
//         >
//           <div className={` flex flex-col `}>
//             {dummyNavLinks[subcatOpen]?.subcategories?.map((subcat, index1) => {
//               return (
//                 <div className={`flex flex-col p-2 `}>
//                   <strong key={index1} className="text-[16px] mb-2">
//                     {subcat?.name}
//                   </strong>

//                   {subcat?.image && (
//                     <img
//                       src={subcat?.image}
//                       alt={subcat?.description}
//                       className="mb-2 rounded"
//                     />
//                   )}
//                   {subcat?.description && (
//                     <span className="mb-2">{subcat?.description}</span>
//                   )}

//                   {subcat?.links?.map((links, index2) => {
//                     return (
//                       <Link
//                         key={index2}
//                         to={links?.url}
//                         className={`mb-2  ${
//                           subcat?.description && "underline"
//                         }`}
//                       >
//                         {links?.label}
//                       </Link>
//                     );
//                   })}
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
