import React, { useState } from "react";
import { icon_logo } from "../../assets/logo";
import { BtnFilled } from "../../component/button";
import { TextInputField1 } from "../../component/form/InputField";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axiosInstance from "../../redux/helpers/axios";

const Divider = () => {
  return <div className="border-t-[0.45px] border-t-gray-600 w-8/12 my-11" />;
};

const Home2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [isEmailWrote, setEmailWrote] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    name: "",
    instagram: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
    if (name === "password") setPasswordWrote((prevState) => true);
  };

  const handleLogin = () => {
    const id = toast.loading("Loading...");
    if (!isEmailWrote) {
      toast.error("Email can't be empty.", { duration: 1500, id: id });
    } else {
      axiosInstance
        .post(`/user/request/create`, formData)
        .then((res) => {
          const { msg } = res.data;
          toast.success(`${msg}`, { duration: 2000, id: id });
          setFormData({
            email: "",
            name: "",
            instagram: "",
          });
        })
        .catch((err) => {
          toast.error(`${err?.response?.data?.msg}`, {
            duration: 2000,
            id: id,
          });
        });
    }
  };

  // if (auth.authenticate) {
  //   navigate("/profile");
  // }

  return (
    <div className="bg-globalBg   text-white">
      <div className=" bg-dark flex flex-col justify-between items-center ">
        <div className="flex flex-col  items-center py-6 m-8 rounded-xl  min-w-6/12 px-10">
          <img
            src={icon_logo}
            width={70}
            height={70}
            className="hover:cursor-pointer"
            onClick={() => navigate("/")}
          />
          <h1 className="text-[32px] font-semibold mt-4 mb-0  text-center">
            Exclusive for invited artists
          </h1>
          <h2 className="text-[24px] font-semibold mb-8  text-center">
            Sign up to be considered
          </h2>

          <TextInputField1
            type={"email"}
            name={"email"}
            label={"Email"}
            placeholder={"Enter your email"}
            value={formData?.email}
            onChange={handleInputChange}
          />
          <TextInputField1
            type={"text"}
            name={"name"}
            label={"Full Name"}
            placeholder={"Enter full name"}
            value={formData?.name}
            onChange={handleInputChange}
          />
          <TextInputField1
            type={"text"}
            name={"instagram"}
            label={"Instagram"}
            placeholder={"Enter your Instagram handle"}
            value={formData?.instagram}
            onChange={handleInputChange}
            style={{ marginBottom: 12 }}
          />

          <BtnFilled
            text={"Submit"}
            onClick={() => handleLogin()}
            width={320}
            bgColor={"#6813f2"}
            textColor={"white"}
          />

          <Divider />

          <div className="text-[16px] flex justify-center gap-1 pb-12">
            <p className="text-gray-500">Already have an account?</p>{" "}
            <Link to="/login" className="underline">
              Login
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4 bg-dark py-9 flex justify-center items-center w-full px-4">
        <a href="" className="text-[11px] text-gray-500">
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </a>
      </div>
    </div>
  );
};

export default Home2;
