import axiosInstance from "../helpers/axios";
import { albumConstant } from "./constants";
import toast from "react-hot-toast";

export const createAlbum = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: albumConstant.CREATE_ALBUM_REQUEST });
      const res = await axiosInstance.post(`/user/album/create`, data);

      if (res.status === 201) {
        const { album, msg } = res.data;
        dispatch({
          type: albumConstant.CREATE_ALBUM_SUCCESS,
          payload: album,
        });
        dispatch(getAlbumByUser());
        toast.success(`${msg}`, { duration: 1500, id: id });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: albumConstant.CREATE_ALBUM_FAILURE,
        payload: data,
      });
      toast.error(`${data?.msg}`, { duration: 1500, id: id });
    }
  };
};

export const getAlbumByUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: albumConstant.GET_ALBUM_REQUEST });
      const res = await axiosInstance.get(`/user/album/get`);

      if (res.status === 200) {
        const { albums } = res.data;
        dispatch({
          type: albumConstant.GET_ALBUM_SUCCESS,
          payload: albums,
        });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: albumConstant.GET_ALBUM_FAILURE,
        payload: data,
      });
    }
  };
};

export const getAlbumById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: albumConstant.GET_PUBLIC_ALBUM_REQUEST });
      const res = await axiosInstance.get(`/public/album/get?id=${id}`);

      if (res.status === 200) {
        dispatch({
          type: albumConstant.GET_PUBLIC_ALBUM_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      dispatch({ type: albumConstant.GET_PUBLIC_ALBUM_FAILURE });
      toast.error("Can't perform this action. Try again later.");
    }
  };
};
