import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const OrderHistory = () => {
  const navigate = useNavigate();
  const orderHistoryList = [
    // {
    //   dateIssued: "Sep 10, 2024",
    //   orderNumber: "2479594313738106-1-3",
    //   paymentMethod: "Card（#井#井 #＃＃＃ ＃＃## 9909）",
    //   retailer: "Upvibez AB",
    //   address: "Regeringsgatan 19, SE-111 53 Stockholm, Sweden",
    //   vatID: "SE556703748501",
    //   product: "Premium for Students",
    //   moms: "BDT 0.00",
    //   totalTax: "BDT 0.00",
    //   total: "BDT 99.00",
    //   status: "Paid",
    //   receipt: "More details",
    //   msg: "You will continue to receive Upvibez Premium at the current subscription price of BDT 99.00 as long as you remain a student. You can cancel anytime from your Upvibez account. If you are no longer a student, your Upvibez Premium subscription will be cancelled.",
    // },
    // {
    //   dateIssued: "Sep 8, 2024",
    //   orderNumber: "2479594313738106-1-2",
    //   paymentMethod: "Card（#井#井 #＃＃＃ ＃＃## 9909）",
    //   retailer: "Upvibez AB",
    //   address: "Regeringsgatan 19, SE-111 53 Stockholm, Sweden",
    //   vatID: "SE556703748501",
    //   product: "Premium for Students",
    //   moms: "BDT 0.00",
    //   totalTax: "BDT 0.00",
    //   total: "BDT 99.00",
    //   status: "Paid",
    //   receipt: "More details",
    //   msg: "You will continue to receive Upvibez Premium at the current subscription price of BDT 99.00 as long as you remain a student. You can cancel anytime from your Upvibez account. If you are no longer a student, your Upvibez Premium subscription will be cancelled.",
    // },
    // {
    //   dateIssued: "Sep 7, 2024",
    //   orderNumber: "2479594313738106-1-1",
    //   paymentMethod: "Card（#井#井 #＃＃＃ ＃＃## 9909）",
    //   retailer: "Upvibez AB",
    //   address: "Regeringsgatan 19, SE-111 53 Stockholm, Sweden",
    //   vatID: "SE556703748501",
    //   product: "Premium for Students",
    //   moms: "BDT 0.00",
    //   totalTax: "BDT 0.00",
    //   total: "BDT 99.00",
    //   status: "Paid",
    //   receipt: "More details",
    //   msg: "You will continue to receive Upvibez Premium at the current subscription price of BDT 99.00 as long as you remain a student. You can cancel anytime from your Upvibez account. If you are no longer a student, your Upvibez Premium subscription will be cancelled.",
    // },
    // {
    //   dateIssued: "Sep 12, 2023",
    //   orderNumber: "2479594313738105-1-1",
    //   paymentMethod: "Card（#井#井 #＃＃＃ ＃＃## 9909）",
    //   retailer: "Upvibez AB",
    //   address: "Regeringsgatan 19, SE-111 53 Stockholm, Sweden",
    //   vatID: "SE556703748501",
    //   product: "Upvibez Premium",
    //   moms: "BDT 0.00",
    //   totalTax: "BDT 0.00",
    //   total: "BDT 199.00",
    //   status: "Paid",
    //   receipt: "More details",
    //   msg: "You will continue to receive Upvibez Premium at the current subscription price of BDT 99.00 as long as you remain a student. You can cancel anytime from your Upvibez account. If you are no longer a student, your Upvibez Premium subscription will be cancelled.",
    // },
    // {
    //   dateIssued: "Sep 9, 2023",
    //   orderNumber: "2479594313738104-1-1",
    //   paymentMethod: "Card（#井#井 #＃＃＃ ＃＃## 9909）",
    //   retailer: "Upvibez AB",
    //   address: "Regeringsgatan 19, SE-111 53 Stockholm, Sweden",
    //   vatID: "SE556703748501",
    //   product: "Upvibez Premium",
    //   moms: "BDT 0.00",
    //   totalTax: "BDT 0.00",
    //   total: "BDT 0.00",
    //   status: "No payment",
    //   receipt: "N/A",
    //   msg: "You will continue to receive Upvibez Premium at the current subscription price of BDT 99.00 as long as you remain a student. You can cancel anytime from your Upvibez account. If you are no longer a student, your Upvibez Premium subscription will be cancelled.",
    // },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Order history
        </h1>
        <div>
          {orderHistoryList?.length === 0 ? (
            <div className="flex gap-2 w-full bg-[#2A2A2A] rounded p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>

              <p>You have no payments or refunds from the past 2 years.</p>
            </div>
          ) : (
            <div>
              <div className="w-full " style={{ borderSpacing: "0 10px" }}>
                <div>
                  <div className="w-full lg:text-[16px] sm:text-[11px] flex px-2 py-2.5 justify-between lg:gap-1 sm:gap-3 text-[14px] text-gray-400 border-b-[0.45px] border-b-gray-700">
                    <p className="text-left w-2/12"> Date issued</p>
                    <p className="text-left w-4/12"> Description</p>
                    <p className="text-left w-2/12"> Amount</p>
                    <p className="text-left w-2/12"> Status</p>
                    <p className="text-left w-2/12"> Receipt</p>
                  </div>
                </div>

                <div>
                  {orderHistoryList?.map((order, index) => {
                    return (
                      <div
                        key={index}
                        className="w-full lg:text-[16px] sm:text-[12px] px-2 py-3  flex justify-between lg:gap-1 sm:gap-3  border-b-[0.45px] border-b-gray-700"
                      >
                        <p className="text-left  w-2/12">{order?.dateIssued}</p>
                        <p className="text-left  w-4/12">{order?.product}</p>
                        <p className="text-left  w-2/12">{order?.total}</p>
                        <div className="text-left  w-2/12 flex items-center gap-2">
                          {" "}
                          <div
                            className={
                              order?.status === "Paid"
                                ? "h-[8px] w-[8px]  rounded-full bg-green-600"
                                : "h-[8px] w-[8px]  rounded-full bg-gray-600"
                            }
                          />{" "}
                          <p className="w-9/12"> {order?.status}</p>
                        </div>
                        <Link
                          to={`/account/receipt?orderNumber=${order?.orderNumber}`}
                          state={{ orderDetails: order, message: order?.msg }} // Correct way to pass state
                          className={
                            order?.receipt === "N/A"
                              ? "text-left w-2/12 "
                              : "text-left w-2/12 underline "
                          }
                        >
                          {order?.receipt}
                        </Link>
                      </div>
                    );
                  })}

                  <div className="mt-6 w-full flex justify-between items-center">
                    <div></div>
                    <div className="flex gap-2 items-center">
                      <button className="px-3 py-1.5 bg-gray-800 rounded-lg">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5 8.25 12l7.5-7.5"
                          />
                        </svg>
                      </button>
                      <button className="px-3 py-1.5 bg-gray-800 rounded-lg">
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
