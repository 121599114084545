import React, { useState } from "react";
import { wave_2 } from "../../assets/images";
import { useAudio } from "../context/audio";
import { useModal } from "../context/modal";

const PlaylistSection = ({
  data,
  isPlaying,
  setTrackList,
  setCurrentTrackIndex,
  playAudio,
  pauseAudio,
  showModal,
  currentTrack,
  setShow,
}) => {
  // const data = {
  //   img: "https://i.ibb.co.com/MVG0Jjh/sunday-night-party-video-flyer-design-template-f09c1e9acf4912e1dce68fb794369d57-screen.jpg",
  //   name: "Arijit Singh Radio",
  //   artists: ["arijit", "singh", "atif", "aslam"],
  //   saves: 120120,
  //   playtime: "3 hr 45 min",
  //   songs: [...song],
  // };

  // const [isPlaying, setPlaying] = useState(false);

  return (
    <div className="bg-dark text-white pr-2">
      <div
        style={{
          backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 0), ${data?.bg?.hex})`,
        }}
      >
        <Header data={data} />
        <Contoller
          songs={data?.songs}
          isPlaying={isPlaying}
          setTrackList={setTrackList}
          setCurrentTrackIndex={setCurrentTrackIndex}
          playAudio={playAudio}
          pauseAudio={pauseAudio}
          showModal={showModal}
          currentTrack={currentTrack}
          setShow={setShow}
        />
      </div>

      <div>
        <TrackListsTable
          songs={data?.songs}
          data={data}
          currentTrack={currentTrack}
          isPlaying={isPlaying}
        />
      </div>
    </div>
  );
};

export default PlaylistSection;

const Header = ({ data, setShow }) => {
  const encodedUrl = encodeURI(data?.img);
  console.log(data.type);
  return (
    <div className="min-h-[216px] rounded-t-lg p-2 flex items-center gap-6">
      {/* IMAGE */}
      <div className="w-[200px]">
        {data?.typed === "Liked" ? (
          <div
            style={{
              background: "linear-gradient(135deg, #A956E2, #D383E3, #FFC1E3)",

              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            alt=""
            className={
              "rounded-xl lg:h-[148px] lg:w-[148px] lg:h-[136px] lg:w-[136px] sm:h-[120px] sm:w-[120px] flex justify-center items-center"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="lg:size-15 md:size-13 sm:size-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
              />
            </svg>
          </div>
        ) : (
          <div
            style={{
              paddingTop: "100%",
              backgroundImage: `url(${encodedUrl})`,
            }}
            className="h-full bg-cover bg-center text-[160px] font-bold tracking-tighter rounded"
          />
        )}
      </div>
      {/* PLAYLIST NAME , ARTISTS AND STATS */}
      <div className="pl-4">
        <p className="w-fit lg:text-6xl md:text-4xl sm:text-2xl font-black">
          {data?.name}
        </p>

        <div className="flex">
          {data?.artists?.map((artist, index) => {
            return (
              <div className="flex">
                <p
                  key={index}
                  className=" w-fit lg:text-lg md:text-lg sm:text-md gap-1"
                >
                  {artist}
                  {index + 1 === data?.artists?.length ? "" : ", "}
                </p>
              </div>
            );
          })}
        </div>

        <div className="flex gap-3 text-sm mt-4">
          <p>{data?.saves} saves</p>
          {/* <p>{data?.playtime}</p> */}
          <p>{data?.songs?.length} songs</p>
        </div>
      </div>
    </div>
  );
};

const Contoller = ({
  songs,
  isPlaying,
  setTrackList,
  setCurrentTrackIndex,
  playAudio,
  pauseAudio,
  showModal,
  currentTrack,
  setShow,
}) => {
  const handlePlayPause = () => {
    if (isPlaying && songs?.some((s) => s?._id === currentTrack)) {
      pauseAudio();
    } else if (!isPlaying && songs?.some((s) => s?._id === currentTrack)) {
      playAudio();
      showModal();
    } else {
      setTrackList(songs);
      setCurrentTrackIndex(0);
      showModal();
      // playAudio();
    }
  };
  return (
    <div className=" flex  items-center mx-auto py-6 px-2">
      <div className="w-10/12 flex items-center gap-5 ">
        {/* PLAY PAUSE BTN */}
        <button>
          {isPlaying && songs?.some((s) => s?._id === currentTrack) ? (
            // && currentTrack === song?.track?._id
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-20 hover:scale-95 transition-all ease-in-out"
              // onClick={handlePlayPause}
              onClick={() => handlePlayPause()}
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="size-20 hover:scale-95 transition-all ease-in-out"
              // onClick={handlePlayPause}
              onClick={() => handlePlayPause()}
            >
              <path
                fillRule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>

        <button className="w-fit" onClick={() => setShow(true)}>
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-9"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
        </button>

        {/* <button className="w-fit">
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-9"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM18.75 12a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"
            />
          </svg>
        </button> */}
      </div>

      <div className="w-[2/12] flex mx-auto items-center gap-2">
        <p>List</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
          />
        </svg>
      </div>
    </div>
  );
};

const TrackListsTable = ({ songs, currentTrack, isPlaying }) => {
  // console.log(songs);

  const { pauseAudio, playAudio, setTrackList, setCurrentTrackIndex } =
    useAudio();
  const { showModal } = useModal();

  const handlePlayPause = (s, i) => {
    if (isPlaying && currentTrack === s?._id) {
      pauseAudio();
    } else if (!isPlaying && currentTrack === s?._id) {
      playAudio();
    } else {
      setTrackList(songs);
      setCurrentTrackIndex(i);
      showModal();
      // playAudio();
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Function to perform on hover
  const handleMouseEnter = (i) => {
    setHoveredIndex(i);
    // You can perform any other action here
  };

  // Function to perform when hover ends
  const handleMouseLeave = () => {
    setHoveredIndex(null);
    // You can perform any other action here
  };

  return (
    <div className="mx-auto py-6 px-2 ">
      {/* TABLE HEADER */}
      <div className="border-b-[0.5px] border-b-gray-700 px-6 py-2 flex items-center text-sm ">
        <div className="lg:w-5/12 md:w-5/12 sm:w-8/12">
          <span># Title</span>
        </div>
        <div className="lg:w-3/12 md:w-3/12 sm:w-3/12">
          <span>Genre</span>
        </div>
        <div className="w-3/12 lg:block md:block sm:hidden">
          <span>Date added</span>
        </div>
        <div className="lg:w-3/12 md:w-3/12 sm:w-1/12">
          <span>Plays</span>
        </div>
      </div>
      {/* TABLE BODY/ROW */}
      <div className="mt-2">
        {songs?.map((song, index) => {
          const D = new Date(song?.createdAt);
          return (
            <div
              key={index}
              className=" px-6 py-2 flex items-center text-sm mb-2"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
            >
              <div className="lg:w-5/12 md:w-5/12 sm:w-8/12 flex items-center gap-2">
                {/* INDEX */}

                {hoveredIndex === index ? (
                  <>
                    {isPlaying && song?._id === currentTrack ? (
                      // && currentTrack === song?.track?._id
                      <div className="w-[50px] lg:block md:block sm:hidden flex items-center justify-center text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-10"
                          // onClick={handlePlayPause}
                          onClick={() => handlePlayPause(song, index)}
                          style={{ marginLeft: -15 }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="w-[50px] lg:block md:block sm:hidden flex items-center justify-center text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="size-10"
                          // onClick={handlePlayPause}
                          onClick={() => handlePlayPause(song, index)}
                          style={{ marginLeft: -15 }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="w-[50px] lg:block md:block sm:hidden flex items-center justify-center">
                    {isPlaying && currentTrack === song?._id ? (
                      <img
                        src={wave_2}
                        className="h-[30px] w-[30px] rounded"
                        style={{ marginLeft: -10 }}
                      />
                    ) : (
                      <div className="w-[50px] lg:block md:block sm:hidden">
                        {index + 1}
                      </div>
                    )}
                  </div>
                )}
                <div className="lg:hidden md:hidden sm:block">{index + 1}</div>

                {/* IMAGE */}
                <div className="w-[42px] ml-3">
                  <div
                    style={{
                      paddingTop: "100%",
                      backgroundImage: `url(${song?.img})`,
                    }}
                    className="h-full bg-cover bg-center text-[160px] font-bold tracking-tighter rounded"
                  />
                </div>
                {/* TITLE AND ARTIST */}
                <div>
                  <p
                    className={
                      currentTrack === song?._id
                        ? "text-[#E898D4]"
                        : "text-white"
                    }
                  >
                    {song?.title?.slice(0, 40)}..
                  </p>
                  <p className="text-[12px] text-gray-400">
                    {song?.user?.name}
                  </p>
                </div>
              </div>
              <div className="w-3/12">
                <span>{song?.genre}</span>
              </div>
              {/* <div className="lg:w-3/12 md:w-3/12 sm:hidden">
                <span>{D.toDateString()}</span>
              </div> */}
              <div className="w-3/12 lg:block md:block sm:hidden">
                <span>{D.toDateString()}</span>
              </div>
              <div className="lg:w-3/12 md:w-3/12 sm:w-1/12 flex">
                <span>{song?.count}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
