import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../component/shared/Navbar";
import ProfilePicture from "../../component/shared/ProfilePicture";
import { IconButton, SmallIconButton } from "../../component/button";
import { getTracksByArtist } from "../../redux/actions";
import { useAudio } from "../../component/context/audio";
import { useModal } from "../../component/context/modal";
import { dot_gif } from "../../assets/images";
import { appstore, googleplay } from "../../assets/images";
import axiosInstance from "../../redux/helpers/axios";

let btnList = [
  {
    id: 0,
    name: "All",
    tag: "",
    isDisabled: false,
  },
  {
    id: 1,
    name: "Popular tracks",
    tag: "",
    isDisabled: false,
  },
  {
    id: 2,
    name: "Tracks",
    tag: "",
    isDisabled: false,
  },
  {
    id: 3,
    name: "Albums",
    tag: "",
    isDisabled: false,
  },
  {
    id: 4,
    name: "Playlists",
    tag: "",
    isDisabled: false,
  },
  {
    id: 5,
    name: "Reposts",
    tag: "",
    isDisabled: false,
  },
  {
    id: 6,
    name: "Events",
    tag: "",
    isDisabled: false,
  },
  {
    id: 7,
    name: "Gallery",
    tag: "",
    isDisabled: false,
  },
];

let musicbuttons = [
  {
    id: 0,
    name: "0",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-3"
      >
        <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
      </svg>
    ),
  },
  {
    id: 1,
    name: "Repost",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="size-3"
      >
        <path
          fillRule="evenodd"
          d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 0 1 3.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 1 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 0 0-4.392-4.392 49.422 49.422 0 0 0-7.436 0A4.756 4.756 0 0 0 3.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 1 0 1.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 0 1 3.01-3.01c1.19-.09 2.392-.135 3.605-.135Zm-6.97 6.22a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 0 0 4.392 4.392 49.413 49.413 0 0 0 7.436 0 4.756 4.756 0 0 0 4.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 0 0-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 0 1-3.01 3.01 47.953 47.953 0 0 1-7.21 0 3.256 3.256 0 0 1-3.01-3.01 47.759 47.759 0 0 1-.1-1.759L6.97 15.53a.75.75 0 0 0 1.06-1.06l-3-3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: "Share",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
      </svg>
    ),
  },
  {
    id: 3,
    name: "Copy Link",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path
          fillRule="evenodd"
          d="M10.986 3H12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1.014A2.25 2.25 0 0 1 7.25 1h1.5a2.25 2.25 0 0 1 2.236 2ZM9.5 4v-.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75V4h3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "More",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path d="M2 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM12.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
      </svg>
    ),
  },
];

const MusicDiv = ({
  isPlaying,
  playAudio,
  pauseAudio,
  showModal,
  track,
  setTrackList,
  setCurrentTrackIndex,
  index,
  tracks,
  currentTrack,
}) => {
  const handlePlayPause = () => {
    if (isPlaying && currentTrack === track?._id) {
      pauseAudio();
    } else if (!isPlaying && currentTrack === track?._id) {
      playAudio();
    } else {
      setTrackList(tracks?.slice().reverse());
      setCurrentTrackIndex(index);
      showModal();
    }
  };

  const D = new Date(track?.createdAt);

  function timeAgo(milliseconds) {
    const now = Date.now();
    const diff = now - milliseconds;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  }
  const navigate = useNavigate();

  return (
    <div className="flex mb-6 gap-4">
      <img
        src={track?.img}
        alt=""
        height={180}
        width={180}
        className="lg:h-[100px] lg:w-[100px] md:h-[120px] md:w-[120px] sm:h-[80px] sm:w-[80px]"
        onClick={() => navigate(`/track/${track?.permalink}`)}
      />
      <div className="w-full">
        <div className=" flex justify-between items-center">
          <div className="flex items-center gap-2">
            <button
              className="lg:block md:block sm:hidden"
              onClick={handlePlayPause}
            >
              {isPlaying && currentTrack === track?._id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            <div
              className="lg:mr-auto md:mr-auto sm:mr-2 cursor-pointer"
              onClick={() => navigate(`/track/${track?.permalink}`)}
            >
              <p className="text-[13px] text-gray-400">{track?.user?.name}</p>
              <p className="text-[16px]">{track?.title}</p>
            </div>
          </div>
          <>
            <div className="lg:flex md:flex sm:hidden flex-col gap-1">
              <p className="text-[12px]">{timeAgo(D.getTime())}</p>
              <p className="px-1.5 text-center text-[11px] bg-gray-500 rounded-full">
                {track?.tags?.length > 0 && `#${track?.tags[0]}`}
              </p>
            </div>
          </>
        </div>

        <div className="lg:flex md:flex sm:hidden  gap-1.5 items-center mt-4">
          {musicbuttons?.map((btn, index) => {
            if (btn?.name !== "" || btn?.icon !== "")
              return <SmallIconButton btn={btn} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

const Divider = ({ marginTop }) => (
  <div
    className="border-b-[0.25px] border-b-gray-800 mt-4"
    style={{ marginTop: marginTop }}
  />
);

const Artist = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [user, setUser] = useState({});

  const {
    isPlaying,
    playAudio,
    pauseAudio,
    setTrackList,
    currentTrackIndex,
    setCurrentTrackIndex,
    currentTrack,
  } = useAudio();

  const { isVisible, showModal, hideModal } = useModal();

  const [activeBtn, setActiveBtn] = useState(0);

  const song = useSelector((state) => state?.song);

  useEffect(() => {
    dispatch(getTracksByArtist({ artist: id }));
  }, []);

  useEffect(() => {
    axiosInstance
      .get(`public/artist/get-details?id=${id}`)
      .then((res) => {
        const { user } = res.data;
        setUser(user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let SocialLinks = [
    {
      name: "Instagram",
      link: user?.social?.ig,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
      ),
    },
    {
      name: "Twitter",
      link: user?.social?.x,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      ),
    },
    {
      name: "Facebook",
      link: user?.social?.fb,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      ),
    },
  ];

  let iconBtnList = [
    {
      id: 1,
      name: "Follow",
      tag: "",
      isDisabled: false,
      icon: "https://icons.veryicon.com/png/o/miscellaneous/3vjia-icon-line/follow-42.png",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-4"
        >
          <path d="M8.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 13c.552 0 1.01-.452.9-.994a5.002 5.002 0 0 0-9.802 0c-.109.542.35.994.902.994h8ZM12.5 3.5a.75.75 0 0 1 .75.75v1h1a.75.75 0 0 1 0 1.5h-1v1a.75.75 0 0 1-1.5 0v-1h-1a.75.75 0 0 1 0-1.5h1v-1a.75.75 0 0 1 .75-.75Z" />
        </svg>
      ),
    },
    {
      id: 2,
      name: "Share",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-4"
        >
          <path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
        </svg>
      ),
    },
    {
      id: 3,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
    {
      id: 4,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
    {
      id: 5,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
  ];

  return (
    <>
      <div className="bg-globalBg  min-h-screen ">
        {/* HEADER */}

        <div className=" bg-dark sticky top-0 z-50">
          <Navbar />
        </div>
        {song?.loading ? (
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        ) : (
          <>
            {/* <div
              style={{
                backgroundImage: user?.cover
                  ? `url(${user?.cover?.url})`
                  : null,

                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className=" bg-bgMoreDark py-4 lg:px-4 md:px-4 sm:px-0 mx-auto max-w-[1280px] flex justify-between items-center"
            >
              <div className=" w-full p-4 flex justify-between ">
                <div className="flex lg:gap-4 md:gap-4 sm:gap-2">
                  <div>
                    <ProfilePicture
                      url={user?.avatar?.url}
                      name={user?.name}
                      // height={220}
                      // width={220}
                      className="lg:h-[220px] lg:w-[220px] md:h-[190px] md:w-[190px] sm:h-[80px] sm:w-[80px] lg:text-[100px] md:text-[80px] sm:text-[60px] font-bold tracking-tighter"
                    />
                  </div>

                  <div className="text-white">
                    <p className="lg:text-3xl md:text-2xl sm:text-md px-1.5 rounded bg-dark w-fit">
                      {user?.name}
                    </p>
                    <p className="mt-1 lg:text-lg md:text-md sm:text-xs px-1.5 rounded bg-dark w-fit">
                      {user?.country}
                    </p>
                  </div>
                </div>
              </div>
            </div> */}

            <div
              style={{
                backgroundImage: user?.cover
                  ? `linear-gradient(to top, rgba(7, 0, 51, 1), rgba(0, 0, 0, 0)) , url(${user?.cover?.url})`
                  : null,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
              className="relative bg-bgMoreDark py-4 lg:px-4 md:px-4 sm:px-0 mx-auto w-full lg:h-[420px] flex flex-col justify-between items-center"
            >
              {/* DASH */}
              <div className=" w-full p-4 flex justify-between ">
                <div className="flex lg:gap-4 md:gap-4 sm:gap-2 items-center">
                  <div class="relative cursor-pointer">
                    <ProfilePicture
                      url={user?.avatar?.url}
                      name={user?.name}
                      // height={220}
                      // width={220}
                      className="lg:h-[220px] lg:w-[220px] md:h-[190px] md:w-[190px] sm:h-[80px] sm:w-[80px] lg:text-[100px] md:text-[80px] sm:text-[60px] font-bold tracking-tighter"
                    />
                  </div>

                  <div className="text-white">
                    <p className="lg:text-3xl md:text-2xl sm:text-md px-1.5 rounded bg-dark w-fit">
                      {user?.name}
                    </p>
                    <p className="my-1 lg:text-2xl md:text-xl sm:text-xs px-1.5 rounded bg-dark w-fit">
                      {user?.email}
                    </p>
                    <p className="lg:text-lg md:text-md sm:text-xs px-1.5 rounded bg-dark w-fit">
                      {user?.country}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-between items-center w-full">
                <div className="text-white"></div>
                <div className="mt-2 flex items-center justify-between">
                  <div className="mt-4 mr-10 hover:scale-95 transition-all ease-in-out">
                    <button
                      // onClick={btn?.handleOnClick}
                      className="flex justify-center rounded-full items-center gap-1 px-5 py-2 text-white"
                      style={{
                        backgroundColor: "#29253E",
                      }}
                    >
                      <p className="lg:text-[14px] md:text-[12px] sm:text-[10px]  text-nowrap">
                        Follow
                      </p>
                    </button>
                  </div>
                  <div className="mt-4 lg:flex md:flex sm:flex  gap-2">
                    {SocialLinks?.map((social, index) => {
                      return (
                        <Link
                          key={index}
                          to={social?.link}
                          target="_blank"
                          className="flex flex-col justify-center items-center"
                        >
                          <div className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] ">
                            {social?.icon}
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full bg-[#070033]">
              <div className="text-white pt-8 px-4 mx-auto max-w-[1280px] ">
                {user?.biography && (
                  <div className="mb-4 lg:hidden md:block sm:block">
                    <p className="text-[13px] ">{user?.biography}</p>
                  </div>
                )}
                {/* TRACKS HEADER */}

                <div className="flex justify-between items-end">
                  <div
                    className=" flex gap-4 lg:w-9/12 md:w-full sm:w-full overflow-x-scroll "
                    style={{
                      scrollbarWidth: "none", // Firefox
                      msOverflowStyle: "none", // IE and Edge
                    }}
                  >
                    {btnList?.map((btn) => {
                      return (
                        <div
                          key={btn?.id}
                          className={
                            btn?.id === activeBtn
                              ? "flex justify-center items-center gap-1 border-b-2 border-b-primary px-2"
                              : "flex justify-center items-center gap-1 px-2"
                          }
                        >
                          <p
                            onClick={() => setActiveBtn(btn?.id)}
                            className={
                              btn?.id === activeBtn
                                ? "pb-1 whitespace-nowrap leading-none font-semibold text-primary lg:text-[16px] md:text-[14px] sm:text-[10px] cursor-pointer"
                                : "pb-1 whitespace-nowrap lg:text-[16px] md:text-[14px] sm:text-[10px] cursor-pointer"
                            }
                            // disabled={btn?.isDisabled}
                          >
                            {btn?.name}
                          </p>

                          {btn?.tag !== "" && (
                            <p className="lg:text-[11px] md:text-[9px] sm:text-[7px] text-primary p-1 border border-primary">
                              {btn?.tag}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="lg:flex md:hidden sm:hidden flex-col items-center justify-center w-3/12 gap-6 mb-4">
                    <div className=" lg:flex md:hidden sm:hidden gap-2 ">
                      {iconBtnList?.map((btn, index) => {
                        if (btn?.name !== "" || btn?.icon !== "")
                          return <IconButton btn={btn} key={index} />;
                      })}
                    </div>

                    {/* <div className=" lg:flex md:hidden sm:hidden gap-4">
                {SocialLinks?.map((social, index) => {
                  console.log(social);
                  return (
                    <Link
                      key={index}
                      to={social?.link}
                      target="_blank"
                      className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] rounded-full bg-[#222326]"
                    >
                      <div>{social?.icon}</div>
                    </Link>
                  );
                })}
              </div> */}
                  </div>
                </div>

                <div className="border-b-[0.25px] border-b-gray-600 " />

                <div className="flex justify-between items-start mt-4">
                  {/* MUSIC */}

                  {activeBtn === 0 && (
                    <>
                      {song?.loading ? (
                        <div className="lg:w-3/4 sm:w-full px-8 py-6 rounded-lg h-[228px] flex justify-center items-center">
                          <img src={dot_gif} className="h-[80px]" />
                        </div>
                      ) : (
                        <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                          <p className="lg:text-[28px] md:text-[24px] sm:text-[20px]">
                            Spotlight
                          </p>

                          <div className="mt-4">
                            {song?.tracks?.length > 0 ? (
                              song?.tracks
                                ?.slice()
                                .reverse()
                                .map((track, index) => (
                                  <MusicDiv
                                    pauseAudio={pauseAudio}
                                    isPlaying={isPlaying}
                                    playAudio={playAudio}
                                    showModal={showModal}
                                    track={track}
                                    setCurrentTrackIndex={setCurrentTrackIndex}
                                    setTrackList={setTrackList}
                                    index={index}
                                    currentTrackIndex={currentTrackIndex}
                                    tracks={song?.tracks}
                                    currentTrack={currentTrack}
                                  />
                                ))
                            ) : (
                              <div>Nothing Here!</div>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {activeBtn === 1 && (
                    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                      <div className="mt-4">
                        {song?.tracks?.length > 0 ? (
                          song?.tracks
                            .sort((a, b) => b.count - a.count)
                            ?.slice(0, 10)
                            .map((track, index) => (
                              <MusicDiv
                                pauseAudio={pauseAudio}
                                isPlaying={isPlaying}
                                playAudio={playAudio}
                                showModal={showModal}
                                track={track}
                                setCurrentTrackIndex={setCurrentTrackIndex}
                                setTrackList={setTrackList}
                                index={index}
                                currentTrackIndex={currentTrackIndex}
                                tracks={song?.tracks}
                                currentTrack={currentTrack}
                              />
                            ))
                        ) : (
                          <div>Nothing Here!</div>
                        )}
                      </div>
                    </div>
                  )}

                  {activeBtn === 2 && (
                    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                      <div className="mt-4">
                        {song?.tracks?.length > 0 ? (
                          song?.tracks
                            ?.slice()
                            .reverse()
                            .map((track, index) => (
                              <MusicDiv
                                pauseAudio={pauseAudio}
                                isPlaying={isPlaying}
                                playAudio={playAudio}
                                showModal={showModal}
                                track={track}
                                setCurrentTrackIndex={setCurrentTrackIndex}
                                setTrackList={setTrackList}
                                index={index}
                                currentTrackIndex={currentTrackIndex}
                                tracks={song?.tracks}
                                currentTrack={currentTrack}
                              />
                            ))
                        ) : (
                          <div>Nothing Here!</div>
                        )}
                      </div>
                    </div>
                  )}
                  {activeBtn === 3 && <Albums />}
                  {activeBtn === 4 && <Playlists />}
                  {activeBtn === 5 && <Reposts />}

                  {activeBtn === 6 && <EventDetails events={user?.events} />}
                  {activeBtn === 7 && <Gallery />}

                  {/* PROFILE DETAILS */}
                  <div className="lg:block md:hidden sm:hidden w-3/12 pl-4 border-l-[0.25px] border-l-gray-600 sticky top-24">
                    {/* FOLLOWERS  */}
                    <div className="flex text-gray-400 items-center">
                      <div className="pr-4">
                        <p className="text-[14px]">Followers</p>
                        <p className="text-[20px]">
                          {user?.followers?.length || 0}
                        </p>
                      </div>
                      <div className="px-4 border-l-[0.25px] border-l-gray-600 border-r-[0.25px] border-r-gray-600">
                        <p className="text-[14px]">Following</p>
                        <p className="text-[20px]">
                          {user?.following?.length || 0}
                        </p>
                      </div>
                      <div className="px-4">
                        <p className="text-[14px]">Tracks</p>
                        <p className="text-[20px]">
                          {song?.tracks?.length || 0}
                        </p>
                      </div>
                    </div>
                    {/* DETAILS ABOUT */}
                    {user?.biography && (
                      <div className="mt-4 ">
                        <p className="text-[13px] ">{user?.biography}</p>
                      </div>
                    )}
                    {/* SOCIAL */}{" "}
                    <div className="mt-8">
                      <div className="flex text-gray-400 justify-between items-center ">
                        <p className="flex gap-1">
                          <span>Social Links </span>
                        </p>
                      </div>
                      <Divider marginTop={6} />
                      <div className="mt-4 lg:flex md:hidden sm:hidden  gap-6">
                        {SocialLinks?.map((social, index) => {
                          return (
                            <Link
                              key={index}
                              to={social?.link}
                              target="_blank"
                              className="flex flex-col justify-center items-center"
                            >
                              <div className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] rounded-full bg-[#222326]">
                                {social?.icon}
                              </div>
                              <p className="text-center text-[10px] mt-1">
                                {" "}
                                {social?.name}
                              </p>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    {/* GO MOBILE */}
                    <div className="mt-8">
                      <div className="flex text-gray-400 justify-between items-center ">
                        <p className="flex gap-1">
                          <span>Go mobile </span>
                        </p>
                      </div>
                      <Divider marginTop={6} />
                      <div className="my-4 flex gap-2">
                        <img
                          src={appstore}
                          className="w-full h-[50px]"
                          alt=""
                        />
                        <img
                          src={googleplay}
                          className="w-full h-[50px]"
                          alt=""
                        />
                      </div>

                      <Divider marginTop={12} />

                      <p className="max-w-[200px] text-[12px] text-[#999] mt-6">
                        Legal ⁃ Privacy ⁃ Cookie Policy ⁃ Cookie Manager ⁃
                        Imprint ⁃ Artist Resources ⁃ Blog ⁃ Charts ⁃
                      </p>
                      <p className="max-w-[200px] text-[12px] text-[#999] mt-6">
                        Transparency Reports Language: English (US)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Artist;

const EventDetails = ({ events }) => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="text-white flex justify-between items-center"></div>
      <div className="my-4">
        {events?.length > 0 ? (
          <>
            <div className="w-full rounded-xl my-1 p-2.5 font-bold text-[18px] flex justify-around items-center">
              <p>Event Date</p>
              <p>Event Name</p>
              <p>Location</p>
            </div>
            {events
              ?.slice()
              .reverse()
              .map((e, i) => {
                const D = new Date(e.date);
                return (
                  <div className="w-full bg-cardBg rounded-xl my-1 p-2.5 border-gray-600 flex justify-around items-center hover:scale-95 transition-all ease-in-out">
                    <p>{D.toDateString()}</p>
                    <p>{e.name}</p>
                    <p>
                      {e.city}, {e.country}
                    </p>
                  </div>
                );
              })}
          </>
        ) : (
          <div className="flex justify-center items-center text-[18px]">
            No event posted yet.
          </div>
        )}
      </div>
    </div>
  );
};

const Reposts = () => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-center items-center text-[18px]">
        No reposts yet.
      </div>
    </div>
  );
};

const Playlists = () => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-center items-center text-[18px]">
        No playlist found.
      </div>
    </div>
  );
};

const Albums = () => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-center items-center text-[18px]">
        This artist haven't created any Album yet.
      </div>
    </div>
  );
};

const Gallery = () => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-center items-center text-[18px]">
        No posts yet.
      </div>
    </div>
  );
};
