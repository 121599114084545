import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BtnFilled } from "../../component/button";

const Receipt = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderDetails, message } = location.state || {}; // Access the passed order object
  console.log(orderDetails);

  let receiptFields = [
    { title: "Date Issued", value: orderDetails?.dateIssued },
    { title: "Order Number", value: orderDetails?.orderNumber },
    { title: "Payment Method", value: orderDetails?.paymentMethod },
    { title: "Retailer", value: orderDetails?.retailer },
    { title: "Address", value: orderDetails?.address },
    { title: "VAT ID", value: orderDetails?.vatID },
    { title: "Product", value: orderDetails?.product },
    { title: "MOMS", value: orderDetails?.moms },
    { title: "Total Tax", value: orderDetails?.totalTax },
    { title: "Total", value: orderDetails?.total },
    { title: "Status", value: orderDetails?.status },
  ];

  const handlePrint = () => {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Receipt
        </h1>
        <div>
          <div>
            {receiptFields.map((field, index) => (
              <div
                key={index}
                className="py-3 border-b-[0.45px] border-b-gray-700 flex items-center"
              >
                <p className="text-gray-400 text-[14px] font-semibold w-4/12">
                  {field.title}
                </p>
                <p className="text-white text-[14px] w-8/12">{field.value}</p>
              </div>
            ))}
          </div>

          <div className="mt-6">
            <p className="text-white text-[12px]">
              {message}{" "}
              <Link to={"/terms-and-condition"} className="underline">
                Terms and condition
              </Link>
            </p>
          </div>
          <div className="flex justify-between items-center gap-2 my-6">
            <div></div>
            <div className="flex items-center gap-2 mt-6">
              <BtnFilled
                text={"Print receipt"}
                onClick={handlePrint}
                width={180}
                bgColor={"#6813f2"}
                textColor={"white"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Receipt;
