import React, { useEffect, useState } from "react";
import { linkArray } from "../../assets/data/homeFooterLink";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeaturedTracks,
  getPlaylistById,
  getTracksByGenre,
} from "../../redux/actions";
import { dot_gif } from "../../assets/images";
import LeftNav from "../../component/home/LeftNav";
import Navbar from "../../component/shared/Navbar";
import PlaylistSection from "../../component/playlist/PlaylistSection";
import Footer from "../../component/shared/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useAudio } from "../../component/context/audio";
import { useModal } from "../../component/context/modal";
import ReactModal from "react-modal";
import { TextInputField1 } from "../../component/form/InputField";
import toast from "react-hot-toast";
import axiosInstance from "../../redux/helpers/axios";

const Playlist = () => {
  const {
    isPlaying,
    setTrackList,
    setCurrentTrackIndex,
    playAudio,
    pauseAudio,
    currentTrack,
  } = useAudio();

  const { showModal } = useModal();

  const dispatch = useDispatch();
  const { id } = useParams();

  const [deviceScreenType, setDeviceScreenType] = useState("none");
  const [fullPageWidth, setFullPageWidth] = useState(-1);
  const [navRatio, setNavRatio] = useState(0.2);
  const [show, setShow] = useState(false);
  const [param, setParam] = useState("");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [formData, setFormData] = useState({ tracks: [] });

  useEffect(() => {
    let fullPageWidth = window.innerWidth;
    setFullPageWidth(fullPageWidth);

    const handleResize = () => {
      if (window) {
        const fullPageWidth = window?.innerWidth;

        if (fullPageWidth < 768) {
          setDeviceScreenType("sm");
          setNavRatio(0);
        } else if (fullPageWidth < 1024) {
          setDeviceScreenType("md");
          setNavRatio(0.08);
        } else {
          setDeviceScreenType("lg");
          setNavRatio(0.2);
        }
      }
    };

    // Call the function on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);

  useEffect(() => {
    dispatch(getPlaylistById(id));
    dispatch(getFeaturedTracks(10));
  }, [id]);

  const playlist = useSelector((state) => state.playlist);
  const song = useSelector((state) => state.song);

  useEffect(() => {
    setFilteredSongs(song?.featuredTracks);
  }, [song]);

  const handleRemoveTrack = (song) => {
    const index = selectedSongs.indexOf(song);
    if (index !== -1) {
      selectedSongs.splice(index, 1);
    }
    setSelectedSongs(selectedSongs);
    setFormData({
      ...formData,
      ["tracks"]: selectedSongs,
    });
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    const searchQuery = value?.toLowerCase();
    setParam(searchQuery);

    // Filter the songs based on the search query
    if (searchQuery?.split("")?.length > 2) {
      axiosInstance
        .get(`/song/public/search?searchTerm=${searchQuery}`)
        .then((res) => {
          setFilteredSongs(res.data.songs);
        });
    } else {
      setFilteredSongs(song?.featuredTracks);
    }
  };

  const handleSelect = (song) => {
    const valid = selectedSongs.some((s) => s._id === song._id);
    if (valid) {
      toast.success("Already Selected.", { duration: 1500 });
    } else {
      selectedSongs.push(song);
      setSelectedSongs(selectedSongs);
      setFormData({
        ...formData,
        ["tracks"]: selectedSongs,
      });
    }
    setSelectedSongs(selectedSongs);
    setParam("");
    setFilteredSongs([]);
  };

  const handleAddTrack = () => {
    const data = {
      tracks: formData.tracks.map((t) => {
        return t._id;
      }),
      _id: playlist?.publicPlaylist?._id,
    };
    const id = toast.loading("Loading...");
    axiosInstance
      .post(`/user/playlist/update`, data)
      .then((res) => {
        toast.success("Track(s) Added", { id: id, duration: 1500 });
        setSelectedSongs([]);
        dispatch(getPlaylistById(playlist?.publicPlaylist?._id));
        setShow(false);
      })
      .catch((err) => {
        toast.error("Unable to add now. Try later.");
      });
  };

  return (
    <div className="bg-dark  min-h-screen ">
      {fullPageWidth !== -1 && deviceScreenType !== "none" ? (
        <div className="min-h-screen relative ">
          {/* NAV */}
          <Navbar />

          <div className="flex gap-0 mt-2 relative min-h-screen">
            {/* FIRST BOX */}
            <div
              style={{ width: window.innerWidth * navRatio }}
              className="lg:block md:block sm:hidden relative px-2 "
            >
              <LeftNav
                deviceScreenType={deviceScreenType}
                linkArray={linkArray}
              />
            </div>
            <div className="border-2 border-globalBg lg:block md:block sm:hidden"></div>
            {/* SECOND BOX */}
            <div
              style={{ width: window.innerWidth * (1 - navRatio) }}
              className="rounded-lg "
            >
              {playlist?.loading ? (
                <div className="flex justify-center items-center min-h-screen px-2">
                  <img src={dot_gif} className="h-[100px]" />
                </div>
              ) : (
                <>
                  <PlaylistSection
                    data={{
                      img: playlist?.publicPlaylist?.img
                        ? playlist?.publicPlaylist?.img
                        : playlist?.publicPlaylist?.tracks[0]?.img,
                      name: playlist?.publicPlaylist?.name,
                      artists: [
                        playlist?.publicPlaylist?.tracks[0]?.user?.name,
                      ],
                      saves: 0,
                      playtime: "3 hr 45 min",
                      songs: [...playlist?.publicPlaylist?.tracks],
                      bg: playlist?.bg,
                      type: "ff",
                    }}
                    //temporary
                    // song={song?.tracksByGenre}
                    deviceScreenType={deviceScreenType}
                    isPlaying={isPlaying}
                    setTrackList={setTrackList}
                    setCurrentTrackIndex={setCurrentTrackIndex}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                    showModal={showModal}
                    currentTrack={currentTrack}
                    setShow={setShow}
                  />
                  <div className="bg-globalBg py-[2px] rounded-lg">
                    <Footer />
                  </div>
                </>
              )}
            </div>
          </div>
          <ReactModal
            isOpen={show}
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              },
              content: {
                // width: "50%",
                height: "80%",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                // backgroundColor: "#1A1A1A",
                backgroundColor: "#070033",
                borderRadius: 8,
                border: "none",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                maxWidth: 840,
              },
            }}
            onRequestClose={() => {
              setShow(false);
            }}
          >
            <div className="mx-auto w-full px-4 py-2 text-white">
              <div className="flex  justify-between w-full">
                <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
                  Add tracks to playlist
                </p>

                <svg
                  onClick={() => {
                    setShow(false);
                  }}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="white"
                  className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18 18 6M6 6l12 12"
                  />
                </svg>
              </div>

              <div className="mt-4">
                <div className="flex justify-center items-start overflow-hidden">
                  <div className="flex lg:flex-col md:flex-row sm:flex-col justify-center items-center gap-4">
                    <div>
                      {selectedSongs.length > 0 &&
                        selectedSongs.map((song) => (
                          <div
                            key={song.id}
                            className="flex justify-between items-center"
                          >
                            <div className="flex gap-2 my-1 items-center">
                              <img src={song?.img} className="h-[35px]" />
                              <div>
                                <p className="text-[14px]">
                                  {song.title?.slice(0, 30)}...
                                </p>
                                <p className="text-[10px] text-gray-400">
                                  {song?.user?.name} - {song?.genre}
                                </p>
                              </div>
                            </div>

                            <svg
                              onClick={() => handleRemoveTrack(song)}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="size-6 p-1 cursor-pointer hover:scale-90 bg-red-500 rounded-full  transition-all ease-in-out"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18 18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        ))}
                    </div>
                    <TextInputField1
                      type={"text"}
                      name={"param"}
                      label={"Search Tracks"}
                      placeholder={"Enter Atleast 3 Letter"}
                      value={param}
                      onChange={handleSearch}
                    />

                    <div>
                      <ul>
                        {filteredSongs.map((song) => (
                          <li key={song.id} onClick={() => handleSelect(song)}>
                            <div className="flex gap-2 my-1 items-center hover:bg-[#1F1F1F] rounded-lg px-3 py-1 cursor-pointer">
                              <img src={song?.img} className="h-[35px]" />
                              <div>
                                <p className="text-[14px]">
                                  {song.title?.slice(0, 35)}...
                                </p>
                                <p className="text-[10px] text-gray-400">
                                  {song?.user?.name} - {song?.genre}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center mt-1">
                  <div className="flex gap-2 ">
                    <button
                      onClick={handleAddTrack}
                      className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ReactModal>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        </>
      )}
    </div>
  );
};

export default Playlist;
