import toast from "react-hot-toast";
import axiosInstance from "../helpers/axios";
import { subConstant } from "./constants";
import { getUserByToken } from "./auth.action";

export const getPlan = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: subConstant.SUB_PLAN_REQUEST });
      const res = await axiosInstance.post(`/subscription/get-plan`, data);

      if (res.status === 200) {
        const { msg, plan } = res.data;
        dispatch({
          type: subConstant.SUB_PLAN_SUCCESS,
          payload: plan,
        });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: subConstant.SUB_PLAN_FAILURE,
        payload: data,
      });
    }
  };
};

export const createSubOnStripe = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: subConstant.SUB_STRIPE_CREATE_REQUEST });
      const res = await axiosInstance.post(`/payment/stripe/create-sub`, data);

      if (res.status === 200) {
        const { sub } = res.data;
        dispatch({
          type: subConstant.SUB_STRIPE_CREATE_SUCCESS,
          payload: sub,
        });
        toast.dismiss(id);
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: subConstant.SUB_STRIPE_CREATE_FAILURE,
        payload: data,
      });
      toast.dismiss(id);
    }
  };
};

export const createSub = (data) => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");
    try {
      dispatch({ type: subConstant.SUB_CREATE_REQUEST });
      const res = await axiosInstance.post(`/user/subscription/create`, data);

      if (res.status === 201) {
        const { sub, msg } = res.data;
        dispatch({
          type: subConstant.SUB_CREATE_SUCCESS,
          payload: sub,
        });
        dispatch(getUserByToken(token));
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: subConstant.SUB_CREATE_FAILURE,
        payload: data,
      });
    }
  };
};
