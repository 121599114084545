import React from "react";
import { icon_logo, logopngw } from "../../assets/logo";
import { Link } from "react-router-dom";

let NecessaryLinks = [
  {
    name: "Company",
    links: [
      { name: "About", link: "" },
      { name: "Jobs", link: "" },
      { name: "For the Record", link: "" },
    ],
  },
  {
    name: "Communities",
    links: [
      { name: "For Artists", link: "" },
      { name: "Developers", link: "" },
      { name: "Advertising", link: "" },
      { name: "Investors", link: "" },
      { name: "Vendors", link: "" },
    ],
  },
  {
    name: "Useful Links",
    links: [
      { name: "Support", link: "" },
      { name: "FAQ", link: "/faq" },
      { name: "Web Player", link: "" },
      { name: "Free Mobile App", link: "" },
    ],
  },
  {
    name: "Upvibez Plans",
    links: [
      { name: "Premium Individual", link: "" },
      { name: "Premium Duo", link: "" },
      { name: "Premium Family", link: "" },
      { name: "Premium Student", link: "" },
      { name: "Upvibez Free", link: "" },
    ],
  },
];

let SocialLinks = [
  {
    name: "Instagram",
    link: "",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
      </svg>
    ),
  },
  {
    name: "X",
    link: "",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
      </svg>
    ),
  },
  {
    name: "Facebook",
    link: "",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="currentColor"
        viewBox="0 0 24 24"
      >
        <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
      </svg>
    ),
  },
];

let MoreLinks = [
  { name: "Legal", link: "" },
  { name: "Safety & Privacy Center", link: "" },
  { name: "Privacy Policy", link: "/privacy-policy" },
  { name: "Cookies", link: "" },
  { name: "About Ads", link: "" },
  { name: "Accessibility", link: "" },
];

const Footer = () => {
  return (
    <div className="bg-dark ">
      <div className="mx-auto  lg:px-12 md:px-16 sm:px-8 lg:py-20 md:py-16 sm:py-8">
        <div className="flex lg:flex-row md:flex-col sm:flex-col justify-between lg:gap-4 sm:gap-10">
          <Link to={"/"}>
            <img src={logopngw} height={37} width={120} alt="" />
          </Link>

          <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 lg:gap-6 sm:gap-12 justify-between">
            {NecessaryLinks?.map((linkStack, index) => {
              return (
                <div key={index} className="max-w-[130px]">
                  <h2 className="lg:text-[13px] sm:text-[11px] font-semibold text-gray-400 uppercase">
                    {linkStack?.name}
                  </h2>

                  <div className="flex flex-col gap-6 mt-3">
                    {linkStack?.links?.map((routelink, index2) => {
                      return (
                        <Link key={index2} to={routelink?.link}>
                          <p className="lg:text-[14px] sm:text-[13px] font-medium text-white max-w-[130px]">
                            {routelink?.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {/* SOCIALS */}
          <div className="flex lg:justify-start md:justify-start sm:justify-center gap-6">
            {SocialLinks?.map((social, index) => {
              return (
                <Link
                  key={index}
                  to={social?.link}
                  className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[56px] w-[56px] rounded-full bg-[#222326]"
                >
                  <div>{social?.icon}</div>
                </Link>
              );
            })}
          </div>
        </div>

        <div className="max-w-[760px] px-8 mx-auto flex lg:flex-row md:flex-row sm:flex-col gap-10 justify-between items-center mt-24">
          <div className="flex flex-wrap lg:justify-start md:justify-start sm:justify-center gap-6">
            {MoreLinks?.map?.((moreLink, index) => {
              return (
                <Link
                  key={index}
                  to={moreLink?.link}
                  className="text-[11px] text-center text-gray-400"
                >
                  {moreLink?.name}
                </Link>
              );
            })}
          </div>

          <p className="text-[11px] text-gray-400 ">© 2024 Upvibez</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
