import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const FeaturedTrackCard = ({ item, displayType, minwidth }) => {
  const navigate = useNavigate();

  return (
    <div
      style={{ width: minwidth }}
      className="px-2  cursor-pointer"
      onClick={() => navigate(`/track/${item?.permalink}`)}
    >
      <div
        style={{
          backgroundImage: `url(${item?.img})`,

          backgroundPosition: "center",
          backgroundSize: "cover",
          height: minwidth,
          width: minwidth,
        }}
        height={minwidth}
        width={minwidth}
        alt=""
        className={displayType === "r" ? "rounded-full" : "rounded-xl"}
      ></div>

      <h2 className="lg:text-[14px] md:text-[12px] text-[12px] mt-3">
        {item?.title?.split("").length > 30
          ? `${item?.title?.slice(0, 40)}. . .`
          : item?.title}
      </h2>
      <h2 className="text-[12px] mt-1 text-gray-500">{item?.user?.name}</h2>
    </div>
  );
};
const FeaturedTracksRow = ({ featuredTracks, name, gap, deviceScreenType }) => {
  const [minwidth, setMinWidth] = useState(220);
  useEffect(() => {
    if (deviceScreenType === "sm") {
      setMinWidth(160);
    } else if (deviceScreenType === "md") {
      setMinWidth(190);
    } else {
      setMinWidth(220);
    }
  }, [deviceScreenType]);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [songsLength, setSongsLength] = useState(0);

  useEffect(() => {
    const { offsetWidth, offsetHeight } = ref.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
    setSongsLength(Math.ceil((offsetWidth - 32) / (minwidth + gap)));
  }, []);

  return (
    <div className="text-white">
      <div ref={ref} className="flex justify-between items-center">
        <h1 className="lg:text-[24px] md:text-[20px] sm:text-[18px] font-medium">
          {" "}
          {name}{" "}
        </h1>
        <button className="lg:text-[14px] md:text-[12px] text-[12px]">
          {" "}
          Show all{" "}
        </button>
      </div>
      <div
        style={{ gap: gap }}
        className="lg:flex md:flex sm:hidden  mt-6 overflow-x-scroll no-scrollbar "
      >
        {featuredTracks?.map((item, index) => {
          if (index < songsLength)
            return (
              <FeaturedTrackCard
                item={item}
                key={index}
                displayType={"s"}
                minwidth={minwidth}
              />
            );
        })}
      </div>

      <div className="lg:hidden md:hidden sm:flex gap-6 mt-6 overflow-x-scroll no-scrollbar ">
        {featuredTracks?.map((item, index) => {
          return (
            <FeaturedTrackCard
              item={item}
              key={index}
              displayType={"s"}
              minwidth={minwidth}
            />
          );
        })}
      </div>
    </div>
  );
};

const FeaturedArtistCard = ({ item, displayType, minwidth }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <div
      style={{ width: minwidth }}
      className="] cursor-pointer"
      onClick={() =>
        user?._id === item?._id
          ? navigate("/profile")
          : navigate(`/public/artist/${item?._id}`)
      }
    >
      <div
        style={{
          backgroundImage: `url(${item?.avatar?.url})`,

          backgroundPosition: "center",
          backgroundSize: "cover",
          height: minwidth,
          width: minwidth,
        }}
        height={minwidth}
        width={minwidth}
        alt=""
        className={displayType === "r" ? "rounded-full" : "rounded-xl"}
      ></div>

      <h2 className="lg:text-[16px] md:text-[14px] text-[12px] mt-3">
        {item?.name}
      </h2>
      <h2 className="text-[12px] ">{item?.type}</h2>
    </div>
  );
};
const FeaturedArtistRow = ({ featuredArtists, name, gap, minwidth }) => {
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [songsLength, setSongsLength] = useState(0);

  useEffect(() => {
    const { offsetWidth, offsetHeight } = ref.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
    setSongsLength(Math.ceil((offsetWidth - 32) / (minwidth + gap)));
  }, []);

  return (
    <div className="text-white">
      <div ref={ref} className="flex justify-between items-center">
        <h1 className="lg:text-[24px] md:text-[20px] sm:text-[18px] font-medium">
          {" "}
          {name}{" "}
        </h1>
        <button className="lg:text-[14px] md:text-[12px] text-[12px]">
          {" "}
          Show all{" "}
        </button>
      </div>
      <div
        style={{ gap: gap }}
        className="lg:flex md:flex sm:hidden  mt-6 overflow-x-scroll no-scrollbar "
      >
        {featuredArtists?.map((item, index) => {
          if (index < songsLength)
            return (
              <FeaturedArtistCard
                item={item}
                key={index}
                displayType={"r"}
                minwidth={minwidth}
              />
            );
        })}
      </div>
      <div
        style={{ gap: gap }}
        className="lg:hidden md:hidden sm:flex  mt-6 overflow-x-scroll no-scrollbar "
      >
        {featuredArtists?.map((item, index) => {
          return (
            <FeaturedArtistCard
              item={item}
              key={index}
              displayType={"r"}
              minwidth={minwidth}
            />
          );
        })}
      </div>
    </div>
  );
};

const GenreCard = ({ item, displayType, minwidth }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ width: minwidth }}
      className="cursor-pointer"
      onClick={() =>
        navigate(`/genre/${item?.name}`, { state: { genreDetails: item } })
      }
    >
      <div
        style={{
          backgroundImage: `url(${item?.img})`,

          backgroundPosition: "center",
          backgroundSize: "cover",
          height: minwidth,
          width: minwidth,
        }}
        height={minwidth}
        width={minwidth}
        alt=""
        className={displayType === "r" ? "rounded-full" : "rounded-xl"}
      ></div>

      <h2 className="lg:text-[16px] md:text-[14px] sm:text-[12px] mt-1 ">
        {item?.name}
      </h2>
    </div>
  );
};
const GenreRow = ({ genres, name, gap, deviceScreenType }) => {
  const [minwidth, setMinWidth] = useState(220);
  useEffect(() => {
    if (deviceScreenType === "sm") {
      setMinWidth(160);
    } else if (deviceScreenType === "md") {
      setMinWidth(190);
    } else {
      setMinWidth(220);
    }
  }, [deviceScreenType]);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [songsLength, setSongsLength] = useState(0);

  useEffect(() => {
    const { offsetWidth, offsetHeight } = ref.current;
    setWidth(offsetWidth);
    setHeight(offsetHeight);
    setSongsLength(Math.ceil((offsetWidth - 32) / (minwidth + gap)));
  }, []);

  return (
    <div className="text-white">
      <div ref={ref} className="flex justify-between items-center">
        <h1 className="lg:text-[24px] md:text-[20px] sm:text-[18px] font-medium">
          {" "}
          {name}{" "}
        </h1>
        <button className="lg:text-[14px] md:text-[12px] text-[12px]">
          {" "}
          Show all{" "}
        </button>
      </div>
      <div
        style={{ gap: gap }}
        className="lg:flex md:flex sm:hidden  mt-6 overflow-x-scroll no-scrollbar "
      >
        {genres?.map((item, index) => {
          if (index < songsLength)
            return (
              <GenreCard
                item={item}
                key={index}
                displayType={"s"}
                minwidth={minwidth}
              />
            );
        })}
      </div>
      <div
        style={{ gap: gap }}
        className="lg:hidden md:hidden sm:flex  mt-6 overflow-x-scroll no-scrollbar "
      >
        {genres?.map((item, index) => {
          return (
            <GenreCard
              item={item}
              key={index}
              displayType={"s"}
              minwidth={minwidth}
            />
          );
        })}
      </div>
    </div>
  );
};

const MusicSuggestion = ({
  featuredArtists,
  featuredTracks,
  genres,
  deviceScreenType,
}) => {
  const [minwidth, setMinWidth] = useState(148);
  const [gap, setGap] = useState(24);

  useEffect(() => {
    if (deviceScreenType === "sm") {
      setMinWidth(120);
      setGap(8);
    } else if (deviceScreenType === "md") {
      setMinWidth(136);
      setGap(16);
    } else {
      setMinWidth(148);
      setGap(24);
    }
  }, [deviceScreenType]);

  return (
    <div
      // className="  homeSuggestingbg"
      className="bg-dark rounded-lg "
    >
      <div className="flex flex-col gap-12 px-6 py-8">
        <FeaturedArtistRow
          featuredArtists={featuredArtists}
          name={"Featured Artists"}
          deviceScreenType={deviceScreenType}
          minwidth={minwidth}
          gap={gap}
        />

        <GenreRow
          genres={genres}
          name={"Trending"}
          deviceScreenType={deviceScreenType}
          minwidth={minwidth}
          gap={gap}
        />

        <FeaturedTracksRow
          featuredTracks={featuredTracks}
          name={"Popular Tracks"}
          deviceScreenType={deviceScreenType}
          minwidth={minwidth}
          gap={gap}
        />
      </div>
    </div>
  );
};

export default MusicSuggestion;
