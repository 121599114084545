import { albumConstant } from "../actions/constants";

const initState = {
  albums: [],
  bg: {},
  publicPlaylist: {},
  loading: false,
  error: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case albumConstant.CREATE_ALBUM_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case albumConstant.CREATE_ALBUM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;
    case albumConstant.CREATE_ALBUM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case albumConstant.GET_ALBUM_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case albumConstant.GET_ALBUM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        albums: action.payload,
      };
      break;
    case albumConstant.GET_ALBUM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: null,
      };
      break;
    case albumConstant.GET_PUBLIC_ALBUM_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case albumConstant.GET_PUBLIC_ALBUM_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        publicPlaylist: action.payload.album,
        bg: action.payload.bg,
      };
      break;
    case albumConstant.GET_PUBLIC_ALBUM_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
  }
  return state;
};
