import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../component/shared/Navbar";
import Footer from "../../component/shared/Footer";
const Account = () => {
  return (
    <div className="bg-globalBg  min-h-screen ">
      <div className=" bg-dark sticky top-0 z-50">
        <Navbar />
      </div>
      <div className="min-h-[580px] px-4 py-6 max-w-[800px] mx-auto">
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default Account;
