import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const LoginMethod = () => {
  const navigate = useNavigate();
  let enable = "G";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Edit login methods
        </h1>
        <h2 className="text-[14px]">
          Add one or several login methods to make sure you can access your
          account anytime.
        </h2>

        <div className="max-w-[480px] my-8">
          <h1 className="text-[16px] mb-2">Current login methods</h1>
          <div className="p-4  bg-bgLightDark rounded-lg flex gap-2 items-center">
            <div className="bg-[#404040] px-2 rounded-lg text-white font-bold tracking-tighter text-[20px]">
              G
            </div>
            <p className=" text-white text-[15px] font-bold">Google</p>
          </div>

          <h1 className="text-[16px] mb-2 mt-6">Available login methods</h1>
          <div className="p-4  bg-bgLightDark rounded-lg">
            <div className="flex gap-2 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
              <p>Log in to continue</p>
            </div>
            <p className="text-[12px] my-4">
              To securely add a new login method to your account, we need to
              verify it’s you. Please login to Upvibez again to proceed.
            </p>
            <div className="flex justify-between items-center">
              <div></div>
              <button className="px-4 py-1 rounded-full bg-secondary  text-[14px] font-bold">
                Login{" "}
              </button>
            </div>
          </div>

          <div
            className={
              enable === "E"
                ? " mt-2 p-4  bg-bgLightDark rounded-lg flex gap-2 items-center"
                : " mt-2 p-4  bg-bgMoreDark text-gray-500 rounded-lg flex gap-2 items-center"
            }
          >
            <div className="bg-[#404040] px-2 rounded-lg  font-bold tracking-tighter text-[20px]">
              E
            </div>
            <p className="  text-[15px] font-bold">Email and Password</p>
          </div>
          <div
            className={
              enable === "E"
                ? " mt-2 p-4  bg-bgLightDark rounded-lg flex gap-2 items-center"
                : " mt-2 p-4  bg-bgMoreDark text-gray-500 rounded-lg flex gap-2 items-center"
            }
          >
            <div className="bg-[#404040] px-2 rounded-lg  font-bold tracking-tighter text-[20px]">
              F
            </div>
            <p className="  text-[15px] font-bold">Facebook</p>
          </div>
          <div
            className={
              enable === "E"
                ? " mt-2 p-4  bg-bgLightDark rounded-lg flex gap-2 items-center"
                : " mt-2 p-4  bg-bgMoreDark text-gray-500 rounded-lg flex gap-2 items-center"
            }
          >
            <div className="bg-[#404040] px-2 rounded-lg  font-bold tracking-tighter text-[20px]">
              A
            </div>
            <p className="  text-[15px] font-bold">Apple</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginMethod;
