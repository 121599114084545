import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from "../../redux/helpers/axios";
import { BtnFilled } from "../../component/button";
import ReactModal from "react-modal";
import { AddCard } from "../../component/payment/add-card";
import {
  aexcard,
  jcbcard,
  loading_1,
  mastercard,
  visacard,
} from "../../assets/images";
import Footer from "../../component/shared/Footer";
import Navbar from "../../component/shared/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { createSubOnStripe, getPlan } from "../../redux/actions";

let planDetails = {
  planName: "Premium Individual",
  channelNumbers: "1 Premium account",
  price: "USD 12",
  expiaryTimeLimit: "1 month",
  totalBill: "$12",
  startingFrom: "Nov 24, 2024",
  features: [
    "Normal price is BDT199.00 / month",
    "You will next be billed on Nov 24, 2024",
    "1 Premium account",
    "Subscribe or one-time payment",
    "Cancel anytime. Offer terms apply.",
  ],
  notes:
    "Offer only available if you haven't tried Premium before. Terms apply.",
  backgroundColor: "#FFD2D7",
  btnName: "Get Premium individual",
};

const Purchase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentSelect, setPaymentSelect] = useState(-1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const sub = useSelector((state) => state.sub);

  const now = new Date();
  const next30Days = now.getTime() + 30 * 24 * 60 * 60 * 1000;
  const next30DaysTimestamp = new Date(next30Days).toDateString();

  //Get subscription plan
  useEffect(() => {
    dispatch(getPlan({ type: "user" }));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Get Stripe key
  useEffect(() => {
    axiosInstance
      .get(`/payment/stripe/get-publishable-key`)
      .then(async (res) => {
        const { publishableKey } = await res.data;
        setStripePromise(loadStripe(publishableKey));
      });
  }, []);

  //Fetch all the payment method
  useEffect(() => {
    setLoading(true);
    const data = { customer: user.stripeId };
    axiosInstance
      .post(`/payment/stripe/get-payment-methods`, data)
      .then(async (res) => {
        const { methods } = await res.data;
        setPaymentMethods(methods);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [show]);

  //Subscribe and pay now
  const handlePayNow = () => {
    const data = {
      paymentMethodId: paymentMethods[paymentSelect].id,
      customerId: user.stripeId,
      priceId: sub?.plan?.stripeId,
    };
    dispatch(createSubOnStripe(data));
  };

  useEffect(() => {
    if (sub?.status === "success") {
      if (
        sub?.subDetails?.pending_setup_intent?.status ===
        "requires_confirmation"
      ) {
        confirmSetupIntent(
          sub?.subDetails?.pending_setup_intent?.client_secret
        );
      } else {
        navigate("/subscription/success", {
          state: {
            paymentMethod: paymentMethods[paymentSelect],
            subDetails: sub?.subDetails,
            plan: sub?.plan,
          },
        });
      }
    }
  }, [sub]);

  const confirmSetupIntent = async (setupIntentClientSecret) => {
    const stripe = await stripePromise;

    if (setupIntentClientSecret) {
      const { error, setupIntent } = await stripe.confirmCardSetup(
        setupIntentClientSecret,
        {
          // Optionally specify the payment method here if needed
        }
      );

      if (error) {
        console.error("Error confirming Setup Intent:", error);
      } else {
        navigate("/subscription/success", {
          state: {
            paymentMethod: paymentMethods[paymentSelect],
            subDetails: sub?.subDetails,
            plan: sub?.plan,
          },
        });
      }
    }
  };

  //Add a new card
  const handleAddCardModal = () => {
    setCardLoading(true);
    const data = { customer: user.stripeId, email: user.email };
    axiosInstance
      .post(`/payment/stripe/create-setup-intent`, data)
      .then(async (res) => {
        const { clientSecret } = await res.data;
        setClientSecret(clientSecret);
        setShow(true);
        setCardLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCardLoading(false);
      });
  };

  return (
    <div className="min-h-screen bg-white">
      <div className=" bg-dark sticky top-0 z-50">
        <Navbar />
      </div>
      <div className="mx-auto">
        {loading ? (
          <div className=" mb-20 text-black min-h-[720px] flex justify-center items-center px-4 py-6 max-w-[480px] mx-auto">
            <img
              src={loading_1}
              height={390}
              width={390}
              alt=""
              className=" bg-white px-1 rounded"
            />
          </div>
        ) : (
          <>
            <div className="bg-white">
              <div className="text-white px-4 py-6 max-w-[480px] mx-auto">
                <div className="flex justify-between items-center px-4">
                  <h1 className="text-[22px] font-semibold  mb-4">Your plan</h1>
                </div>
                {!sub?.loading && (
                  <div className="rounded-xl bg-dark text-white">
                    <div
                      className="rounded-tl-xl rounded-tr-xl  py-4 px-6"
                      style={{ backgroundColor: planDetails?.backgroundColor }}
                    >
                      <div className="flex justify-between items-center text-black">
                        <h1 className="font-bold text-[16px]">
                          {sub?.plan?.name}
                        </h1>
                        <h1 className="font-bold text-[20px] text-black">
                          {planDetails?.expiaryTimeLimit}
                        </h1>
                      </div>
                      <div className="flex justify-between items-center text-black">
                        <p className=" text-[11px]">
                          {planDetails?.channelNumbers}
                        </p>
                        <p className=" text-[11px]">
                          For USD {sub?.plan?.price}
                        </p>
                      </div>
                    </div>
                    <div className="rounded-tl-lg rounded-tr-lg  py-4 px-6">
                      <div className="flex justify-between items-center">
                        <p className="font-bold text-[12px]">
                          Free Trial for {planDetails?.expiaryTimeLimit}
                        </p>
                      </div>
                      <div className="flex justify-between items-center">
                        <p className="font-semibold text-[12px]">
                          {" "}
                          Starting from {next30DaysTimestamp}
                        </p>
                        <p className="font-bold text-[12px]">
                          USD {sub?.plan?.price}/month
                        </p>
                      </div>
                      <ul className="list-disc mt-2 ml-[9px]">
                        {sub?.plan?.metadata?.map((m, index) => (
                          <li key={index} className="text-[10px] ">
                            {m.value}
                          </li>
                        ))}
                      </ul>
                      <p className="flex justify-center items-center text-[12px] mt-4">
                        *Free trial only applicable for new users.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" mb-20 text-black  px-4 py-6 max-w-[480px] mx-auto rounded-b-lg shadow-2xl">
              {paymentMethods.length > 0 ? (
                <>
                  <p className="flex justify-center items-center text-[12px] mt-4">
                    {user.subscription.subId !== null
                      ? "You are not eligible for free trial."
                      : "*You will not be charged untill your free trial ends."}
                  </p>
                  {paymentMethods?.map((method, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        paymentSelect !== index
                          ? setPaymentSelect(index)
                          : setPaymentSelect(-1);
                      }}
                      style={{
                        backgroundColor: paymentSelect === index && "#6813f2",
                        color: paymentSelect === index && "#fff",
                      }}
                      className="scale-95 hover:scale-100 hover:bg-[#D9C8FB] cursor-pointer transition-all ease-in-out mt-6 px-4 py-5 border-[0.45px] bg-slate-100 border-gray-600 rounded-lg flex justify-between items-center"
                    >
                      <div className="text-[14px] flex items-center gap-2 font-semibold tracking-tighter ">
                        {method?.card?.brand === "mastercard" ? (
                          <img
                            src={mastercard}
                            height={34}
                            width={34}
                            alt=""
                            className=" bg-white px-1 rounded"
                          />
                        ) : (
                          <img
                            src={visacard}
                            height={34}
                            width={34}
                            alt=""
                            className=" bg-white px-1 rounded"
                          />
                        )}

                        <p>**** **** **** {method?.card?.last4}</p>
                        <p>
                          {method?.card?.exp_month}/{method?.card?.exp_year}
                        </p>
                      </div>

                      <button
                        style={{
                          color: paymentSelect === index && "#fff",
                        }}
                        className="text-secondary font-bold text-[14px] tracking-tighter"
                      >
                        {paymentSelect === index ? "Remove" : "Select"}
                      </button>
                    </div>
                  ))}
                </>
              ) : (
                <div className="flex gap-2 w-full bg-[#E4E4E4] rounded p-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                    />
                  </svg>

                  <p>You dont't have any saved payment method.</p>
                </div>
              )}

              <div className="flex justify-center items-center mt-4">
                {paymentMethods.length <= 0 ? (
                  <BtnFilled
                    text={cardLoading ? "Loading..." : "Add New Card"}
                    onClick={() => handleAddCardModal()}
                    width={320}
                    bgColor={"#6813f2"}
                    textColor={"white"}
                    style={{ marginTop: "18px" }}
                    isDisabled={cardLoading}
                  />
                ) : (
                  <BtnFilled
                    text={"Start Free Trial"}
                    onClick={() => handlePayNow()}
                    width={320}
                    bgColor={"#6813f2"}
                    textColor={"white"}
                    style={{ marginTop: "18px" }}
                    isDisabled={paymentSelect === -1 ? true : false}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <ReactModal
          isOpen={show}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.6)",
            },
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              // backgroundColor: "#1A1A1A",
              backgroundColor: "#070033",
              borderRadius: 8,
              border: "none",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            },
          }}
          onRequestClose={() => {
            setShow(false);
          }}
        >
          <div className="h-[290px] w-[590px] px-4 py-2 text-white">
            <div className="flex  justify-between w-full">
              <p className="text-[22px]">Add a new card</p>

              <svg
                onClick={() => {
                  setShow(false);
                }}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div className="p-6 rounded-xl">
              {stripePromise && clientSecret && (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: clientSecret }}
                >
                  <AddCard
                    clientSecret={clientSecret}
                    user={user}
                    setShow={setShow}
                  />
                </Elements>
              )}
            </div>
          </div>
        </ReactModal>
      </div>

      <Footer />
    </div>
  );
};

export default Purchase;
