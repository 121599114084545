export const linkArray = [
  {
    name: "User Agreement",
    route: "/user-license-agreement",
  },
  {
    name: "Terms & Conditions",
    route: "/terms-and-condition",
  },
  {
    name: "Privacy Policy",
    route: "/privacy-policy",
  },
  {
    name: "Cookies",
    route: "",
  },
  {
    name: "About Ads",
    route: "",
  },
  {
    name: "Accessibility",
    route: "",
  },
];
