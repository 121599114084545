import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const ManageApps = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Manage Apps
        </h1>
        <div>
          <p>
            You've given these apps access to your Upvibez account. To prevent
            them from future access, click "Remove Access".
          </p>

          <p className="mt-4">You haven't approved any applications.</p>
        </div>
      </div>
    </div>
  );
};

export default ManageApps;
