import { playlistConstant } from "../actions/constants";

const initState = {
  playlists: [],
  pl: {},
  error: null,
  loading: false,
  bg: {},
  publicPlaylist: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case playlistConstant.CREATE_PLAYLIST_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case playlistConstant.CREATE_PLAYLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        pl: action.payload,
      };
      break;
    case playlistConstant.CREATE_PLAYLIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case playlistConstant.GET_PLAYLIST_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case playlistConstant.GET_PLAYLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        playlists: action.payload,
      };
      break;
    case playlistConstant.GET_PLAYLIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case playlistConstant.GET_PUBLIC_PLAYLIST_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case playlistConstant.GET_PUBLIC_PLAYLIST_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        publicPlaylist: action.payload.playlist,
        bg: action.payload.bg,
      };
      break;
    case playlistConstant.GET_PUBLIC_PLAYLIST_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
  }
  return state;
};
