import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BtnFilled } from "../../component/button";
import {
  PasswordInputField1,
  PasswordInputFieldWithCondotion,
} from "../../component/form/InputField";

const ManageDevices = () => {
  const navigate = useNavigate();

  const [conOne, setConeOne] = useState(false);
  const [conTwo, setConTwo] = useState(false);
  const [conThree, setConThree] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);
  const [isPasswordMatch, setPasswordMatch] = useState(false);
  const [formData, setFormData] = useState({
    confirm_password: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "password") setPasswordWrote((prevState) => true);
  };

  useEffect(() => {
    const minLength = 10;
    const letterRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    const numberOrSpecialCharRegex = /[0-9#?!&]/;

    if (formData.password.length < minLength) {
      setConThree(false);
    } else {
      setConThree(true);
    }

    if (!letterRegex.test(formData.password)) {
      setConeOne(false);
    } else {
      setConeOne(true);
    }
    if (!numberOrSpecialCharRegex.test(formData.password)) {
      setConTwo(false);
    } else {
      setConTwo(true);
    }
  }, [formData.password]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSetDevicePassword = () => {};
  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white mt-8">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Set device password
        </h1>
        <div>
          <p className="text-[15px] mt-0">
            To use Upvibez on your devices, you need to set a separate password
            and log in with the username provided.
          </p>

          <p className="text-[14px] font-bold mt-8">Your device username</p>
          <p className="text-[16px] mt-1 mb-8">31axerix5zoqzf4swwt52hpedtri</p>

          <div className="">
            <PasswordInputFieldWithCondotion
              labelStyle={{ fontSize: "14px" }}
              name={"password"}
              label={"Your device password"}
              placeholder={""}
              value={formData?.password}
              onChange={handleInputChange}
              con1={conOne}
              con2={conTwo}
              con3={conThree}
              style={{ marginBottom: 32, width: "100%" }}
              warning={isPasswordWrote && formData?.password === ""}
              warningText={"Please choose a password."}
            />

            <PasswordInputField1
              // type={"password"}
              labelStyle={{ fontSize: "14px" }}
              name={"confirm_password"}
              label={"Confirm device password"}
              placeholder={""}
              value={formData?.confirm_password}
              onChange={handleInputChange}
              warning={isPasswordMatch && formData?.confirm_password === ""}
              warningText={"Password dosen't match."}
              style={{ marginBottom: 12, width: "100%" }}
            />
          </div>

          <div className="flex justify-between items-center gap-2">
            <div></div>
            <div className="flex items-center gap-2 mt-6">
              <BtnFilled
                text={"Cancel"}
                onClick={() => navigate(-1)}
                width={110}
                bgColor={""}
                textColor={"white"}
              />
              <BtnFilled
                text={"Set device password"}
                onClick={handleSetDevicePassword}
                width={240}
                bgColor={"#6813f2"}
                textColor={"white"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDevices;
