import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Navbar from "../../component/shared/Navbar";
import Footer from "../../component/shared/Footer";
import { loading_1, success_gif } from "../../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { createSub } from "../../redux/actions";

const Success = () => {
  const dispatch = useDispatch();

  const sub = useSelector((state) => state.sub);
  const user = JSON.parse(localStorage.getItem("user"));

  const { state } = useLocation();
  const { paymentMethod, subDetails, plan } =
    state !== null
      ? state
      : { paymentMethod: null, subDetails: null, plan: null };

  const D = new Date(subDetails?.current_period_end * 1000);

  useEffect(() => {
    if (paymentMethod !== null) {
      const data = {
        paymentMethodInfo: {
          pmId: paymentMethod?.id,
          card: paymentMethod?.card?.last4,
          network: paymentMethod?.card?.brand,
          expires: `${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}`,
        },
        stripeId: subDetails?.id,
        nextBilling: subDetails?.current_period_end * 1000,
        startDate: subDetails?.current_period_start * 1000,
        plan: plan?._id,
        invoices:
          subDetails?.status === "trialing"
            ? [
                {
                  issueDate: new Date().getTime(),
                  amount: 0,
                  status: "no_payment",
                  orderId: `U-${Math.floor(
                    1000000000 + Math.random() * 9000000000
                  )}`,
                },
              ]
            : [],
      };

      dispatch(createSub(data));
    }
  }, [paymentMethod]);

  return (
    <div className={sub?.loading ? "bg-[#ffffff]" : "bg-[#F7F7F7]"}>
      <div className="bg-dark sticky">
        <Navbar />
      </div>
      {sub?.loading ? (
        <div className=" mb-20 text-black min-h-[720px] flex justify-center items-center px-4 py-6 max-w-[480px] mx-auto">
          <img
            src={loading_1}
            height={390}
            width={390}
            alt=""
            className=" bg-white px-1 rounded"
          />
        </div>
      ) : (
        <div className=" min-h-[580px] flex flex-col justify-center items-center ">
          <img src={success_gif} alt="" height={280} width={280} />
          <div className="mt-2 font-bold text-[36px]">
            Thank you for your subscription.
          </div>
          <div className="uppercase text-[14px] mt-4">
            Your next billing is on {D.toDateString()}, Card ending{" "}
            {paymentMethod?.card?.last4}
          </div>
          <Link
            to={"/"}
            className="mt-12 hover:scale-95 transition-all ease-in-out font-semibold text-white px-6 py-2 mx-auto rounded-full bg-dark"
          >
            Start Listening
          </Link>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Success;
