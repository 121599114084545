import React, { useEffect, useRef, useState } from "react";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";
import { useNavigate } from "react-router-dom";
import { BarChart, Bar, XAxis, YAxis, Tooltip } from "recharts";

/**
 * @author
 * @function Insight
 **/

export const Insight = (props) => {
  const navigate = useNavigate();

  const [insights, setInsights] = useState({
    stats: [
      {
        name: "plays",
        stat: [
          { date: "Jul 30", count: 2, static: 10 },
          { date: "Aug 1", count: 1, static: 10 },
          { date: "Aug 2", count: 8, static: 10 },
          { date: "Aug 3", count: 2, static: 10 },
          { date: "Aug 4", count: 3, static: 10 },
          { date: "Aug 5", count: 4, static: 10 },
          { date: "Aug 6", count: 1, static: 10 },
        ],
        count: 4,
      },
      {
        name: "likes",
        stat: [
          { date: "Jul 16", count: 2, static: 10 },
          { date: "Aug 1", count: 1, static: 10 },
          { date: "Aug 7", count: 1, static: 10 },
        ],
        count: 4,
      },
      {
        name: "comments",
        stat: [
          { date: "Jul 16", count: 2, static: 10 },
          { date: "Aug 1", count: 1, static: 10 },
          { date: "Aug 7", count: 1, static: 10 },
        ],
        count: 4,
      },
      {
        name: "reposts",
        stat: [
          { date: "Jul 16", count: 2, static: 10 },
          { date: "Aug 1", count: 1, static: 10 },
          { date: "Aug 7", count: 1, static: 10 },
        ],
        count: 4,
      },
      {
        name: "downloads",
        stat: [
          { date: "Jul 16", count: 2, static: 10 },
          { date: "Aug 1", count: 1, static: 10 },
          { date: "Aug 7", count: 1, static: 10 },
        ],
        count: 4,
      },
    ],
    topTracks: [
      {
        title: "Track 1",
        playCount: 2,
        imageUrl: "https://upvibez.s3.me-central-1.amazonaws.com/3626569063",
      },
      {
        title: "Track 2",
        playCount: 1,
        imageUrl: "https://upvibez.s3.me-central-1.amazonaws.com/5644171345",
      },
    ],
    topListeners: [
      {
        name: "Listener 1",
        playCount: 3,
        avatarUrl:
          "https://upvibez.s3.me-central-1.amazonaws.com/_mg_9158.jpg-7926762633",
      },
      {
        name: "Listener 2",
        playCount: 1,
        avatarUrl:
          "https://upvibez.s3.me-central-1.amazonaws.com/_mg_9158.jpg-7926762633",
      },
    ],
  });
  const [activeStats, setActiveStats] = useState(0);
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const { offsetWidth } = containerRef.current;
    setWidth(offsetWidth);
  }, [window?.innerWidth]);

  return (
    <div className="min-h-screen bg-globalBg text-white">
      <div className="bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="min-h-[580px] px-4 py-6 max-w-[720px] mx-auto ">
        <div className="flex gap-4">
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-7"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
          <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
            Insights
          </h1>
        </div>

        <div ref={containerRef} className="">
          <Header
            insights={insights}
            activeStats={activeStats}
            setActiveStats={setActiveStats}
          />
          <PlayStatsChart
            width={width}
            playStats={insights?.stats[activeStats]?.stat}
          />

          <div className="my-8 grid lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-2">
            <TopTracks topTracks={insights.topTracks} />
            <TopListeners topListeners={insights.topListeners} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const Header = ({ insights, activeStats, setActiveStats }) => {
  return (
    <div className="header">
      <h2>
        Hey, you have {insights?.stats[activeStats]?.count}{" "}
        {insights?.stats[activeStats]?.name} in the last 30 days
      </h2>
      <div className="flex overflow-x-scroll no-scrollbar gap-2 mt-4">
        {insights?.stats?.map((stat, index) => {
          return (
            <button
              key={index}
              onClick={() => setActiveStats(index)}
              className={`text-nowrap lg:text-[16px] md:text-[14px] sm:text-[12px] py-1.5 rounded-full w-fit ${
                activeStats === index ? "bg-secondary px-4" : "px-4 border"
              }`}
            >
              {stat.count} {stat.name}
            </button>
          );
        })}
      </div>
    </div>
  );
};

const PlayStatsChart = ({ playStats, width }) => {
  return (
    <div className="my-6">
      <BarChart width={width || 500} height={300} data={playStats}>
        <XAxis dataKey="date" />
        {/* <YAxis /> */}
        {/* <Tooltip /> */}
        <Bar dataKey="static" fill="#fff" />
        <Bar dataKey="count" fill="#DE8DD6" />
      </BarChart>
    </div>
  );
};

const TopTracks = ({ topTracks }) => {
  return (
    <div className="p-4 shadow border bg-dark border-white rounded-lg">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Top Tracks</h3>
        <button className="text-[14px]"> See all</button>
      </div>
      <ul className="">
        {topTracks?.map((track, index) => (
          <li key={index} className="flex items-center gap-2 mt-4">
            <img
              src={track.imageUrl}
              height={52}
              width={52}
              className="rounded-lg"
              alt={track.title}
            />
            <div>
              <h4 className="text-[16px]">{track.title}</h4>
              <p className="text-[14px] flex gap-1 items-center">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="size-4"
                >
                  <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
                </svg>
                <span> {track.playCount} plays </span>{" "}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const TopListeners = ({ topListeners }) => {
  return (
    <div className="p-4 shadow bg-dark border border-white rounded-lg">
      <div className="flex justify-between items-center">
        <h3 className="text-xl font-semibold">Top Listeners</h3>
        <button className="text-[14px]"> See all</button>
      </div>
      <ul>
        {topListeners.map((listener, index) => (
          <li key={index} className="flex items-center gap-2 mt-4">
            <img
              src={listener.avatarUrl}
              height={52}
              width={52}
              className="rounded-full"
              alt={listener.name}
            />
            <div>
              <h4 className="text-[16px]">{listener.name}</h4>
              <p className="text-[14px] flex gap-1 items-center">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="currentColor"
                  className="size-4"
                >
                  <path d="M3 3.732a1.5 1.5 0 0 1 2.305-1.265l6.706 4.267a1.5 1.5 0 0 1 0 2.531l-6.706 4.268A1.5 1.5 0 0 1 3 12.267V3.732Z" />
                </svg>
                <span> {listener.playCount} plays </span>{" "}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
