import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";

const AudioContext = createContext();

export const useAudio = () => useContext(AudioContext);

export const AudioProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [trackList, setTrackList] = useState([]);
  const [currentTrack, setCurrentTrack] = useState("");

  const audioRef = useRef(new Audio());

  const loadTrack = (index) => {
    if (trackList[index]) {
      // Pause the current audio before changing the source
      audioRef.current.pause();

      // Set the new source and reset relevant states
      audioRef.current.src = trackList[index].track;
      audioRef.current.load(); // Ensures the browser reloads the new track
      setCurrentTrackIndex(index);
      setCurrentTrack(trackList[index]?._id);
      setIsLoaded(false);
      setCurrentTime(0);
      setDuration(0);
    }
  };

  const playAudio = () => {
    audioRef.current
      .play()
      .then(() => {
        setIsPlaying(true);
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
  };

  const intervalRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      intervalRef.current = setInterval(async () => {
        setCurrentTime(audioRef.current.currentTime);
        setProgress(duration > 0 ? audioRef.current.currentTime / duration : 0);
      }, 500); // Update every second

      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [isPlaying, currentTrackIndex]);

  const pauseAudio = () => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  const seekTo = (time) => {
    audioRef.current.currentTime = time;
    setCurrentTime(time); // Update the currentTime state
  };

  const skipToNext = () => {
    const nextIndex = (currentTrackIndex + 1) % trackList.length;
    loadTrack(nextIndex);
  };

  const skipToPrevious = () => {
    const prevIndex =
      currentTrackIndex === 0 ? trackList.length - 1 : currentTrackIndex - 1;
    loadTrack(prevIndex);
  };

  useEffect(() => {
    const handleLoadedMetadata = () => {
      setDuration(audioRef.current.duration);
    };

    const handleCanPlay = () => {
      setIsLoaded(true);
      if (isPlaying) {
        playAudio(); // Play only if isPlaying is true
      }
    };

    audioRef.current.addEventListener("canplay", handleCanPlay);
    audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    audioRef.current.addEventListener("ended", skipToNext);

    return () => {
      audioRef.current.removeEventListener("canplay", handleCanPlay);
      audioRef.current.removeEventListener(
        "loadedmetadata",
        handleLoadedMetadata
      );
      audioRef.current.removeEventListener("ended", skipToNext);
    };
  }, [isPlaying, duration]);

  useEffect(() => {
    if (currentTrackIndex >= 0 && trackList.length > 0) {
      loadTrack(currentTrackIndex);
      // if (isPlaying) {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
      setIsPlaying(true);
      // }
    }
  }, [currentTrackIndex, trackList]);

  return (
    <AudioContext.Provider
      value={{
        isPlaying,
        isLoaded,
        playAudio,
        pauseAudio,
        skipToNext,
        skipToPrevious,
        trackList,
        setTrackList,
        currentTrackIndex,
        setCurrentTrackIndex,
        audioRef,
        currentTime,
        duration,
        progress,
        currentTrack,
        seekTo,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};
