export const genreData = [
  {
    id: 1,
    name: "Afro House",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/afro-house.jpeg",
  },
  {
    id: 2,
    name: "Disco",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/Disco+Cover.jpeg",
  },
  {
    id: 3,
    name: "Deep House",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/Deep+House+Cover.jpeg",
  },
  {
    id: 4,
    name: "Eletronica",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/Eletronica.jpeg",
  },
  {
    id: 5,
    name: "Indie Rock",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/Indie+Rock.jpeg",
  },
  {
    id: 6,
    name: "Jackin House",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/JACKIN+HOUSE.jpeg",
  },
  {
    id: 7,
    name: "Organic House",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/ORGANIC+HOUSE_+DOWNTEMPO.jpeg",
  },
  {
    id: 8,
    name: "Soul",
    img: "https://upvibez.s3.me-central-1.amazonaws.com/Soul+Music+Cover.jpeg",
  },
];
