import logo from "./logo.svg";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Signup from "./pages/Signup";
import Upload from "./pages/Upload";
import Profile from "./pages/Profile";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EULA from "./pages/EULA";
import TermsAndConditions from "./pages/TermsAndConditions";

import { Toaster } from "react-hot-toast";
import PrivateRoute from "./component/HOC/PrivateRoute";
import { useDispatch, useSelector } from "react-redux";
import { isUserLoggedIn } from "./redux/actions";
import { useEffect } from "react";
import RecoverPassword from "./pages/RecoverPassword";
import NewPassword from "./pages/NewPassword";
import Account from "./pages/Account/Account";
import Overview from "./pages/Account/Overview";
import ManageYourPlan from "./pages/Account/ManageYourPlan";
import EditProfile from "./pages/Account/EditProfile";
import RecoverPlaylists from "./pages/Account/RecoverPlaylists";
import OrderHistory from "./pages/Account/OrderHistory";
import SavedPaymentCards from "./pages/Account/SavedPaymentCards";
import ManageApps from "./pages/Account/ManageApps";
import Notifications from "./pages/Account/Notifications";
import Privacy from "./pages/Account/Privacy";
import LoginMethod from "./pages/Account/LoginMethod";
import ManageDevices from "./pages/Account/ManageDevices";
import SignOutEverywhere from "./pages/Account/SignOutEverywhere";
import Support from "./pages/Account/Support";
import Reedem from "./pages/Reedem";
import SubscriptionPlans from "./pages/Subscription/SubscriptionPlans";
import Receipt from "./pages/Account/Receipt";
import Purchase from "./pages/Subscription/Purchase";
import Success from "./pages/Subscription/Success";
import FAQ from "./pages/FAQ";
import Subscription from "./pages/Subscription/Subscription";
import SongDetails from "./pages/SongDetails";
import ArtistRoute from "./component/HOC/ArtistRoute";
import { AudioPlayer } from "./component/HOC/Audio";
import { Insight } from "./pages/Insight";
import InvitationSignup from "./pages/Invitation/Invitation";
import Refer from "./pages/Account/Refer";
import Artist from "./pages/Public/Artist";
// import Playlist from "./pages/Playlist";
import Home2 from "./pages/Public/Home";
import Playlist from "./pages/Public/Playlist";
import Genre from "./pages/Public/Genre";
import Album from "./pages/Public/Album";
import LikedPlaylist from "./pages/Public/LikedPlaylist";

function App() {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!auth.authenticate) {
      dispatch(isUserLoggedIn());
    }
  }, []);

  return (
    <div className="bg-globalBg ">
      <Routes>
        <Route path={"/"} element={auth.authenticate ? <Home /> : <Home2 />} />
        <Route path={"/home"} element={<Home />} />
        <Route path={"/playlist/:id"} element={<Playlist />} />
        <Route path={"/playlist/liked"} element={<LikedPlaylist />} />
        <Route path={"/album/:id"} element={<Album />} />
        <Route path={"/genre/:genre"} element={<Genre />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<RecoverPassword />} />
        <Route path="/set-new-password" element={<NewPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/invitation" element={<InvitationSignup />} />
        <Route path="/help" element={<Support />} />
        <Route path="/terms-and-condition" element={<TermsAndConditions />} />
        <Route path="/user-license-agreement" element={<EULA />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/track/:permalink" element={<SongDetails />} />
        <Route path="/public/artist/:id" element={<Artist />} />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile/insights"
          element={
            <PrivateRoute>
              <Insight />
            </PrivateRoute>
          }
        />
        <Route
          path="/upload"
          element={
            <ArtistRoute>
              <Upload />
            </ArtistRoute>
          }
        />

        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          }
        >
          <Route index element={<Purchase />} />
          <Route path="purchase" element={<Purchase />} />
          <Route path="success" element={<Success />} />
        </Route>

        <Route
          path="/account"
          element={
            <PrivateRoute>
              <Account />
            </PrivateRoute>
          }
        >
          <Route index element={<Overview />} />
          <Route path="overview" element={<Overview />} />
          <Route path="manage-your-plan" element={<ManageYourPlan />} />
          <Route path="profile" element={<EditProfile />} />
          <Route path="recover-playlists" element={<RecoverPlaylists />} />
          <Route path="refer" element={<Refer />} />
          <Route path="order-history" element={<OrderHistory />} />
          <Route path="receipt" element={<Receipt />} />
          <Route path="saved-payment-cards" element={<SavedPaymentCards />} />
          <Route path="apps" element={<ManageApps />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="login-methods" element={<LoginMethod />} />
          <Route path="set-device-password" element={<ManageDevices />} />
          <Route path="sign-out-everywhere" element={<SignOutEverywhere />} />
        </Route>
        <Route
          path="/subscription-plans"
          element={
            <PrivateRoute>
              <SubscriptionPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/redeem"
          element={
            <PrivateRoute>
              <Reedem />
            </PrivateRoute>
          }
        />

        <Route path="/.well-known/apple-developer-merchantid-domain-association"></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Toaster />
      <AudioPlayer />
    </div>
  );
}

export default App;
