import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/shared/Footer";
import Navbar from "../component/shared/Navbar";

const faqData = [
  {
    category: "Users",
    questions: [
      {
        question: "1. Create your account",
        answer: (
          <p>
            Sign up for Upvibez by creating an account.{" "}
            <Link to={"/signup"} className="underline">
              Signup here
            </Link>
          </p>
        ),
      },
      {
        question: "2. Login",
        answer: (
          <p>Once registered, you will be redirected to the login page.</p>
        ),
      },
      {
        question: "3. Choose your favorite music styles",
        answer: (
          <p>
            Select your favorite genres so we can recommend new music, artists,
            and playlists tailored to your taste.
          </p>
        ),
      },
      {
        question: "4. Set up your homepage",
        subquestions: [
          { question: "4.1. Changing the color of your homepage." },
          { question: "4.2. Editing the order of your playlists." },
          { question: "4.3. Reordering songs within your playlists." },
        ],
        answer: <p>Customize your home experience.</p>,
      },
      {
        question: "5. Follow your favorite artists",
        answer: (
          <p>
            Stay updated with new releases and DJ sets from your favorite
            artists.
          </p>
        ),
      },
      {
        question: "6. Follow your favorite playlists",
        answer: <p>Keep track of your favorite playlists for easy access.</p>,
      },
      {
        question: "7. Show love with a heart",
        answer: (
          <p>
            Add a heart to the playlists or DJ sets you love to help improve our
            algorithm and receive better recommendations.
          </p>
        ),
      },
      {
        question: "8. Ask our AI DJ",
        subquestions: [
          {
            question: "8.1. How to use Vibez",
            answer: (
              <p>
                Use Vibez to explore and discover more music, with
                recommendations curated just for you.
              </p>
            ),
          },
        ],
        answer: (
          <p>
            Get personalized music suggestions from our AI DJ, whether you're
            looking for something new or the perfect DJ set for a special
            occasion.
          </p>
        ),
      },
    ],
  },
  {
    category: "For Artist",
    questions: [
      {
        question: "1. How do I sign up as a DJ on Upvibez?",
        answer: (
          <p>
            Simply create a DJ account by signing up on Upvibez, providing the
            necessary details to get started.
          </p>
        ),
      },
      {
        question: "2. How do I upload my DJ sets?",

        answer: (
          <ul className="list-disc ">
            <li>Log in to your DJ account.</li>
            <li className="my-2">
              Click the 'Upload' button on your dashboard.
            </li>
            <li>
              Choose the DJ set or mix you want to upload from your device.
            </li>
            <li className="my-2">
              Add as many relevant hashtags as possible to help our algorithm
              recommend your DJ sets to the right venues and users.
            </li>
            <li>List all music and artists used in your DJ set.</li>
            <li className="my-2">
              Choose a cover and hit 'Publish' to make your set available.
            </li>
          </ul>
        ),
      },
      {
        question: "3. How can I monetize my DJ sets?",
        subquestions: [
          {
            question:
              "3.1 You can monetize your page once you reach 1,000 real users.",
          },
          {
            question:
              "3.2 DJ sets can only be monetized if they comply with our guidelines, which require you to list all tracks and artists used in the recording.",
          },
          {
            question:
              "3.3 We count real users based on the time they actively listen to your DJ set.",
          },
          {
            question:
              "3.4 Focus on growing your authentic audience by sharing your sets on social media and creating content that resonates with your listeners.",
          },
        ],
      },
      {
        question: "4. Can I organize my DJ sets into playlists?",
        answer: (
          <p>
            Yes, you can organize your uploaded DJ sets into playlists. This
            helps your audience easily find sets that suit different moods or
            events.
          </p>
        ),
      },
      {
        question: "5. How do I promote my DJ sets on Upvibez?",
        answer: (
          <p>
            After uploading, you can promote your DJ sets by sharing them on
            your social media, engaging with listeners, and allowing businesses
            to discover and use your sets for their venues.
          </p>
        ),
      },
      {
        question: "6. Can businesses use my DJ sets in their venues?",
        answer: (
          <p>
            Yes! Businesses can browse, select, and play your DJ sets in their
            venues. This allows you to reach new audiences and potentially gain
            more followers.
          </p>
        ),
      },
      {
        question: "7. How do I track engagement with my DJ sets?",
        answer: (
          <p>
            Upvibez provides analytics to help you track how your DJ sets are
            performing. You can see the number of plays, likes, which businesses
            are using your sets, countries with the most audience, audience age
            range.
          </p>
        ),
      },
      {
        question: "8. Can I get feedback from listeners?",
        answer: (
          <p>
            Yes, listeners can leave feedback by liking or commenting on your
            sets, helping you understand what resonates with your audience.
          </p>
        ),
      },
      {
        question: "9. Is there a limit on the number of DJ sets I can upload?",
        answer: (
          <p>
            No, there’s no limit! You can upload as many DJ sets as you want and
            organize them to suit different audiences.
          </p>
        ),
      },
      {
        question: "10. Can I edit or delete my DJ sets?",
        answer: (
          <p>
            Yes, you can easily edit or delete your DJ sets at any time through
            your dashboard.
          </p>
        ),
      },
      {
        question: "11. Set up your homepage",
        subquestions: [
          { question: "11.1. Changing the color of your homepage." },
          { question: "11.2. Editing the order of your playlists." },
          { question: "11.3. Reordering songs within your playlists." },
        ],
        answer: (
          <p>
            You can customize your home experience by saving DJ sets from other
            playlists or DJs and making them private.
          </p>
        ),
      },
    ],
  },
  {
    category: "For Businesses",
    questions: [
      {
        question: "1. Create your business account",
        answer: (
          <p>
            Sign up for Upvibez with a business account to access exclusive
            features.
          </p>
        ),
      },
      {
        question: "2. Login",
        answer: <p>After registration, log in to your business account.</p>,
      },
      {
        question: "3. Customize your music experience",
        answer: (
          <p>
            Choose music styles and genres that match your business atmosphere
            to create the perfect mood for your venue.
          </p>
        ),
      },
      {
        question: "4. Vibez, AI DJ on demand in your pocket",
        subquestions: [
          {
            question: "4.1 How to use Vibez for Business",
            answer: (
              <p>
                Vibez offers tailored recommendations and tools to help your
                business create the best musical experience.
              </p>
            ),
          },
          {
            question:
              "4.2 Ask Vibez which DJ set is the best option for your business type.",
          },
          {
            question:
              "4.3 Curated Playlists by Vibez: Vibez will curate the best DJ sets tailored to your needs.",
          },
        ],
      },
      {
        question: "5. Save the best DJ sets for your business",
        answer: (
          <p>
            We provide more personalized recommendations based on your music
            preferences.
          </p>
        ),
      },
      {
        question:
          "6. Create playlists based on the time of day and day of the week",
        answer: (
          <p>
            Access a tailored music experience that enhances customer retention
            and revenue growth.
          </p>
        ),
      },
      {
        question: "7. Access DJ sets on demand",
        answer: (
          <p>
            Instantly play DJ sets from a variety of professional DJs to enhance
            the experience in your space.
          </p>
        ),
      },
      {
        question: "8. Follow curated playlists",
        answer: (
          <p>Browse and follow curated playlists designed for businesses.</p>
        ),
      },
      {
        question: "9. Manage your music remotely",
        answer: (
          <p>
            Control and update your music from anywhere with the Upvibez app.
          </p>
        ),
      },
      {
        question: "10. Monitor customer engagement",
        answer: (
          <p>
            Track customer interactions with your music by monitoring likes and
            engagement.
          </p>
        ),
      },
      {
        question: "11. Create and share events and schedules",
        answer: (
          <p>
            Share upcoming events and schedules directly with your audience.
          </p>
        ),
      },
      {
        question: "12. Create and share playlists with your customers",
        answer: (
          <p>
            Tailor and distribute playlists to enhance your customers'
            experience.
          </p>
        ),
      },
      {
        question: "13. Set up your homepage",
        subquestions: [
          { question: "13.1. Changing the color of your homepage." },
          { question: "13.2. Editing the order of your playlists." },
          { question: "13.3. Reordering songs within your playlists." },
        ],
      },
    ],
  },
];

const FAQ = () => {
  return (
    <div className="min-h-screen bg-globalBg text-white">
      <div className="bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="min-h-[580px] px-4 py-6 max-w-[720px] mx-auto ">
        {faqData?.map((section, index) => {
          return (
            <div className="mb-8" key={index}>
              <p className="font-bold tracking-tighter text-[24px] mb-4">
                {section?.category}
              </p>
              {section?.questions?.map((q, qIndex) => {
                return (
                  <Accordion
                    title={q?.question}
                    first={qIndex === 0}
                    last={qIndex + 1 === section?.questions?.length}
                    key={qIndex}
                  >
                    <div className="bg-bgLightDark rounded-xl flex flex-col gap-6 py-3 px-2">
                      {q?.answer && (
                        <p className="text-white ml-4 ">{q?.answer}</p>
                      )}

                      {q?.subquestions && (
                        <div>
                          {q?.subquestions?.map((sub, subIndex) => {
                            return (
                              <Accordion
                                title={sub?.question}
                                first={subIndex === 0}
                                last={subIndex + 1 === q?.subquestions?.length}
                                key={subIndex}
                              >
                                <p>{/* ANSWER */}</p>
                              </Accordion>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </Accordion>
                );
              })}
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;

const Accordion = ({ title, children, first, last }) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  //   console.log(children);

  return (
    <div
      className={
        first
          ? "w-full bg-cardBg rounded-t-xl py-2.5 border-b-[0.45px] border-gray-600 "
          : last
          ? "w-full bg-cardBg py-2.5 rounded-b-xl"
          : "w-full bg-cardBg py-2.5 border-b-[0.45px] border-gray-600"
      }
    >
      <button
        className="w-full flex justify-between items-center py-3 px-4   focus:outline-none"
        onClick={toggleAccordion}
      >
        <p className="font-medium text-[15px] text-left">{title}</p>
        <svg
          className={`w-4 h-4 transform transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        ref={contentRef}
        className="overflow-hidden transition-all duration-500 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
        }}
      >
        <div className="m-4  text-[14px]">{children}</div>
      </div>
    </div>
  );
};
