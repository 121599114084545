import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BtnFilled } from "../../component/button";
import { dot_gif, person_icon } from "../../assets/images";
import axiosInstance from "../../redux/helpers/axios";
import toast from "react-hot-toast";

const Refer = () => {
  const navigate = useNavigate();

  const [referUrl, setReferUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const ReferLink = {
    url: "https://upvibez.com/signup",
    user: "",
    signUpUsers: [
      {
        name: "Alice Johnson",
        email: "alice.johnson@example.com",
        image: "https://randomuser.me/api/portraits/women/1.jpg",
        userId: "user_001",
      },
      {
        name: "Bob Smith",
        email: "bob.smith@example.com",
        image: "https://randomuser.me/api/portraits/men/1.jpg",
        userId: "user_002",
      },
      {
        name: "Charlie Brown",
        email: "charlie.brown@example.com",
        image: "https://randomuser.me/api/portraits/men/2.jpg",
        userId: "user_003",
      },
      {
        name: "Diana Prince",
        email: "diana.prince@example.com",
        image: "https://randomuser.me/api/portraits/women/2.jpg",
        userId: "user_004",
      },
      {
        name: "Ethan Hunt",
        email: "ethan.hunt@example.com",
        image: "https://randomuser.me/api/portraits/men/3.jpg",
        userId: "user_005",
      },
    ],
  };

  const generateReferUrl = () => {
    setLoading(true);
    const id = toast.loading("Loading...");
    axiosInstance
      .get(`/user/refer/create`)
      .then((res) => {
        setReferUrl(res?.data?.ref?.user);
        toast.success("URL Generated.", { id: id, duration: 2000 });
        setLoading(false);
      })
      .catch((err) => {
        const { data } = err.response;
        toast.error(`${data.msg}`, { id: id, duration: 2000 });
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/user/refer/get-link`)
      .then((res) => {
        setReferUrl(res?.data?.ref?.user);
        setLoading(false);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`/user/refer/get-user-list`)
      .then((res) => {
        setUsers(res?.data?.users);
        setLoading(false);
      })
      .catch((err) => {
        const { data } = err.response;
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://upvibez.com/signup?referralCode=${referUrl}`
      );
      toast.success("URL copied to clipboard!", { duration: 1000 });
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Refer a friend
        </h1>
        <div>
          {loading ? (
            <div className="flex justify-center items-center mt-4">
              <img src={dot_gif} className="h-[100px]" />
            </div>
          ) : (
            <>
              {referUrl === null ? (
                <>
                  <div className="flex gap-2 w-full bg-[#2A2A2A] rounded p-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                      />
                    </svg>

                    <p>You don't have any refer link</p>
                  </div>
                  <div className="flex justify-center mt-6">
                    <BtnFilled
                      text={"Generate Refer Link"}
                      onClick={generateReferUrl}
                      width={260}
                      bgColor={"#6813f2"}
                      textColor={"white"}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <div className="flex justify-between gap-2 w-full bg-[#2A2A2A] rounded p-4">
                    <div className="flex gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                        />
                      </svg>
                      <p> https://upvibez.com/signup?referralCode={referUrl}</p>
                    </div>
                    <button className="btn btn-circle" onClick={handleCopy}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184"
                        />
                      </svg>
                    </button>
                  </div>

                  {users.length > 0 ? (
                    <div className="mt-6">
                      <h2 className="text-lg">Signed Up Users</h2>

                      <div style={{ borderSpacing: "0 10px" }}>
                        <div className="mt-4">
                          <div className="w-full lg:text-[16px] sm:text-[11px] flex px-2 py-2.5 justify-between lg:gap-1 sm:gap-3 text-[14px] text-gray-400 border-b-[0.45px] border-b-gray-700">
                            <p className="text-left w-2/12"> Name</p>
                            <p className="text-left w-4/12"> Email</p>
                          </div>
                        </div>
                        <div className="mt-2">
                          {users.map((x, index) => (
                            <div
                              key={index}
                              className="w-full lg:text-[16px] sm:text-[11px] flex px-2 py-2.5 justify-between lg:gap-1 sm:gap-3 text-[14px] text-gray-400 border-b-[0.45px] border-b-gray-700"
                            >
                              <div className="flex gap-2 items-center">
                                {" "}
                                {x?.avatar?.url === "" ||
                                x?.avatar?.url === undefined ||
                                x?.avatar?.url === null ? (
                                  <div className="rounded-full bg-warning w-[40px] h-[40px] flex justify-center items-center">
                                    <img
                                      height={20}
                                      width={20}
                                      src={person_icon}
                                      alt="img"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundImage: x?.avatar?.url
                                        ? `url(${x?.avatar?.url})`
                                        : null,

                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                    }}
                                    className={`rounded-full w-[40px] h-[40px] flex justify-center items-center`}
                                  ></div>
                                )}
                                <div>
                                  <p className="text-[16px]">{x?.name}</p>
                                </div>
                              </div>
                              <p className="text-[16px] text-info">
                                {x?.email}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Refer;
