import React from "react";
import { useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

const LeftNav = ({ linkArray, style, deviceScreenType }) => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  return (
    <div className="bg-dark px-3 py-6 rounded-lg flex flex-col gap-4  sticky top-20  ">
      <Link to="/" className="flex gap-3 items-center">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="white"
            className="lg:size-5 md:size-8"
          >
            <path
              fillRule="evenodd"
              d="M9.293 2.293a1 1 0 0 1 1.414 0l7 7A1 1 0 0 1 17 11h-1v6a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-3a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-6H3a1 1 0 0 1-.707-1.707l7-7Z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        {deviceScreenType === "lg" && (
          <span className="text-[16px] text-white">Home</span>
        )}
      </Link>
      {auth?.authenticate && (
        <Link to="/profile" className="flex gap-3 items-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="lg:size-5 md:size-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
          </div>

          {deviceScreenType === "lg" && (
            <span className="text-[16px] text-white">Profile</span>
          )}
        </Link>
      )}
      {/* <Link to="/search" className="flex gap-3">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="white"
            className="lg:size-5 md:size-8"
          >
            <path
              fillRule="evenodd"
              d="M9 3.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11ZM2 9a7 7 0 1 1 12.452 4.391l3.328 3.329a.75.75 0 1 1-1.06 1.06l-3.329-3.328A7 7 0 0 1 2 9Z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        {deviceScreenType === "lg" && (
          <span className="text-[16px] text-white">Search</span>
        )}
      </Link> */}

      {/* <div className="flex justify-between items-center  ">
        <Link to="/playlist" className="flex gap-3 items-center">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="white"
              className="lg:size-5 md:size-8"
            >
              <path d="M5.127 3.502 5.25 3.5h9.5c.041 0 .082 0 .123.002A2.251 2.251 0 0 0 12.75 2h-5.5a2.25 2.25 0 0 0-2.123 1.502ZM1 10.25A2.25 2.25 0 0 1 3.25 8h13.5A2.25 2.25 0 0 1 19 10.25v5.5A2.25 2.25 0 0 1 16.75 18H3.25A2.25 2.25 0 0 1 1 15.75v-5.5ZM3.25 6.5c-.04 0-.082 0-.123.002A2.25 2.25 0 0 1 5.25 5h9.5c.98 0 1.814.627 2.123 1.502a3.819 3.819 0 0 0-.123-.002H3.25Z" />
            </svg>
          </div>

          {deviceScreenType === "lg" && (
            <span className="text-[16px] text-white ">Library</span>
          )}
        </Link>

        {deviceScreenType === "lg" && (
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              className="size-5"
            >
              <path
                fillRule="evenodd"
                d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div> */}

      {deviceScreenType === "lg" && (
        <div className="px-4 py-5 bg-secondary rounded-lg mt-0">
          <p className="text-[15px] text-white font-semibold">
            Create your first playlist
          </p>
          <p className="text-[12px] text-white ">
            It's easy, we'll help you out
          </p>
          <button
            className="bg-white px-4 py-1 rounded-full mt-5 text-[14px]"
            onClick={() => navigate("/profile?activeTab=4")}
          >
            {auth?.authenticate ? "Create Playlist" : "Login"}
          </button>
        </div>
      )}

      {deviceScreenType === "lg" && (
        <div className="mt-4 px-4 pb-6">
          <div className="flex flex-wrap gap-4 ">
            {linkArray?.map((link, index) => {
              if (index < 3)
                return (
                  <Link
                    key={index}
                    to={link.route}
                    className="text-[10px] text-white"
                  >
                    {link?.name}
                  </Link>
                );
            })}
          </div>
          <div className="flex flex-wrap gap-4 my-3">
            {linkArray?.map((link, index) => {
              if (index > 2 && index < 6)
                return (
                  <Link to="" className="text-[10px] text-white">
                    {link?.name}
                  </Link>
                );
            })}
          </div>
          <div className="flex flex-wrap gap-4 ">
            {linkArray?.map((link, index) => {
              if (index > 5 && index < 9)
                return (
                  <Link key={index} to="" className="text-[10px] text-white">
                    {link.name}
                  </Link>
                );
            })}
          </div>

          <button className=" border border-white px-4 py-1 flex gap-1 items-center justify-center rounded-full mt-6 ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="white"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M3.757 4.5c.18.217.376.42.586.608.153-.61.354-1.175.596-1.678A5.53 5.53 0 0 0 3.757 4.5ZM8 1a6.994 6.994 0 0 0-7 7 7 7 0 1 0 7-7Zm0 1.5c-.476 0-1.091.386-1.633 1.427-.293.564-.531 1.267-.683 2.063A5.48 5.48 0 0 0 8 6.5a5.48 5.48 0 0 0 2.316-.51c-.152-.796-.39-1.499-.683-2.063C9.09 2.886 8.476 2.5 8 2.5Zm3.657 2.608a8.823 8.823 0 0 0-.596-1.678c.444.298.842.659 1.182 1.07-.18.217-.376.42-.586.608Zm-1.166 2.436A6.983 6.983 0 0 1 8 8a6.983 6.983 0 0 1-2.49-.456 10.703 10.703 0 0 0 .202 2.6c.72.231 1.49.356 2.288.356.798 0 1.568-.125 2.29-.356a10.705 10.705 0 0 0 .2-2.6Zm1.433 1.85a12.652 12.652 0 0 0 .018-2.609c.405-.276.78-.594 1.117-.947a5.48 5.48 0 0 1 .44 2.262 7.536 7.536 0 0 1-1.575 1.293Zm-2.172 2.435a9.046 9.046 0 0 1-3.504 0c.039.084.078.166.12.244C6.907 13.114 7.523 13.5 8 13.5s1.091-.386 1.633-1.427c.04-.078.08-.16.12-.244Zm1.31.74a8.5 8.5 0 0 0 .492-1.298c.457-.197.893-.43 1.307-.696a5.526 5.526 0 0 1-1.8 1.995Zm-6.123 0a8.507 8.507 0 0 1-.493-1.298 8.985 8.985 0 0 1-1.307-.696 5.526 5.526 0 0 0 1.8 1.995ZM2.5 8.1c.463.5.993.935 1.575 1.293a12.652 12.652 0 0 1-.018-2.608 7.037 7.037 0 0 1-1.117-.947 5.48 5.48 0 0 0-.44 2.262Z"
                clipRule="evenodd"
              />
            </svg>
            <p className="text-[12px] text-white font-">English</p>
          </button>
        </div>
      )}
    </div>
  );
};

export default LeftNav;
