import toast from "react-hot-toast";
import axiosInstance from "../helpers/axios";
import { songConstant } from "./constants";

export const uploadSong = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Uploading...");
    try {
      dispatch({ type: songConstant.SONG_UPLOAD_REQUEST });

      const res = await axiosInstance.post(`/song/create`, data);

      if (res.status === 201) {
        const { song, msg } = res.data;
        dispatch({
          type: songConstant.SONG_UPLOAD_SUCCESS,
          payload: song,
        });
        toast.success(`${msg}`, { id: id, duration: 1500 });
      }
    } catch (error) {
      const { data } = error.response;
      dispatch({
        type: songConstant.SONG_UPLOAD_FAILURE,
        payload: data,
      });
      toast.error(`${data?.msg}`, { id: id, duration: 1500 });
    }
  };
};

export const getTracksByArtist = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.SONG_BY_ARTIST_REQUEST });
      const res = await axiosInstance.post(
        `/song/public/get-tracks-by-artist`,
        data
      );

      if (res.status === 200) {
        const { tracks } = res.data;
        dispatch({
          type: songConstant.SONG_BY_ARTIST_SUCCESS,
          payload: tracks,
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.SONG_BY_ARTIST_FAILURE,
        payload: data,
      });
    }
  };
};

export const getTracksByUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.SONG_BY_USER_REQUEST });
      const res = await axiosInstance.get(`/song/get-tracks-by-user`);

      if (res.status === 200) {
        const { tracks } = res.data;
        dispatch({
          type: songConstant.SONG_BY_USER_SUCCESS,
          payload: tracks,
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.SONG_BY_USER_FAILURE,
        payload: data,
      });
    }
  };
};

export const getTrackByPermalink = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.SONG_BY_PERMALINK_REQUEST });
      const res = await axiosInstance.post(
        `/song/public/get-track-by-permalink`,
        data
      );

      if (res.status === 200) {
        const { track, bg } = res.data;
        dispatch({
          type: songConstant.SONG_BY_PERMALINK_SUCCESS,
          payload: { track: track, bg: bg },
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.SONG_BY_PERMALINK_FAILURE,
        payload: data,
      });
    }
  };
};

export const getFeaturedTracks = (limit) => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.FEATURED_TRACKS_REQUEST });
      const res = await axiosInstance.get(
        `/song/public/get-featured-tracks?limit=${limit}`
      );

      if (res.status === 200) {
        const { tracks } = res.data;
        dispatch({
          type: songConstant.FEATURED_TRACKS_SUCCESS,
          payload: tracks,
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.FEATURED_TRACKS_FAILURE,
        payload: data,
      });
    }
  };
};

export const getTracksByGenre = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.SONG_BY_GENRE_REQUEST });
      const res = await axiosInstance.get(
        `/song/public/get-tracks-by-genre?genre=${data?.genre}&img=${data?.img}`
      );

      if (res.status === 200) {
        dispatch({
          type: songConstant.SONG_BY_GENRE_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.SONG_BY_GENRE_FAILURE,
        payload: data,
      });
    }
  };
};

export const getFeaturedArtists = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: songConstant.FEATURED_ARTISTS_REQUEST });
      const res = await axiosInstance.get(`/song/public/get-featured-artists`);

      if (res.status === 200) {
        const { topUsers } = res.data;
        dispatch({
          type: songConstant.FEATURED_ARTISTS_SUCCESS,
          payload: topUsers,
        });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.FEATURED_ARTISTS_FAILURE,
        payload: data,
      });
    }
  };
};

export const handleLikeSong = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: songConstant.HANDLE_LIKE_REQUEST });
      const res = await axiosInstance.post(`/song/handle-like`, data);

      if (res.status === 200) {
        const { msg, song } = res.data;
        dispatch({
          type: songConstant.HANDLE_LIKE_SUCCESS,
          payload: song,
        });
        toast.success(`${msg}`, { duration: 2000, id: id });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.HANDLE_LIKE_FAILURE,
        payload: data,
      });
    }
  };
};

export const postComment = (data) => {
  return async (dispatch) => {
    const id = toast.loading("Loading...");
    try {
      dispatch({ type: songConstant.POST_COMMENT_REQUEST });
      const res = await axiosInstance.post(`/song/post-comment`, data);

      if (res.status === 200) {
        const { msg, song } = res.data;
        dispatch({
          type: songConstant.POST_COMMENT_SUCCESS,
          payload: song,
        });
        toast.success(`${msg}`, { duration: 2000, id: id });
      }
    } catch (error) {
      const { data } = error?.response;
      dispatch({
        type: songConstant.POST_COMMENT_FAILURE,
        payload: data,
      });
    }
  };
};
