import React from "react";

let colors = {
  a: "#FF5733",
  A: "#FF5733",
  b: "#33FF57",
  B: "#33FF57",
  c: "#3357FF",
  C: "#3357FF",
  d: "#FF33A1",
  D: "#FF33A1",
  e: "#FF8C33",
  E: "#FF8C33",
  f: "#33FFF7",
  F: "#33FFF7",
  g: "#8C33FF",
  G: "#8C33FF",
  h: "#FF5733",
  H: "#FF5733",
  i: "#FF33B8",
  I: "#FF33B8",
  j: "#5733FF",
  J: "#5733FF",
  k: "#33FFA1",
  K: "#33FFA1",
  l: "#A1FF33",
  L: "#A1FF33",
  m: "#33FF57",
  M: "#33FF57",
  n: "#FF5733",
  N: "#FF5733",
  o: "#33A1FF",
  O: "#33A1FF",
  p: "#FF33FF",
  P: "#FF33FF",
  q: "#33FFA8",
  Q: "#33FFA8",
  r: "#A833FF",
  R: "#A833FF",
  s: "#FF5733",
  S: "#FF5733",
  t: "#FF33B1",
  T: "#FF33B1",
  u: "#5733FF",
  U: "#5733FF",
  v: "#33FFB1",
  V: "#33FFB1",
  w: "#B1FF33",
  W: "#B1FF33",
  x: "#33FF57",
  X: "#33FF57",
  y: "#FF5733",
  Y: "#FF5733",
  z: "#33A1FF",
  Z: "#33A1FF",
};

const ProfilePicture = ({
  url,
  name,
  height,
  width,
  className,
  nameClass,
  isRounded,
  style,
  ref,
}) => {
  // console.log(name, isRounded);

  return (
    <div style={{ ...style }}>
      {url ? (
        <>
          {/* <img
            // src={url}
            style={{
              backgroundImage: url ? `url(${url})` : null,

              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            height={height}
            width={width}
            className={`rounded-full  ${className}`}
            // alt={name || ""}
          /> */}

          <div
            style={{
              backgroundImage: url ? `url(${url})` : null,

              backgroundPosition: "center",
              backgroundSize: "cover",
              height: { height },
              width: { width },
            }}
            ref={ref}
            // height={height}
            // width={width}
            className={
              isRounded === null ||
              isRounded === undefined ||
              isRounded === true
                ? `rounded-full  ${className}`
                : `rounded ${className}`
            }
          ></div>
        </>
      ) : name ? (
        <div
          style={{
            height: height,
            width: width,
            backgroundColor: colors[name[0]],
          }}
          className={
            isRounded === null || isRounded === undefined || isRounded === true
              ? `bg-dark flex justify-center items-center rounded-full ${className}`
              : `bg-dark flex justify-center items-center  ${className}`
          }
        >
          <h1 className={`${nameClass}`}>{name[0]}</h1>
        </div>
      ) : (
        <div
          style={{
            height: height,
            width: width,
          }}
          className={`bg-dark flex justify-center items-center rounded-full  ${className}`}
        ></div>
      )}
    </div>
  );
};

export default ProfilePicture;
