import React, { useEffect, useState } from "react";
import Navbar from "../component/shared/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { icon_logo, logopngw } from "../assets/logo";
import { TextInputField1 } from "../component/form/InputField";
import { BtnFilled, BtnOutline1 } from "../component/button";
import { sendPasswordResetLink } from "../redux/actions";

const RecoverPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [isEmailSent, setEmailSent] = useState(false);
  const [isEmailWrote, setEmailWrote] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
  };

  const handleSendResetLink = () => {
    dispatch(sendPasswordResetLink(formData));
  };

  useEffect(() => {
    if (auth.isResetPasswordEmailSent) {
      setEmailSent(true);
    } else {
      setEmailSent(false);
    }
  }, [auth]);

  const handleEditEmail = () => {
    setEmailSent(false);
  };
  const handleBackToLogin = () => {
    navigate("/login");
  };

  if (auth.authenticate) {
    navigate("/profile");
  }
  return (
    <div className="bg-dark  min-h-screen flex flex-col justify-between items-center text-white">
      <div className=" flex flex-col justify-center max-w-[320px] mt-4">
        <div className="flex justify-center items-center mt-12">
          <img src={icon_logo} alt="" height={70} width={70} />
        </div>
        {!isEmailSent ? (
          <>
            <h1 className="mt-2 font-bold text-[30px]">Reset your Password</h1>

            <p className="text-[14px] mt-2 mb-6">
              Enter the email address or username linked to your Upvibez account
              and we'll send you an email.
            </p>

            <TextInputField1
              type={"email"}
              name={"email"}
              label={"Email address or username"}
              placeholder={""}
              value={formData?.email}
              onChange={handleInputChange}
              warning={isEmailWrote && formData?.email === ""}
              warningText={
                "Please enter your Upvibez username or email address."
              }
            />

            <Link to={"/support"} className="text-[13px] underline mb-6">
              Need Support?
            </Link>

            <BtnFilled
              text={"Send Link"}
              onClick={() => handleSendResetLink()}
              width={320}
              bgColor={"#6813f2"}
              textColor={"white"}
              style={{ marginTop: 4 }}
            />
          </>
        ) : (
          <>
            <h1 className="mt-2 font-bold text-[30px]">Check your inbox</h1>

            <p className="text-[14px] mt-2 mb-6">
              We've sent you an email. Follow the instructions to access your
              Upvibez account.
            </p>

            <BtnFilled
              text={"Back to login"}
              onClick={() => handleBackToLogin()}
              width={320}
              bgColor={"#6813f2"}
              textColor={"white"}
              style={{ marginTop: 4 }}
            />
            <BtnOutline1
              text={"Edit email/username"}
              onClick={() => handleEditEmail()}
              width={320}
              textColor={"white"}
              style={{ marginTop: 8 }}
            />
          </>
        )}
      </div>
      <div className="mt-4 bg-dark py-9 flex justify-center items-center w-full px-4">
        <a href="" className="text-[11px] text-gray-500">
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </a>
      </div>
    </div>
  );
};

export default RecoverPassword;
