import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import Cropper from "react-easy-crop";
import ProfilePicture from "../component/shared/ProfilePicture";
import { BtnFilled, IconButton, SmallIconButton } from "../component/button";
import {
  createAlbum,
  createPlaylist,
  getAlbumByUser,
  getPlaylist,
  getTracksByUser,
  getUserByToken,
  postGalleryImg,
} from "../redux/actions";
import { useAudio } from "../component/context/audio";
import { useModal } from "../component/context/modal";
import Navbar from "../component/shared/Navbar";
import axiosInstance from "../redux/helpers/axios";
import { dot_gif } from "../assets/images";
import { appstore, googleplay } from "../assets/images";
import {
  SelectInputCustom,
  TextInputField1,
  UploadTextAreaField,
} from "../component/form/InputField";
import { countryNames } from "../assets/data/dataArray";

import "react-datepicker/dist/react-datepicker.css";

let btnList = [
  {
    id: 0,
    name: "All",
    tag: "",
    isDisabled: false,
  },
  {
    id: 1,
    name: "Popular tracks",
    tag: "",
    isDisabled: false,
  },
  {
    id: 2,
    name: "Tracks",
    tag: "",
    isDisabled: false,
  },
  {
    id: 3,
    name: "Albums",
    tag: "",
    isDisabled: false,
  },
  {
    id: 4,
    name: "Playlists",
    tag: "",
    isDisabled: false,
  },
  {
    id: 5,
    name: "Reposts",
    tag: "",
    isDisabled: false,
  },
  {
    id: 6,
    name: "Events",
    tag: "",
    isDisabled: false,
  },
  {
    id: 7,
    name: "Gallery",
    tag: "",
    isDisabled: false,
  },
];

let musicbuttons = [
  {
    id: 0,
    name: "0",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="size-3"
      >
        <path d="m9.653 16.915-.005-.003-.019-.01a20.759 20.759 0 0 1-1.162-.682 22.045 22.045 0 0 1-2.582-1.9C4.045 12.733 2 10.352 2 7.5a4.5 4.5 0 0 1 8-2.828A4.5 4.5 0 0 1 18 7.5c0 2.852-2.044 5.233-3.885 6.82a22.049 22.049 0 0 1-3.744 2.582l-.019.01-.005.003h-.002a.739.739 0 0 1-.69.001l-.002-.001Z" />
      </svg>
    ),
  },
  {
    id: 1,
    name: "Repost",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="size-3"
      >
        <path
          fillRule="evenodd"
          d="M12 5.25c1.213 0 2.415.046 3.605.135a3.256 3.256 0 0 1 3.01 3.01c.044.583.077 1.17.1 1.759L17.03 8.47a.75.75 0 1 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l3-3a.75.75 0 0 0-1.06-1.06l-1.752 1.751c-.023-.65-.06-1.296-.108-1.939a4.756 4.756 0 0 0-4.392-4.392 49.422 49.422 0 0 0-7.436 0A4.756 4.756 0 0 0 3.89 8.282c-.017.224-.033.447-.046.672a.75.75 0 1 0 1.497.092c.013-.217.028-.434.044-.651a3.256 3.256 0 0 1 3.01-3.01c1.19-.09 2.392-.135 3.605-.135Zm-6.97 6.22a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.752-1.751c.023.65.06 1.296.108 1.939a4.756 4.756 0 0 0 4.392 4.392 49.413 49.413 0 0 0 7.436 0 4.756 4.756 0 0 0 4.392-4.392c.017-.223.032-.447.046-.672a.75.75 0 0 0-1.497-.092c-.013.217-.028.434-.044.651a3.256 3.256 0 0 1-3.01 3.01 47.953 47.953 0 0 1-7.21 0 3.256 3.256 0 0 1-3.01-3.01 47.759 47.759 0 0 1-.1-1.759L6.97 15.53a.75.75 0 0 0 1.06-1.06l-3-3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: "Share",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
      </svg>
    ),
  },
  {
    id: 3,
    name: "Copy Link",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path
          fillRule="evenodd"
          d="M10.986 3H12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h1.014A2.25 2.25 0 0 1 7.25 1h1.5a2.25 2.25 0 0 1 2.236 2ZM9.5 4v-.75a.75.75 0 0 0-.75-.75h-1.5a.75.75 0 0 0-.75.75V4h3Z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
  {
    id: 4,
    name: "More",
    tag: "",
    isDisabled: false,
    icon: "",
    bgColor: "",
    handleOnClick: () => {
      return null;
    },
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 16"
        fill="currentColor"
        className="size-3"
      >
        <path d="M2 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM6.5 8a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0ZM12.5 6.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z" />
      </svg>
    ),
  },
];

const MusicDiv = ({
  isPlaying,
  playAudio,
  pauseAudio,
  showModal,
  track,
  setTrackList,
  setCurrentTrackIndex,
  index,
  tracks,
  currentTrack,
}) => {
  const handlePlayPause = () => {
    if (isPlaying && currentTrack === track?._id) {
      pauseAudio();
    } else if (!isPlaying && currentTrack === track?._id) {
      playAudio();
    } else {
      setTrackList(tracks?.slice().reverse());
      setCurrentTrackIndex(index);
      showModal();
      // playAudio();
    }
  };

  const D = new Date(track?.createdAt);

  function timeAgo(milliseconds) {
    const now = Date.now();
    const diff = now - milliseconds;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const weeks = Math.floor(days / 7);
    const months = Math.floor(days / 30);
    const years = Math.floor(days / 365);

    if (seconds < 60) {
      return `${seconds} seconds ago`;
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 7) {
      return `${days} days ago`;
    } else if (weeks < 4) {
      return `${weeks} weeks ago`;
    } else if (months < 12) {
      return `${months} months ago`;
    } else {
      return `${years} years ago`;
    }
  }
  const navigate = useNavigate();

  return (
    <div className="flex mb-6 gap-4">
      <img
        src={track?.img}
        alt=""
        height={180}
        width={180}
        className="lg:h-[100px] lg:w-[100px] md:h-[120px] md:w-[120px] sm:h-[80px] sm:w-[80px]"
        onClick={() => navigate(`/track/${track?.permalink}`)}
      />
      <div className="w-full">
        <div className=" flex justify-between items-center">
          <div className="flex items-center gap-2">
            <button
              className="lg:block md:block sm:hidden"
              onClick={handlePlayPause}
            >
              {isPlaying && currentTrack === track?._id ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button>
            <div
              className="lg:mr-auto md:mr-auto sm:mr-2 cursor-pointer"
              onClick={() => navigate(`/track/${track?.permalink}`)}
            >
              <p className="text-[13px] text-gray-400">{track?.user?.name}</p>
              <p className="text-[16px]">{track?.title}</p>
            </div>
          </div>
          <>
            <div className="lg:flex md:flex sm:hidden flex-col gap-1">
              <p className="text-[12px]">{timeAgo(D.getTime())}</p>
              <p className="px-1.5 text-center text-[11px] bg-gray-500 rounded-full">
                {track?.tags?.length > 0 && `#${track?.tags[0]}`}
              </p>
            </div>

            {/* <button
              className="lg:hidden md:hidden sm:block "
              onClick={handlePlayPause}
            >
              {isPlaying ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12ZM9 8.25a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75h.75a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H9Zm5.25 0a.75.75 0 0 0-.75.75v6c0 .414.336.75.75.75H15a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75h-.75Z"
                    clipRule="evenodd"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="size-10"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                    clipRule="evenodd"
                  />
                </svg>
              )}
            </button> */}
          </>
        </div>
        <div className="lg:block md:hidden sm:hidden"></div>

        <div className="lg:flex md:flex sm:hidden  gap-1.5 items-center mt-4">
          {musicbuttons?.map((btn, index) => {
            if (btn?.name !== "" || btn?.icon !== "")
              return <SmallIconButton btn={btn} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

const Divider = ({ marginTop }) => (
  <div
    className="border-b-[0.25px] border-b-gray-800 mt-4"
    style={{ marginTop: marginTop }}
  />
);

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const user = JSON.parse(localStorage.getItem("user"));

  const {
    isPlaying,
    playAudio,
    pauseAudio,
    setTrackList,
    currentTrackIndex,
    setCurrentTrackIndex,
    currentTrack,
  } = useAudio();

  const { isVisible, showModal, hideModal } = useModal();

  const [activeBtn, setActiveBtn] = useState(0);
  const [param, setParam] = useState("");
  const [coverImage, setCoverImage] = useState();
  const [avatarImage, setAvatarImage] = useState();
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [showThree, setShowThree] = useState(false);
  const [showFour, setShowFour] = useState(false);
  const [showFive, setShowFive] = useState(false);
  const [showSix, setShowSix] = useState(false);
  const [previw, setPreview] = useState("");
  const [profilePreview, setProfilePreview] = useState("");
  const [playlistPreview, setPlaylistPreview] = useState("");
  const [galleryPreview, setGalleryPreview] = useState("");
  const [albumPreview, setAlbumPreview] = useState("");
  const [galleryImg, setGalleryImg] = useState("");
  const [albumImg, setAlbumImg] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    setActiveTab(searchParams.get("activeTab"));
    setActiveBtn(parseInt(activeTab));
  }, []);

  useEffect(() => {
    if (activeTab !== null) {
      setActiveBtn(parseInt(activeTab));
    } else {
      setActiveBtn(0);
    }
  }, [activeTab]);

  // const [eventName, setEventName] = useState("");
  // const [eventDate, setEventDate] = useState("");
  // const [eventCity, setEventCity] = useState("");
  // const [eventCountry, setEventCountry] = useState("");

  const [formData, setFormData] = useState({
    eventName: "",
    eventCity: "",
    eventCountry: "",
  });

  const [playlistData, setPlaylistData] = useState({
    name: "",
    img: "",
    tracks: [],
  });

  const [albumData, setAlbumData] = useState({
    name: "",
    img: "",
    desc: "",
    tracks: [],
  });

  const handleAlbumInputChange = (e) => {
    const { name, value } = e.target;

    setAlbumData({
      ...albumData,
      [name]: value,
    });
  };

  const handlePlaylistInputChange = (e) => {
    const { name, value } = e.target;

    setPlaylistData({
      ...playlistData,
      [name]: value,
    });
  };

  const handlePlaylistImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPlaylistPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setPlaylistPreview(reader.result);
    }
  };

  const handleGalleryImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setGalleryPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setGalleryPreview(reader.result);
    }
  };

  const handleAlbumImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAlbumPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setAlbumPreview(reader.result);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const auth = useSelector((state) => state?.auth);
  const song = useSelector((state) => state?.song);

  const token = localStorage.getItem("token");

  const handlePostEvent = (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");
    const data = {
      ...formData,
      eventDate: new Date(startDate).getTime(),
    };

    axiosInstance
      .post(`/auth/user/post-event`, data)
      .then((res) => {
        dispatch(getUserByToken(token));
        toast.success("New event posted!", { id: id, duration: 1500 });
        setShowThree(false);
      })
      .catch((err) => {
        toast.error("Unable to post now. Try later.", {
          id: id,
          duration: 1500,
        });
      });
  };

  // useEffect(() => {
  //   setTrackList(song?.userTracks);
  // }, [song]);

  useEffect(() => {
    dispatch(getTracksByUser());
    dispatch(getUserByToken(token));
  }, []);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedProfileImg, setCroppedProfileImg] = useState();
  const [croppedCoverImg, setCroppedCoverImg] = useState();

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    // console.log(avatarImage);
    await cropImageNow(croppedAreaPixels);
  };

  const onCoverCropComplete = async (croppedArea, croppedAreaPixels) => {
    // console.log(avatarImage);
    await cropCoverImageNow(croppedAreaPixels);
  };

  const cropCoverImageNow = (crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = previw;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        // Convert the canvas to a blob
        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            // Create a file from the blob
            const file = new File(
              [blob],
              `${Math.floor(1000000000 + Math.random() * 9000000000)}-${
                coverImage.name
              }`,
              {
                type: coverImage.type,
                lastModified: new Date().getTime(),
              }
            );
            resolve(file);
            setCroppedCoverImg(file);
          },
          coverImage.type,
          1
        );
      };

      image.onerror = (error) => reject(error);
    });
  };

  const cropImageNow = (crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = profilePreview;

      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        // Convert the canvas to a blob

        console.log(avatarImage);

        canvas.toBlob(
          (blob) => {
            if (!blob) {
              reject(new Error("Canvas is empty"));
              return;
            }
            // Create a file from the blob
            const file = new File(
              [blob],
              `${Math.floor(1000000000 + Math.random() * 9000000000)}-${
                avatarImage.name
              }`,
              {
                type: avatarImage.type,
                lastModified: new Date().getTime(),
              }
            );
            resolve(file);
            setCroppedProfileImg(file);
          },
          avatarImage.type,
          1
        );
      };

      image.onerror = (error) => reject(error);
    });
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setPreview(reader.result);
    }
  };

  const handleProfileImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setProfilePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      setProfilePreview(reader.result);
    }
  };

  const generateLinkFromString = (str) => {
    return str?.split("")?.length > 0
      ? `${str?.toLowerCase()?.replace(/\s+/g, "-")}`
      : ""; // Replace spaces (or multiple spaces) with hyphens
  };

  const handleImgUpload = async (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: generateLinkFromString(croppedCoverImg.name),
          fileType: croppedCoverImg.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, croppedCoverImg, {
        headers: {
          "Content-Type": croppedCoverImg.type,
        },
      });

      await axiosInstance.post(`/auth/user/update-cover-img`, {
        url: objectUrl,
      });

      dispatch(getUserByToken(token));
      toast.dismiss(id);
      setPreview("");
      setShow(false);
    } catch (error) {
      console.log(error);

      toast.error("Image upload failed!", { id: id });
    }
  };

  const handleProfileImgUpload = async (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: generateLinkFromString(croppedProfileImg.name),
          fileType: croppedProfileImg.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, croppedProfileImg, {
        headers: {
          "Content-Type": croppedProfileImg.type,
        },
      });

      await axiosInstance.post(`/auth/user/update-profile-img`, {
        url: objectUrl,
      });

      dispatch(getUserByToken(token));
      toast.dismiss(id);
      setShowTwo(false);
      setProfilePreview("");
      setCroppedProfileImg("");
    } catch (error) {
      toast.error("Image upload failed!", { id: id });
    }
  };

  let SocialLinks = [
    {
      name: "Instagram",
      link: user?.social?.ig,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
        </svg>
      ),
    },
    {
      name: "Twitter",
      link: user?.social?.x,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
        </svg>
      ),
    },
    {
      name: "Facebook",
      link: user?.social?.fb,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
        </svg>
      ),
    },
  ];

  let iconBtnList = [
    {
      id: 0,
      name: "Insights",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return navigate("/profile/insights");
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-4"
        >
          <path
            fillRule="evenodd"
            d="M1.75 2a.75.75 0 0 0 0 1.5H2V9a2 2 0 0 0 2 2h.043l-1.004 3.013a.75.75 0 0 0 1.423.474L4.624 14h6.752l.163.487a.75.75 0 1 0 1.422-.474L11.957 11H12a2 2 0 0 0 2-2V3.5h.25a.75.75 0 0 0 0-1.5H1.75Zm8.626 9 .5 1.5H5.124l.5-1.5h4.752ZM5.25 7a.75.75 0 0 0-.75.75v.5a.75.75 0 0 0 1.5 0v-.5A.75.75 0 0 0 5.25 7ZM10 4.75a.75.75 0 0 1 1.5 0v3.5a.75.75 0 0 1-1.5 0v-3.5ZM8 5.5a.75.75 0 0 0-.75.75v2a.75.75 0 0 0 1.5 0v-2A.75.75 0 0 0 8 5.5Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    // {
    //   id: 1,
    //   name: "Follow",
    //   tag: "",
    //   isDisabled: false,
    //   icon: "https://icons.veryicon.com/png/o/miscellaneous/3vjia-icon-line/follow-42.png",
    //   bgColor: "",
    //   handleOnClick: () => {
    //     return null;
    //   },
    //   svg: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       viewBox="0 0 16 16"
    //       fill="currentColor"
    //       className="size-4"
    //     >
    //       <path d="M8.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 13c.552 0 1.01-.452.9-.994a5.002 5.002 0 0 0-9.802 0c-.109.542.35.994.902.994h8ZM12.5 3.5a.75.75 0 0 1 .75.75v1h1a.75.75 0 0 1 0 1.5h-1v1a.75.75 0 0 1-1.5 0v-1h-1a.75.75 0 0 1 0-1.5h1v-1a.75.75 0 0 1 .75-.75Z" />
    //     </svg>
    //   ),
    // },
    {
      id: 2,
      name: "Share",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="size-4"
        >
          <path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
        </svg>
      ),
    },
    {
      id: 3,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
    {
      id: 4,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
    {
      id: 5,
      name: "",
      tag: "",
      isDisabled: false,
      icon: "",
      bgColor: "",
      handleOnClick: () => {
        return null;
      },
    },
  ];

  const handlePlaylistImgUpload = async (file) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: generateLinkFromString(file.name),
          fileType: file.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      setPlaylistData({
        ...playlistData,
        ["img"]: objectUrl,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Image upload failed!");
    }
  };

  const handleGalleryImgUpload = async (file) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: generateLinkFromString(file.name),
          fileType: file.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      setGalleryImg(objectUrl);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Image upload failed!");
    }
  };

  const handleAlbumImgUpload = async (file) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/aws/get-upload-url`, {
        params: {
          filename: generateLinkFromString(file.name),
          fileType: file.type,
        },
      });

      const { url, objectUrl } = response.data;
      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      setAlbumData({
        ...albumData,
        ["img"]: objectUrl,
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Image upload failed!");
    }
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    const searchQuery = value?.toLowerCase();
    setParam(searchQuery);

    // Filter the songs based on the search query
    if (searchQuery?.split("")?.length > 2) {
      axiosInstance
        .get(`/song/public/search?searchTerm=${searchQuery}`)
        .then((res) => {
          setFilteredSongs(res.data.songs);
        });
    } else {
      setFilteredSongs([]);
    }
  };

  const handleSelect = (song) => {
    const valid = selectedSongs.some((s) => s._id === song._id);
    if (valid) {
      toast.success("Already Selected.", { duration: 1500 });
    } else {
      selectedSongs.push(song);
      setSelectedSongs(selectedSongs);
      setPlaylistData({
        ...playlistData,
        ["tracks"]: selectedSongs,
      });
    }
    setSelectedSongs(selectedSongs);
    setParam("");
    setFilteredSongs([]);
  };

  const handleSelectAlbum = (song) => {
    const valid = selectedSongs.some((s) => s._id === song._id);
    if (valid) {
      toast.success("Already Selected.", { duration: 1500 });
    } else {
      selectedSongs.push(song);
      setSelectedSongs(selectedSongs);
      setAlbumData({
        ...albumData,
        ["tracks"]: selectedSongs,
      });
    }
    setSelectedSongs(selectedSongs);
    setParam("");
    setFilteredSongs([]);
  };

  const handleRemoveTrack = (song) => {
    const index = selectedSongs.indexOf(song);
    if (index !== -1) {
      selectedSongs.splice(index, 1);
    }
    setSelectedSongs(selectedSongs);
    setPlaylistData({
      ...playlistData,
      ["tracks"]: selectedSongs,
    });
  };

  const handleRemoveTrackAlbum = (song) => {
    const index = selectedSongs.indexOf(song);
    if (index !== -1) {
      selectedSongs.splice(index, 1);
    }
    setSelectedSongs(selectedSongs);
    setAlbumData({
      ...albumData,
      ["tracks"]: selectedSongs,
    });
  };

  const handleCreatePlaylist = (e) => {
    e.preventDefault();
    const data = {
      name: playlistData.name,
      img: playlistData.img,
      tracks: playlistData?.tracks?.map((t) => {
        return t._id;
      }),
    };
    dispatch(createPlaylist(data));
    setShowFour(false);
  };

  const handleCreateAlbum = (e) => {
    e.preventDefault();
    const data = {
      title: albumData.name,
      img: albumData.img,
      desc: albumData.desc,
      tracks: albumData?.tracks?.map((t) => {
        return t._id;
      }),
    };
    dispatch(createAlbum(data));
    setShowSix(false);
  };

  const handleGalleryPost = () => {
    if (galleryImg) {
      const data = { url: galleryImg, type: "img" };
      dispatch(postGalleryImg(data));
      setGalleryImg("");
      setGalleryPreview("");
      setShowFive("");
    } else {
      toast.error("Select a image first.", { duration: 1500 });
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#070033",
        }}
        className=" min-h-screen "
      >
        {/* HEADER */}

        <div className=" bg-dark sticky top-0 z-50">
          <Navbar />
        </div>

        <div
          style={{
            backgroundImage: auth?.user?.cover
              ? `linear-gradient(to top, rgba(7, 0, 51, 1), rgba(0, 0, 0, 0)) , url(${auth?.user?.cover?.url})`
              : null,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          className="relative bg-bgMoreDark py-4 lg:px-4 md:px-4 sm:px-0 mx-auto w-full lg:h-[420px] flex flex-col justify-between items-center"
        >
          {/* DASH */}
          <div className=" w-full p-4 flex justify-between ">
            <div className="flex lg:gap-4 md:gap-4 sm:gap-2 items-center">
              <div
                class="relative cursor-pointer"
                onClick={() => setShowTwo(true)}
              >
                {/* <div class="absolute inset-0 bg-blue-500"></div> */}

                <div class="absolute inset-0 flex justify-center items-end text-white hover:bg-black hover:bg-opacity-50 rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="lg:size-5 md:size-5 sm:size-4 bg-secondary rounded lg:mb-2 md:mb-2 sm:mb-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z"
                    />
                  </svg>
                </div>

                <ProfilePicture
                  url={auth?.user?.avatar?.url}
                  name={auth?.user?.name}
                  // height={220}
                  // width={220}
                  className="lg:h-[220px] lg:w-[220px] md:h-[190px] md:w-[190px] sm:h-[80px] sm:w-[80px] lg:text-[100px] md:text-[80px] sm:text-[60px] font-bold tracking-tighter"
                />
              </div>

              <div className="text-white">
                <p className="lg:text-3xl md:text-2xl sm:text-md px-1.5 rounded bg-dark w-fit">
                  {auth?.user?.name}
                </p>
                <p className="my-1 lg:text-2xl md:text-xl sm:text-xs px-1.5 rounded bg-dark w-fit">
                  {auth?.user?.email}
                </p>
                <p className="lg:text-lg md:text-md sm:text-xs px-1.5 rounded bg-dark w-fit">
                  {auth?.user?.country}
                </p>
              </div>
            </div>
            <div>
              <button
                onClick={() => setShow(true)}
                className="bg-secondary lg:px-4 md:px-4 sm:px-2 py-1 text-white flex items-center justify-between gap-1 rounded"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="lg:size-5 md:size-4 sm:size-3 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                  />
                </svg>
                <span className=" lg:text-[16px] md:text-[14px] sm:text-[12px] lg:block md:block sm:hidden">
                  Upload Header
                </span>
                <span className=" sm:text-[10px] lg:hidden md:hidden sm:block">
                  Header
                </span>
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center w-full">
            <div className="text-white"></div>
            <div className="mt-2 flex items-center justify-between">
              <div className="mt-4 mr-10 hover:scale-95 transition-all ease-in-out">
                <button
                  // onClick={btn?.handleOnClick}
                  className="flex justify-center rounded-full items-center gap-1 px-5 py-2 text-white"
                  style={{
                    backgroundColor: "#29253E",
                  }}
                >
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-4"
                  >
                    <path d="M8.5 4.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0ZM10 13c.552 0 1.01-.452.9-.994a5.002 5.002 0 0 0-9.802 0c-.109.542.35.994.902.994h8ZM12.5 3.5a.75.75 0 0 1 .75.75v1h1a.75.75 0 0 1 0 1.5h-1v1a.75.75 0 0 1-1.5 0v-1h-1a.75.75 0 0 1 0-1.5h1v-1a.75.75 0 0 1 .75-.75Z" />
                  </svg> */}

                  <p className="lg:text-[14px] md:text-[12px] sm:text-[10px]  text-nowrap">
                    Follow
                  </p>
                </button>
              </div>
              <div className="mt-4 lg:flex md:flex sm:flex  gap-2">
                {SocialLinks?.map((social, index) => {
                  return (
                    <Link
                      key={index}
                      to={social?.link}
                      target="_blank"
                      className="flex flex-col justify-center items-center"
                    >
                      <div className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] ">
                        {social?.icon}
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full bg-[#070033]">
          <div className="text-white pt-8 px-4 mx-auto max-w-[1280px] ">
            {auth?.user?.biography && (
              <div className="mb-4 lg:hidden md:block sm:block">
                <p className="text-[13px] ">{auth?.user?.biography}</p>
              </div>
            )}
            {/* TRACKS HEADER */}

            <div className="flex justify-between items-end">
              <div
                className=" flex gap-4 lg:w-9/12 md:w-full sm:w-full overflow-x-scroll "
                style={{
                  scrollbarWidth: "none", // Firefox
                  msOverflowStyle: "none", // IE and Edge
                }}
              >
                {btnList?.map((btn) => {
                  return (
                    <div
                      key={btn?.id}
                      className={
                        btn?.id === activeBtn
                          ? "flex justify-center items-center gap-1 border-b-2 border-b-primary px-2"
                          : "flex justify-center items-center gap-1 px-2"
                      }
                    >
                      <p
                        onClick={() => setActiveBtn(btn?.id)}
                        className={
                          btn?.id === activeBtn
                            ? "pb-1 whitespace-nowrap leading-none font-semibold text-primary lg:text-[16px] md:text-[14px] sm:text-[10px] cursor-pointer"
                            : "pb-1 whitespace-nowrap lg:text-[16px] md:text-[14px] sm:text-[10px] cursor-pointer"
                        }
                        // disabled={btn?.isDisabled}
                      >
                        {btn?.name}
                      </p>

                      {btn?.tag !== "" && (
                        <p className="lg:text-[11px] md:text-[9px] sm:text-[7px] text-primary p-1 border border-primary">
                          {btn?.tag}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>

              <div className="lg:flex md:hidden sm:hidden flex-col items-center justify-center w-3/12 gap-6 mb-4">
                <div className=" lg:flex md:hidden sm:hidden gap-2 ">
                  {iconBtnList?.map((btn, index) => {
                    if (btn?.name !== "" || btn?.icon !== "")
                      return <IconButton btn={btn} key={index} />;
                  })}
                </div>

                {/* <div className=" lg:flex md:hidden sm:hidden gap-4">
                {SocialLinks?.map((social, index) => {
                  console.log(social);
                  return (
                    <Link
                      key={index}
                      to={social?.link}
                      target="_blank"
                      className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] rounded-full bg-[#222326]"
                    >
                      <div>{social?.icon}</div>
                    </Link>
                  );
                })}
              </div> */}
              </div>
            </div>

            <div className="border-b-[0.25px] border-b-gray-600 " />

            <div className="flex justify-between items-start mt-4">
              {/* MUSIC */}
              {activeBtn === 0 && (
                <>
                  {song?.loading ? (
                    <div className="lg:w-3/4 sm:w-full px-8 py-6 rounded-lg h-[228px] flex justify-center items-center">
                      <img src={dot_gif} className="h-[80px]" />
                    </div>
                  ) : (
                    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                      <p className="lg:text-[28px] md:text-[24px] sm:text-[20px]">
                        Spotlight
                      </p>

                      <div className="mt-4">
                        {song?.userTracks?.length > 0 ? (
                          song?.userTracks
                            ?.slice()
                            .reverse()
                            .map((track, index) => (
                              <MusicDiv
                                pauseAudio={pauseAudio}
                                isPlaying={isPlaying}
                                playAudio={playAudio}
                                showModal={showModal}
                                track={track}
                                setCurrentTrackIndex={setCurrentTrackIndex}
                                setTrackList={setTrackList}
                                index={index}
                                currentTrackIndex={currentTrackIndex}
                                tracks={song?.userTracks}
                                currentTrack={currentTrack}
                              />
                            ))
                        ) : (
                          <div>Nothing Here!</div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}

              {activeBtn === 1 && (
                <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                  <div className="mt-4">
                    {song?.userTracks?.length > 0 ? (
                      song?.userTracks
                        .sort((a, b) => b.count - a.count)
                        ?.slice(0, 10)
                        .map((track, index) => (
                          <MusicDiv
                            pauseAudio={pauseAudio}
                            isPlaying={isPlaying}
                            playAudio={playAudio}
                            showModal={showModal}
                            track={track}
                            setCurrentTrackIndex={setCurrentTrackIndex}
                            setTrackList={setTrackList}
                            index={index}
                            currentTrackIndex={currentTrackIndex}
                            tracks={song?.userTracks}
                            currentTrack={currentTrack}
                          />
                        ))
                    ) : (
                      <div>Nothing Here!</div>
                    )}
                  </div>
                </div>
              )}

              {activeBtn === 2 && (
                <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
                  <div className="mt-4">
                    {song?.userTracks?.length > 0 ? (
                      song?.userTracks
                        ?.slice()
                        .reverse()
                        .map((track, index) => (
                          <MusicDiv
                            pauseAudio={pauseAudio}
                            isPlaying={isPlaying}
                            playAudio={playAudio}
                            showModal={showModal}
                            track={track}
                            setCurrentTrackIndex={setCurrentTrackIndex}
                            setTrackList={setTrackList}
                            index={index}
                            currentTrackIndex={currentTrackIndex}
                            tracks={song?.userTracks}
                            currentTrack={currentTrack}
                          />
                        ))
                    ) : (
                      <div>Nothing Here!</div>
                    )}
                  </div>
                </div>
              )}
              {activeBtn === 3 && <Albums setShowSix={setShowSix} />}
              {activeBtn === 4 && <Playlists setShowFour={setShowFour} />}
              {activeBtn === 5 && <Reposts />}

              {activeBtn === 6 && (
                <EventDetails
                  setShowThree={setShowThree}
                  events={user?.events}
                />
              )}
              {activeBtn === 7 && (
                <Gallery setShowFive={setShowFive} gallery={user?.gallery} />
              )}

              {/* PROFILE DETAILS */}
              <div className="lg:block md:hidden sm:hidden w-3/12 pl-4 border-l-[0.25px] border-l-gray-600 sticky top-24">
                {/* FOLLOWERS  */}
                <div className="flex text-gray-400 items-center">
                  <div className="pr-4">
                    <p className="text-[14px]">Followers</p>
                    <p className="text-[20px]">
                      {auth?.user?.followers?.length || 0}
                    </p>
                  </div>
                  <div className="px-4 border-l-[0.25px] border-l-gray-600 border-r-[0.25px] border-r-gray-600">
                    <p className="text-[14px]">Following</p>
                    <p className="text-[20px]">
                      {auth?.user?.following?.length || 0}
                    </p>
                  </div>
                  <div className="px-4">
                    <p className="text-[14px]">Tracks</p>
                    <p className="text-[20px]">
                      {song?.userTracks.length || 0}
                    </p>
                  </div>
                </div>
                {/* DETAILS ABOUT */}
                {auth?.user?.biography && (
                  <div className="mt-4 ">
                    <p className="text-[13px] ">{auth?.user?.biography}</p>
                  </div>
                )}
                {/* SOCIAL */}{" "}
                {/* <div className="mt-8">
                  <div className="flex text-gray-400 justify-between items-center ">
                    <p className="flex gap-1">
                      <span>Social Links </span>
                    </p>
                  </div>
                  <Divider marginTop={6} />
                  <div className="mt-4 lg:flex md:hidden sm:hidden  gap-6">
                    {SocialLinks?.map((social, index) => {
                      return (
                        <Link
                          key={index}
                          to={social?.link}
                          target="_blank"
                          className="flex flex-col justify-center items-center"
                        >
                          <div className="text-white hover:text-primary hover:scale-95 transition-all ease-in-out flex justify-center items-center h-[48px] w-[48px] rounded-full bg-[#222326]">
                            {social?.icon}
                          </div>
                          <p className="text-center text-[10px] mt-1">
                            {" "}
                            {social?.name}
                          </p>
                        </Link>
                      );
                    })}
                  </div>
                </div> */}
                {/* GO MOBILE */}
                <div className="mt-8">
                  <div className="flex text-gray-400 justify-between items-center ">
                    <p className="flex gap-1">
                      <span>Go mobile </span>
                    </p>
                  </div>
                  <Divider marginTop={6} />
                  <div className="my-4 flex gap-2">
                    <img src={appstore} className="w-full h-[50px]" alt="" />
                    <img src={googleplay} className="w-full h-[50px]" alt="" />
                  </div>

                  <Divider marginTop={12} />

                  <p className="max-w-[200px] text-[12px] text-[#999] mt-6">
                    Legal ⁃ Privacy ⁃ Cookie Policy ⁃ Cookie Manager ⁃ Imprint ⁃
                    Artist Resources ⁃ Blog ⁃ Charts ⁃
                  </p>
                  <p className="max-w-[200px] text-[12px] text-[#999] mt-6">
                    Transparency Reports Language: English (US)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReactModal
        isOpen={show}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            width: "90%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 840,
          },
        }}
        onRequestClose={() => {
          setShow(false);
          setPreview(null);
        }}
      >
        <div className=" px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Upload your profile header
            </p>

            <svg
              onClick={() => {
                setShow(false);
                setPreview(null);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <p className="lg:text-[12px] md:text-[10px] sm:text-[10px] mt-1">
            For best results, upload PNG or JPG images of at least 2480×520
            pixels. 2MB file-size limit. Avoid using text within your header
            image, as it will be cropped on smaller screens.
          </p>
          <form className="mt-4">
            <div className=" relative flex flex-col justify-center items-center">
              {!previw && (
                <label
                  htmlFor="profileImage"
                  className=" lg:h-[320px] w-full border border-gray-400 rounded cursor-pointer flex justify-center items-center "
                >
                  <img
                    src={"https://i.ibb.co/Qc72wGJ/upload.png"}
                    height={80}
                    width={80}
                    alt=""
                    className="h-[80px] w-[80px] "
                  />
                </label>
              )}
              {previw && (
                <>
                  <div className="h-[520px] w-[520px] relative">
                    <Cropper
                      image={previw}
                      crop={crop}
                      zoom={zoom}
                      aspect={62 / 13}
                      onCropChange={setCrop}
                      onCropComplete={onCoverCropComplete}
                      onZoomChange={setZoom}
                      style={{
                        mediaStyle: { height: "auto", width: "auto" },
                        containerStyle: { height: 520 },
                      }}
                    />
                    <div class="absolute h-[520px] ml-2 flex justify-center items-center text-white gap-2">
                      <ProfilePicture
                        url={auth?.user?.avatar?.url}
                        name={auth?.user?.name}
                        height={34}
                        width={34}
                        className="lg:text-[16px] h-[48px] w-[48px] md:text-[16px] sm:text-[16px] font-bold tracking-tighter"
                      />
                      <div className="text-white">
                        <p className="text-[8px] px-1 rounded bg-dark w-fit">
                          {auth?.user?.name}
                        </p>
                        <p className="my-1 text-[8px] px-1 rounded bg-dark w-fit">
                          {auth?.user?.email}
                        </p>
                        <p className="text-[8px] px-1 rounded bg-dark w-fit">
                          {auth?.user?.country}
                        </p>
                      </div>
                    </div>
                  </div>

                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value);
                    }}
                    className="mt-4"
                  />
                </>
              )}
            </div>
            <input
              id="profileImage"
              type="file"
              name="img"
              className="hidden "
              // className="file-input w-full max-w-[480px] hidden"
              onChange={(e) => {
                setCoverImage(e.target.files[0]);
                handleImage(e);
              }}
            />
            <div className="flex justify-between items-center mt-1">
              <div />
              <div className="flex gap-2 ">
                <label
                  htmlFor="profileImage"
                  className="mt-4 cursor-pointer flex justify-center items-center bg-secondary text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]  hover:scale-95 transition-all ease-in-out"
                >
                  Change
                </label>

                <button
                  type="submit"
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                  onClick={handleImgUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showTwo}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            // width: "90%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 520,
          },
        }}
        onRequestClose={() => {
          setShowTwo(false);
          setPreview(null);
        }}
      >
        <div className="mx-auto w-full px-4 py-2 text-white">
          <div className="flex  justify-between items-center w-full ">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Upload your profile picture
            </p>

            <svg
              onClick={() => {
                setShowTwo(false);
                setPreview(null);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <p className="lg:text-[12px] md:text-[10px] sm:text-[10px] mt-1">
            For best results, upload PNG or JPG images of at least 1280×1280
            pixels. 2MB file-size limit.
          </p>
          <form className="mt-4 w-full ">
            <div className="h-[320px] relative flex flex-col justify-center items-center">
              {!profilePreview && (
                <label
                  htmlFor="profileImage"
                  className=" h-[280px] w-full max-w-[280px] border border-gray-400 rounded cursor-pointer flex justify-center items-center "
                >
                  <img
                    src={"https://i.ibb.co/Qc72wGJ/upload.png"}
                    height={80}
                    width={80}
                    alt=""
                    className="h-[80px] w-[80px] "
                  />
                </label>
              )}
              {profilePreview && (
                <>
                  <div className="h-[320px] w-[320px]">
                    <Cropper
                      image={
                        profilePreview ||
                        "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"
                      }
                      crop={crop}
                      zoom={zoom}
                      aspect={1 / 1}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>

                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(e.target.value);
                    }}
                    className=""
                  />
                </>
              )}
            </div>
            <input
              id="profileImage"
              type="file"
              name="img"
              className="hidden "
              // className="file-input w-full max-w-[480px] hidden"
              onChange={(e) => {
                setAvatarImage(e.target.files[0]);
                handleProfileImage(e);
              }}
            />
            <div className="flex justify-between items-center mt-1">
              <div />
              <div className="flex gap-2 ">
                <label
                  htmlFor="profileImage"
                  className="mt-4 cursor-pointer flex justify-center items-center bg-secondary text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px] hover:scale-95 transition-all ease-in-out"
                >
                  Change
                </label>

                <button
                  type="submit"
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                  onClick={handleProfileImgUpload}
                >
                  Upload
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showThree}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            // width: "50%",
            height: "70%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 840,
          },
        }}
        onRequestClose={() => {
          setShowThree(false);
        }}
      >
        <div className="mx-auto w-full px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Post a new event
            </p>

            <svg
              onClick={() => {
                setShowThree(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <form className="mt-4" onSubmit={handlePostEvent}>
            <div className="flex lg:flex-row md:flex-row sm:flex-col justify-around items-center gap-4">
              <TextInputField1
                type={"text"}
                name={"eventName"}
                label={"Event Name"}
                placeholder={"Enter Event Name"}
                value={formData?.eventName}
                onChange={handleInputChange}
              />

              <div className="flex flex-col w-[320px]">
                <div className="text-[13px] font-medium text-gray-100">
                  <p className="font-bold">Event Date</p>
                </div>
                <DatePicker
                  selected={startDate}
                  onSelect={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  placeholderText="Select a date"
                  className="text-white bg-dark border rounded-full px-4 py-2.5 font-semibold text-[15px] w-[320px] mt-2 mb-3"
                />
              </div>
            </div>

            <div className="flex lg:flex-row md:flex-row sm:flex-col md:felx-row justify-around items-center gap-4">
              <TextInputField1
                type={"text"}
                name={"eventCity"}
                label={"Event City"}
                placeholder={"Enter Event City"}
                value={formData?.eventCity}
                onChange={handleInputChange}
              />

              <div className="">
                <SelectInputCustom
                  label={"Event Country"}
                  name={"eventCountry"}
                  onChange={handleInputChange}
                  value={formData?.eventCountry}
                  options={countryNames}
                  width={320}
                />
              </div>
            </div>
            <div className="flex justify-between items-center mt-1">
              <div />
              <div className="flex gap-2 ">
                <button
                  type="submit"
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                >
                  Post
                </button>
              </div>
            </div>
          </form>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showFour}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            // width: "50%",
            height: "80%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 840,
          },
        }}
        onRequestClose={() => {
          setShowFour(false);
        }}
      >
        <div className="mx-auto w-full px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Create a new playlist
            </p>

            <svg
              onClick={() => {
                setShowFour(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="mt-4">
            <div className="flex justify-between items-start overflow-hidden">
              <div className="flex lg:flex-col md:flex-row sm:flex-col justify-center items-center gap-4">
                <TextInputField1
                  type={"text"}
                  name={"name"}
                  label={"Playlist Name"}
                  placeholder={"Enter Playlist Name"}
                  value={playlistData?.name}
                  onChange={handlePlaylistInputChange}
                />
                <div className="w-[320px] lg:hidden md:hidden sm:flex justify-center items-center ">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[232px] w-[232px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    <label htmlFor="Upload" className="cursor-pointer">
                      {!playlistPreview ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-12"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      ) : (
                        <div
                          className={
                            loading
                              ? "h-[100px] w-[100px]"
                              : "h-[232px] w-[232px] border rounded-lg"
                          }
                          style={{
                            backgroundImage: loading
                              ? `url(${dot_gif})`
                              : `url(${playlistPreview})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {!loading && (
                            <div className="h-[232px] w-[232px] hover:bg-customBgColor flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-12"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        id="Upload"
                        className="hidden"
                        onChange={(e) => {
                          handlePlaylistImage(e);
                          handlePlaylistImgUpload(e.target.files[0]);
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>
                <div>
                  {selectedSongs.length > 0 &&
                    selectedSongs.map((song) => (
                      <div
                        key={song.id}
                        className="flex justify-between items-center"
                      >
                        <div className="flex gap-2 my-1 items-center">
                          <img src={song?.img} className="h-[35px]" />
                          <div>
                            <p className="text-[14px]">
                              {song.title?.slice(0, 30)}...
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {song?.user?.name} - {song?.genre}
                            </p>
                          </div>
                        </div>

                        <svg
                          onClick={() => handleRemoveTrack(song)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="size-6 p-1 cursor-pointer hover:scale-90 bg-red-500 rounded-full  transition-all ease-in-out"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    ))}
                </div>
                <TextInputField1
                  type={"text"}
                  name={"param"}
                  label={"Search Tracks"}
                  placeholder={"Enter Atleast 3 Letter"}
                  value={param}
                  onChange={handleSearch}
                />

                <div>
                  <ul>
                    {filteredSongs.map((song) => (
                      <li key={song.id} onClick={() => handleSelect(song)}>
                        <div className="flex gap-2 my-1 items-center hover:bg-[#1F1F1F] rounded-lg px-3 py-1 cursor-pointer">
                          <img src={song?.img} className="h-[35px]" />
                          <div>
                            <p className="text-[14px]">
                              {song.title?.slice(0, 35)}...
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {song?.user?.name} - {song?.genre}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex lg:block md:block sm:hidden justify-center items-center gap-4">
                <div className="w-[320px] flex justify-center items-center">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[232px] w-[232px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    <label htmlFor="Upload" className="cursor-pointer">
                      {!playlistPreview ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-12"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      ) : (
                        <div
                          className={
                            loading
                              ? "h-[100px] w-[100px]"
                              : "h-[232px] w-[232px] border rounded-lg"
                          }
                          style={{
                            backgroundImage: loading
                              ? `url(${dot_gif})`
                              : `url(${playlistPreview})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {!loading && (
                            <div className="h-[232px] w-[232px] hover:bg-customBgColor flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-12"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        id="Upload"
                        className="hidden"
                        onChange={(e) => {
                          handlePlaylistImage(e);
                          handlePlaylistImgUpload(e.target.files[0]);
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center mt-1">
              <div className="flex gap-2 ">
                <button
                  onClick={handleCreatePlaylist}
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showFive}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            // width: "50%",
            height: "60%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 840,
          },
        }}
        onRequestClose={() => {
          setShowFive(false);
        }}
      >
        <div className="mx-auto w-full px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Post a image
            </p>

            <svg
              onClick={() => {
                setShowFive(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="mt-4">
            <div className="flex lg:block md:block sm:block justify-center items-center gap-4">
              <div className="w-[320px] flex justify-center items-center">
                <label htmlFor="Upload" className="cursor-pointer">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[300px] w-[300px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    {!galleryPreview ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-12"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                        />
                      </svg>
                    ) : (
                      <div
                        className={
                          loading
                            ? "h-[100px] w-[100px]"
                            : " h-[300px] w-[300px] border rounded-lg"
                        }
                        style={{
                          backgroundImage: loading
                            ? `url(${dot_gif})`
                            : `url(${galleryPreview})`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                        }}
                      >
                        {!loading && (
                          <div className=" h-[300px] w-[300px] hover:bg-customBgColor flex justify-center items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="size-12"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    )}
                    <input
                      type="file"
                      id="Upload"
                      className="hidden"
                      onChange={(e) => {
                        handleGalleryImage(e);
                        handleGalleryImgUpload(e.target.files[0]);
                      }}
                      accept="image/*"
                    />
                  </div>
                </label>
              </div>
            </div>
            <div className="flex justify-center items-center mt-5">
              <div className="flex gap-2 ">
                <button
                  onClick={handleGalleryPost}
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={showSix}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            // width: "50%",
            height: "80%",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            maxWidth: 1080,
          },
        }}
        onRequestClose={() => {
          setShowSix(false);
        }}
      >
        <div className="mx-auto w-full px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="lg:text-[22px] md:text-[18px] sm:text-[14px]">
              Create a new Album
            </p>

            <svg
              onClick={() => {
                setShowSix(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>

          <div className="mt-4  p-2">
            <div className="mx-auto flex justify-between items-start ">
              <div className="flex lg:flex-col md:flex-row sm:flex-col justify-center items-center gap-4">
                <TextInputField1
                  type={"text"}
                  name={"name"}
                  label={"Album Name"}
                  placeholder={"Enter Album Name"}
                  value={albumData?.name}
                  onChange={handleAlbumInputChange}
                />

                <UploadTextAreaField
                  name={"desc"}
                  label={"Description *"}
                  placeholder={"Describe your album"}
                  value={albumData?.desc}
                  onChange={handleAlbumInputChange}
                  width={320}
                />
                <div className="w-[320px] lg:hidden md:hidden sm:flex justify-center items-center ">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[232px] w-[232px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    <label htmlFor="Upload" className="cursor-pointer">
                      {!albumPreview ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-12"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      ) : (
                        <div
                          className={
                            loading
                              ? "h-[100px] w-[100px]"
                              : "h-[232px] w-[232px] border rounded-lg"
                          }
                          style={{
                            backgroundImage: loading
                              ? `url(${dot_gif})`
                              : `url(${albumPreview})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {!loading && (
                            <div className="h-[232px] w-[232px] hover:bg-customBgColor flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-12"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        id="Upload"
                        className="hidden"
                        onChange={(e) => {
                          handleAlbumImage(e);
                          handleAlbumImgUpload(e.target.files[0]);
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>

                <TextInputField1
                  type={"text"}
                  name={"param"}
                  label={"Search Tracks"}
                  placeholder={"Enter Atleast 3 Letter"}
                  value={param}
                  onChange={handleSearch}
                />

                <div>
                  <ul>
                    {filteredSongs.map((song) => (
                      <li key={song.id} onClick={() => handleSelectAlbum(song)}>
                        <div className="flex gap-2 my-1 items-center hover:bg-[#1F1F1F] rounded-lg px-3 py-1 cursor-pointer">
                          <img src={song?.img} className="h-[35px]" />
                          <div>
                            <p className="text-[14px]">
                              {song.title?.slice(0, 35)}...
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {song?.user?.name} - {song?.genre}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="flex lg:block md:block sm:hidden justify-center items-center gap-4">
                <div className="w-[320px] flex justify-center items-center">
                  <div className=" mt-6 border rounded-lg overflow-hidden h-[232px] w-[232px] flex justify-center items-center cursor-pointer hover:scale-95 transition-all ease-in-out">
                    <label htmlFor="Upload" className="cursor-pointer">
                      {!albumPreview ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-12"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                          />
                        </svg>
                      ) : (
                        <div
                          className={
                            loading
                              ? "h-[100px] w-[100px]"
                              : "h-[232px] w-[232px] border rounded-lg"
                          }
                          style={{
                            backgroundImage: loading
                              ? `url(${dot_gif})`
                              : `url(${albumPreview})`,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        >
                          {!loading && (
                            <div className="h-[232px] w-[232px] hover:bg-customBgColor flex justify-center items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="size-12"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.755 10.059a7.5 7.5 0 0 1 12.548-3.364l1.903 1.903h-3.183a.75.75 0 1 0 0 1.5h4.992a.75.75 0 0 0 .75-.75V4.356a.75.75 0 0 0-1.5 0v3.18l-1.9-1.9A9 9 0 0 0 3.306 9.67a.75.75 0 1 0 1.45.388Zm15.408 3.352a.75.75 0 0 0-.919.53 7.5 7.5 0 0 1-12.548 3.364l-1.902-1.903h3.183a.75.75 0 0 0 0-1.5H2.984a.75.75 0 0 0-.75.75v4.992a.75.75 0 0 0 1.5 0v-3.18l1.9 1.9a9 9 0 0 0 15.059-4.035.75.75 0 0 0-.53-.918Z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      )}
                      <input
                        type="file"
                        id="Upload"
                        className="hidden"
                        onChange={(e) => {
                          handlePlaylistImage(e);
                          handlePlaylistImgUpload(e.target.files[0]);
                        }}
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-between items-center mt-1">
              <div className="flex gap-2 ">
                <button
                  onClick={handleCreateAlbum}
                  className="mt-4  flex justify-center items-center bg-secondarycom text-white px-6 py-2  rounded-full lg:text-[14px] md:text-[12px] sm:text-[10px]   hover:scale-95 transition-all ease-in-out"
                >
                  Create
                </button>
              </div>
              <div>
                {selectedSongs.length > 0 && (
                  <>
                    <div>Selected Tracks</div>
                    {selectedSongs.map((song) => (
                      <div
                        key={song.id}
                        className="flex justify-between items-center"
                      >
                        <div className="flex gap-2 my-1 items-center">
                          <img src={song?.img} className="h-[35px]" />
                          <div>
                            <p className="text-[14px]">
                              {song.title?.slice(0, 30)}...
                            </p>
                            <p className="text-[10px] text-gray-400">
                              {song?.user?.name} - {song?.genre}
                            </p>
                          </div>
                        </div>

                        <svg
                          onClick={() => handleRemoveTrackAlbum(song)}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="white"
                          className="size-6 p-1 cursor-pointer hover:scale-90 bg-red-500 rounded-full  transition-all ease-in-out"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18 18 6M6 6l12 12"
                          />
                        </svg>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

const EventDetails = ({ setShowThree, events }) => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="text-white flex justify-between items-center">
        <div></div>
        <BtnFilled
          icon={null}
          text={"Post Event"}
          onClick={() => setShowThree(true)}
          width={180}
          // textColor={}
          bgColor={"#6813F2"}
          // style={}
          isDisabled={false}
        />
      </div>
      <div className="my-4">
        {events?.length > 0 ? (
          <>
            <div className="w-full rounded-xl my-1 p-2.5 font-bold text-[18px] flex justify-around items-center">
              <p>Event Date</p>
              <p>Event Name</p>
              <p>Location</p>
            </div>
            {events
              ?.slice()
              .reverse()
              .map((e, i) => {
                const D = new Date(e.date);
                return (
                  <div className="w-full bg-[#2F1A66] rounded-xl my-1 p-2.5 border-gray-600 flex justify-around items-center hover:scale-95 transition-all ease-in-out">
                    <p>{D.toDateString()}</p>
                    <p>{e.name}</p>
                    <p>
                      {e.city}, {e.country}
                    </p>
                  </div>
                );
              })}
          </>
        ) : (
          <div className="flex justify-center items-center text-[18px]">
            No event posted yet.
          </div>
        )}
      </div>
    </div>
  );
};

const Reposts = () => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-center items-center text-[18px]">
        No reposts yet.
      </div>
    </div>
  );
};

const GenreCard = ({ item, displayType, minwidth }) => {
  const navigate = useNavigate();
  return (
    <div
      style={{ width: minwidth }}
      className="cursor-pointer"
      onClick={() =>
        navigate(`/playlist/${item?.name}`, { state: { genreDetails: item } })
      }
    >
      <div
        style={{
          backgroundImage: `url(${item?.img})`,

          backgroundPosition: "center",
          backgroundSize: "cover",
          height: minwidth,
          width: minwidth,
        }}
        height={minwidth}
        width={minwidth}
        alt=""
        className={displayType === "r" ? "rounded-full" : "rounded-xl"}
      ></div>

      <h2 className="lg:text-[16px] md:text-[14px] sm:text-[12px] mt-1 ">
        {item?.name}
      </h2>
    </div>
  );
};

const Playlists = ({ setShowFour }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [likedSong, setLikedSong] = useState([]);

  useEffect(() => {
    dispatch(getPlaylist());
  }, []);

  useEffect(() => {
    axiosInstance.get(`/song/get-liked-song`).then((res) => {
      setLikedSong(res.data.tracks);
    });
  }, []);

  const playlist = useSelector((state) => state.playlist);

  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-between items-center my-2">
        <div></div>
        <BtnFilled
          icon={null}
          text={"New Playlist"}
          onClick={() => setShowFour(true)}
          width={180}
          // textColor={}
          bgColor={"#6813F2"}
          // style={}
          isDisabled={false}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        <div
          // style={{ width: minwidth }}
          className="cursor-pointer lg:w-[148px] md:w-[136px] sm:w-[120px]"
          onClick={() =>
            navigate(`/playlist/liked`, {
              state: { tracks: likedSong },
            })
          }
        >
          <div
            style={{
              background: "linear-gradient(135deg, #A956E2, #D383E3, #FFC1E3)",

              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            alt=""
            className={
              "rounded-xl lg:h-[148px] lg:w-[148px] lg:h-[136px] lg:w-[136px] sm:h-[120px] sm:w-[120px] flex justify-center items-center"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="lg:size-15 md:size-13 sm:size-12"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
              />
            </svg>
          </div>

          <h2 className="lg:text-[15px] md:text-[13px] sm:text-[11px] mt-1 ">
            Liked Tracks
          </h2>
          <p className="lg:text-[13px] md:text-[11px] sm:text-[9px] text-gray-400">
            {likedSong.length} Tracks
          </p>
        </div>
        {playlist?.playlists?.length > 0 &&
          playlist?.playlists?.map((item, i) => (
            <div
              // style={{ width: minwidth }}
              className="cursor-pointer lg:w-[148px] md:w-[136px] sm:w-[120px]"
              onClick={() => navigate(`/playlist/${item?._id}`)}
            >
              <div
                style={{
                  backgroundImage: item?.img
                    ? `url(${encodeURI(item?.img)})`
                    : `url(${encodeURI(item?.tracks[0]?.img)})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // height: minwidth,
                  // width: minwidth,
                }}
                // height={minwidth}
                // width={minwidth}
                alt=""
                className={
                  "rounded-xl lg:h-[148px] lg:w-[148px] lg:h-[136px] lg:w-[136px] sm:h-[120px] sm:w-[120px]"
                }
              ></div>

              <h2 className="lg:text-[15px] md:text-[13px] sm:text-[11px] mt-1 ">
                {item?.name}
              </h2>
              <p className="lg:text-[13px] md:text-[11px] sm:text-[9px] text-gray-400">
                {item.tracks?.length} Tracks
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

const Albums = ({ setShowSix }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAlbumByUser());
  }, []);

  const album = useSelector((state) => state.album);
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-between items-center my-2">
        <div></div>
        <BtnFilled
          icon={null}
          text={"Create Album"}
          onClick={() => setShowSix(true)}
          width={200}
          // textColor={}
          bgColor={"#6813F2"}
          // style={}
          isDisabled={false}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        {album?.albums?.length > 0 ? (
          album?.albums?.map((item, i) => (
            <div
              className="cursor-pointer lg:w-[148px] md:w-[136px] sm:w-[120px]"
              onClick={() => navigate(`/album/${item?._id}`)}
            >
              <div
                style={{
                  backgroundImage: item?.img
                    ? `url(${item?.img})`
                    : `url(${item?.tracks[0]?.img})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  // height: minwidth,
                  // width: minwidth,
                }}
                // height={minwidth}
                // width={minwidth}
                alt=""
                className={
                  "rounded-xl lg:h-[148px] lg:w-[148px] lg:h-[136px] lg:w-[136px] sm:h-[120px] sm:w-[120px]"
                }
              ></div>

              <h2 className="lg:text-[15px] md:text-[13px] sm:text-[11px] mt-1 ">
                {item?.title}
              </h2>
              <p className="lg:text-[13px] md:text-[11px] sm:text-[9px] text-gray-400">
                {item.tracks?.length} Tracks
              </p>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center text-[18px]">
            No albums yet.
          </div>
        )}
      </div>
    </div>
  );
};

const Gallery = ({ setShowFive, gallery }) => {
  return (
    <div className="lg:w-9/12 md:w-full sm:w-full pr-4">
      <div className="flex justify-between items-center my-2">
        <div></div>
        <BtnFilled
          icon={null}
          text={"Post Image"}
          onClick={() => setShowFive(true)}
          width={180}
          // textColor={}
          bgColor={"#6813F2"}
          // style={}
          isDisabled={false}
        />
      </div>
      <div className="flex flex-wrap gap-4">
        {gallery?.length > 0 ? (
          gallery
            ?.slice()
            ?.reverse()
            .map((item, i) => (
              <div className="cursor-pointer lg:w-[148px] md:w-[136px] sm:w-[120px]">
                <div
                  style={{
                    backgroundImage: `url(${item?.url})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                  alt=""
                  className={
                    "rounded-xl lg:h-[148px] lg:w-[148px] lg:h-[136px] lg:w-[136px] sm:h-[120px] sm:w-[120px]"
                  }
                ></div>
              </div>
            ))
        ) : (
          <div className="flex justify-center items-center text-[18px]">
            No posts yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
