import React from "react";
import { Navigate } from "react-router-dom";

const ArtistRoute = ({ children }) => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  return token && user?.role === "artist" ? children : <Navigate to="/home" />;
};

export default ArtistRoute;
