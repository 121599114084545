import React, { useEffect, useState } from "react";
import { icon_logo, logopngw } from "../assets/logo";
import { BtnFilled, BtnOutline } from "../component/button";
import {
  PasswordInputField1,
  TextInputField1,
} from "../component/form/InputField";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { getUserByToken, login } from "../redux/actions";

const Divider = () => {
  return <div className="border-t-[0.45px] border-t-gray-600 w-8/12 my-11" />;
};

const Login = () => {
  const [searchParams] = useSearchParams();

  const [status, setStatus] = useState();
  const [code, setCode] = useState();
  const [type, setType] = useState();
  const [token, setToken] = useState("");

  useEffect(() => {
    setStatus(searchParams.get("status"));
    setType(searchParams.get("type"));
    setCode(searchParams.get("code"));
    setToken(searchParams.get("token"));
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const [isEmailWrote, setEmailWrote] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
    if (name === "password") setPasswordWrote((prevState) => true);
  };

  const handleLogin = () => {
    if (!isEmailWrote || !isPasswordWrote) {
      toast.error("Email and password can't be empty.", { duration: 1500 });
    } else {
      dispatch(login(formData));
    }
  };

  useEffect(() => {
    if (status === "failed" && code === "404") {
      toast.error("User not found. Signup instead.", { duration: 1500 });
    } else if (status === "failed" && code === "403") {
      toast.error("Request Forbidden.", { duration: 1500 });
    } else if (status === "success" && code === "200") {
      dispatch(getUserByToken(token));
    }
  }, [status]);

  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      setCode(undefined);
      setType(undefined);
      setStatus(undefined);
      setStatus(token);
      window.location.replace("/login");
    }
  }, [performance]);

  const handleGoogle = () => {
    window.location.replace(
      "https://core.upvibez.com/api/v1/auth/user/google/login"
    );
  };

  if (auth.authenticate) {
    navigate("/profile");
  }

  let btns = [
    {
      text: "Continue with Google",
      icon: "https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png",
      handleOnClick: () => {
        handleGoogle();
      },
    },
    {
      text: "Continue with Facebook",
      icon: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png",
    },
    {
      text: "Continue with Apple",
      icon: "https://static-00.iconduck.com/assets.00/apple-icon-1662x2048-d80o29ez.png",
    },
  ];

  return (
    <div className="bg-globalBg   text-white">
      <div className=" bg-dark flex flex-col justify-between items-center ">
        <div className="flex flex-col  items-center py-6 m-8 rounded-xl  min-w-6/12 px-10">
          <img
            src={icon_logo}
            width={70}
            height={70}
            className="hover:cursor-pointer"
            onClick={() => navigate("/")}
          />
          <h1 className="text-[32px] font-semibold mt-4  text-center">
            Log in to Upvibez
          </h1>

          <div className="flex flex-col gap-4 mt-8">
            {btns?.map((btn, ind) => {
              return (
                <BtnOutline
                  icon={btn?.icon}
                  text={btn?.text}
                  onClick={btn.handleOnClick}
                  width={320}
                />
              );
            })}
          </div>

          <Divider />

          <TextInputField1
            type={"email"}
            name={"email"}
            label={"Email or username"}
            placeholder={"Email or username"}
            value={formData?.email}
            onChange={handleInputChange}
            warning={isEmailWrote && formData?.email === ""}
            warningText={"Please enter your Upvibez username or email address."}
          />

          <PasswordInputField1
            // type={"password"}
            name={"password"}
            label={"Password"}
            placeholder={"Password"}
            value={formData?.password}
            onChange={handleInputChange}
            warning={isPasswordWrote && formData?.password === ""}
            warningText={"Please enter your password."}
            style={{ marginBottom: 12 }}
          />

          <BtnFilled
            text={"Login"}
            onClick={() => handleLogin()}
            width={320}
            bgColor={"#6813f2"}
            textColor={"white"}
          />

          <Link to="/forget-password" className="text-[16px] underline mt-6">
            Forgot your password?
          </Link>

          <Divider />

          <div className="text-[16px] flex justify-center gap-1 pb-12">
            <p className="text-gray-500">Don't have an account?</p>{" "}
            <Link to="/signup" className="underline">
              Sign up for Upvibez
            </Link>
          </div>
        </div>
      </div>
      <div className="mt-4 bg-dark py-9 flex justify-center items-center w-full px-4">
        <a href="" className="text-[11px] text-gray-500">
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </a>
      </div>
    </div>
  );
};

export default Login;
