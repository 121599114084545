import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  DateOfBirth,
  PolicyCheckoutNoBackground,
  SelectInputCustom,
  TextInputField1,
  TextInputFieldWithSecondaryLabel,
  UploadTextAreaField,
} from "../../component/form/InputField";
import { BtnFilled } from "../../component/button";
import { countryNames } from "../../assets/data/dataArray";
import axiosInstance from "../../redux/helpers/axios";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken } from "../../redux/actions";
import toast from "react-hot-toast";
import { dot_gif } from "../../assets/images";

const genderOptions = ["Man", "Woman", "Prefer not to say"];

const EditProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = localStorage.getItem("token");
  const auth = useSelector((state) => state.auth);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    dispatch(getUserByToken(token));
  }, []);

  const [isNameWrote, setNameWrote] = useState(false);
  const [isEmailWrote, setEmailWrote] = useState(false);
  const [isDayWrote, setDayWrote] = useState(false);
  const [isMonthWrote, setMonthWrote] = useState(false);
  const [isYearWrote, setYearWrote] = useState(false);
  const [isCountryWrote, setCountryWrote] = useState(false);

  const dob = auth?.user?.dob?.split("/");

  const [formData, setFormData] = useState({
    email: auth?.user?.email,
    name: auth?.user?.name,
    day: dob?.length > 0 ? dob[0] : "",
    month: dob?.length > 0 ? dob[1] : "",
    year: dob?.length > 0 ? dob[2] : "",
    gender: auth?.user?.gender,
    country: auth?.user?.country,
    policy_1: auth?.user?.consent?.data,
    biography: auth?.user?.biography === null ? "" : auth?.user?.biography,
    fb: auth?.user?.social?.fb === undefined ? "" : auth?.user?.social?.fb,
    ig: auth?.user?.social?.ig === undefined ? "" : auth?.user?.social?.ig,
    x: auth?.user?.social?.x === undefined ? "" : auth?.user?.social?.x,
    yt: auth?.user?.social?.yt,
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
    if (name === "day") setDayWrote((prevState) => true);
    if (name === "month") setMonthWrote((prevState) => true);
    if (name === "year") setYearWrote((prevState) => true);
    if (name === "name") setNameWrote((prevState) => true);
    if (name === "country") setCountryWrote((prevState) => true);
  };

  console.log(formData);

  function ensureHttps(url) {
    if (!url.startsWith("https://")) {
      return `https://${url}`;
    }
    return url;
  }

  useEffect(() => {
    setFormData({
      ...formData,
      ["ig"]: ensureHttps(formData.ig),
    });
  }, [formData.ig]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["fb"]: ensureHttps(formData.fb),
    });
  }, [formData.fb]);

  useEffect(() => {
    setFormData({
      ...formData,
      ["x"]: ensureHttps(formData.x),
    });
  }, [formData.x]);

  const handleSaveProfile = async () => {
    const id = toast.loading("Loading...");
    try {
      await axiosInstance.post(`/auth/user/update-profile-info`, formData);
      dispatch(getUserByToken(token));
      toast.dismiss(id);
    } catch (error) {
      toast.error("Failed.", { duration: 1500, id: id });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function removeHttps(url) {
    if (url.startsWith("https://")) {
      return url.slice(8); // Remove the first 8 characters (i.e., 'https://')
    }
    return url;
  }

  return (
    <div className="">
      {auth?.loading ? (
        <div className="flex justify-center items-center mt-4">
          <img src={dot_gif} className="h-[100px]" />
        </div>
      ) : (
        <>
          <button
            onClick={() => {
              navigate(-1);
            }}
          >
            <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="size-7"
              >
                <path
                  fillRule="evenodd"
                  d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </button>
          <div className="text-white mt-8">
            <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
              Edit Profile
            </h1>
            <div>
              <TextInputField1
                type={"email"}
                name={"email"}
                label={"Email address"}
                placeholder={"name@domain.com"}
                value={formData?.email}
                onChange={handleInputChange}
                warning={isEmailWrote && formData?.email === ""}
                warningText={
                  "This email is invalid. Make sure it's written like example@email.com"
                }
                style={{ marginBottom: 12, width: "100%" }}
                disabled={true}
              />

              <TextInputFieldWithSecondaryLabel
                // type={"password"}
                name={"name"}
                label={"Name"}
                placeholder={""}
                value={formData?.name}
                onChange={handleInputChange}
                warning={isNameWrote && formData?.name === ""}
                warningText={"Enter a name for your profile."}
                style={{ marginBottom: 12, width: "100%" }}
              />
              <SelectInputCustom
                label={"Gender"}
                name={"gender"}
                onChange={handleInputChange}
                value={formData?.gender}
                options={genderOptions}
                style={{ marginBottom: 12, width: "100%" }}
              />

              <DateOfBirth
                name_dd={"day"}
                name_mm={"month"}
                name_yy={"year"}
                label={"Date of birth"}
                placeholder_dd={"dd"}
                placeholder_mm={"month"}
                placeholder_yy={"yyyy"}
                value_dd={formData?.day}
                value_mm={formData?.month}
                value_yy={formData?.year}
                onChange={handleInputChange}
                warning_dd={isDayWrote && formData?.day === ""}
                warning_mm={isMonthWrote && formData?.month === ""}
                warning_yy={isYearWrote && formData?.year === ""}
                warningText_dd={"Please enter your date of birth."}
                warningText_mm={"Select your birth month."}
                warningText_yy={
                  "Please enter the year of your birth date using four digits (e.g., 1990)."
                }
                style={{ marginBottom: 12, width: "100%" }}
              />

              <SelectInputCustom
                label={"Country or Region"}
                name={"country"}
                onChange={handleInputChange}
                value={formData?.country}
                options={countryNames}
                style={{ marginBottom: 12, width: "100%" }}
              />

              <UploadTextAreaField
                name={"biography"}
                label={"Biography"}
                placeholder={"Write about yourself..."}
                value={formData?.biography}
                onChange={handleInputChange}
                // boxHeight={"200px"}
                // width={698}
                style={{ marginBottom: 12, width: "100%" }}
              />

              <div className="flex justify-between items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
                  </svg>
                </div>
                <TextInputFieldWithSecondaryLabel
                  // type={"password"}
                  name={"fb"}
                  label={"Facebook"}
                  placeholder={""}
                  value={removeHttps(formData?.fb)}
                  onChange={handleInputChange}
                  style={{ marginBottom: 12, width: "95%" }}
                />
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
                  </svg>
                </div>
                <TextInputFieldWithSecondaryLabel
                  // type={"password"}
                  name={"ig"}
                  label={"Instagram"}
                  placeholder={""}
                  value={removeHttps(formData?.ig)}
                  onChange={handleInputChange}
                  style={{ marginBottom: 12, width: "95%" }}
                />
              </div>

              <div className="flex justify-between items-center">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </div>
                <TextInputFieldWithSecondaryLabel
                  // type={"password"}
                  name={"x"}
                  label={"Twitter"}
                  placeholder={""}
                  value={removeHttps(formData?.x)}
                  onChange={handleInputChange}
                  style={{ marginBottom: 12, width: "95%" }}
                />
              </div>

              <PolicyCheckoutNoBackground
                // type={"password"}
                name={"policy_1"}
                label={
                  "Share my registration data with Upvibez's content providers for marketing purposes."
                }
                value={formData?.policy_1}
                onChange={handleInputChange}
                style={{ marginBottom: 12, width: "100%", fontSize: "16px" }}
                textColor={""}
              />

              <div className="border-t-[0.45px] border-t-gray-600 w-full "></div>

              <div className="flex justify-between items-center gap-2">
                <div></div>
                <div className="flex items-center gap-2 mt-6">
                  <BtnFilled
                    text={"Cancel"}
                    onClick={() => navigate(-1)}
                    width={110}
                    bgColor={""}
                    textColor={"white"}
                  />
                  <BtnFilled
                    text={"Save Profile"}
                    onClick={handleSaveProfile}
                    width={220}
                    bgColor={"#6813f2"}
                    textColor={"white"}
                  />
                </div>
              </div>

              <div className="mt-12 mb-6 ">
                <p className="text-[11px] w-full text-center text-gray-500">
                  This site is protected by reCAPTCHA and the Google Privacy
                  Policy and Terms of Service apply.
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditProfile;
