import { combineReducers, legacy_createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import authReducer from "./reducers/auth.reducer";
import subReducer from "./reducers/sub.reducer";
import songReducer from "./reducers/song.reducer";
import playlistReducer from "./reducers/playlist.reducer";
import albumReducer from "./reducers/album.reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  sub: subReducer,
  song: songReducer,
  playlist: playlistReducer,
  album: albumReducer,
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
