import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { logopngw } from "../../assets/logo";
import { useSelector, useDispatch } from "react-redux";
import { signout } from "../../redux/actions";
import ProfilePicture from "./ProfilePicture";
import MobileDrawer from "../../layout/MobileDrawer";
import { Drawerbar } from "../../assets/SvgIcons";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(signout());
  };

  let user = {
    name: "M",
    img: "",
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = (event) => {
    if (!event.target.closest(".dropdown")) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => {
      document.removeEventListener("click", closeDropdown);
    };
  }, []);

  return (
    <>
      <MobileDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <div className="bg-dark shadow-2xl sticky top-0 z-40 py-4 lg:px-6 md:px-4 sm:px-2 mx-auto  flex justify-between items-center">
        <div className="flex pl-4 gap-4">
          <button
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            className="lg:hidden md:hidden sm:block text-white"
          >
            {Drawerbar}
          </button>

          <img
            src={logopngw}
            height={37}
            width={120}
            alt=""
            onClick={() => navigate("/")}
            className="cursor-pointer"
          />
        </div>

        {auth.authenticate ? (
          <div className="flex lg:gap-5 md:gap-4 sm:gap-1.5 items-center">
            {auth?.user?.role === "artist" && (
              <button
                onClick={() => navigate("/upload")}
                className="bg-white button-wave-animation text-black lg:px-4 md:px-4 sm:px-1.5 py-1.5 flex gap-1 items-center rounded-full pr-4"
              >
                <p className="font-semibold text-[13px] lg:block md:block sm:hidden">
                  Upload
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 16.5V9.75m0 0 3 3m-3-3-3 3M6.75 19.5a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
                  />
                </svg>
              </button>
            )}
            <button className="hover:bg-secondary  rounded-full hover:text-white lg:block md:block sm:hidden hover:scale-110 p-0 transition-all ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="white"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
                />
              </svg>
            </button>

            <div className="dropdown">
              <button
                onClick={toggleDropdown}
                className="btn btn-ghost btn-circle hover:bg-secondary rounded-full w-[40px] h-[40px] flex justify-center items-center  avatar hover:scale-110 transition-all ease-in-out"
              >
                <ProfilePicture
                  url={auth?.user?.avatar?.url}
                  name={auth?.user?.name}
                  height={34}
                  width={34}
                  className="lg:text-[16px] h-[34px] w-[34px] md:text-[16px] sm:text-[16px] font-bold tracking-tighter"
                />
              </button>
              {isDropdownOpen && (
                <ul className="dropdown-menu text-[14px] text-normal py-2">
                  <Link to={"/account"} className="text-[white]">
                    <li className="">
                      <Link
                        className="flex justify-between items-center hover:bg-secondary mx-1 px-3 py-2 rounded-[4px]"
                        to={"/"}
                      >
                        Home
                      </Link>
                    </li>
                    <li className="flex justify-between items-center hover:bg-secondary rounded-[4px] mx-1 px-3 py-2">
                      Account
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="white"
                        className="size-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                        />
                      </svg>
                    </li>
                  </Link>
                  <li className="">
                    <Link
                      className="flex justify-between items-center hover:bg-secondary mx-1 px-3 py-2 rounded-[4px]"
                      to={"/profile"}
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="flex justify-between items-center hover:bg-secondary mx-1 px-3 py-2">
                    <a className="justify-between">Upgrade to Premium</a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="white"
                      className="size-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                      />
                    </svg>
                  </li>
                  <li className="flex justify-between items-center hover:bg-secondary mx-1 px-3 py-2">
                    <a>Setting</a>
                  </li>
                  <div className="border-t-[0.5px] border-t-slate-400 my-1" />
                  <li className="">
                    <button
                      onClick={() => handleLogout()}
                      className="text-white flex justify-between items-center hover:bg-secondary mx-1 px-3 py-2 w-[216px] rounded-[4px]"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        ) : (
          <div className="flex   items-center">
            <Link
              to={"/signup"}
              className="lg:block md:block sm:hidden text-white px-5 py-1.5 font-semibold rounded-full text-[14px]"
            >
              {" "}
              Sign up
            </Link>

            <Link
              to={"/login"}
              className="bg-white text-black px-5 py-1.5 font-semibold rounded-full text-[14px]"
            >
              {" "}
              Log in
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
