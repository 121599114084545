import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";

/**
 * @author
 * @function AddCard
 **/

export const AddCard = ({ clientSecret, user, setShow }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    setIsProcessing(true);
    const id = toast.loading("Loading...");

    const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.name,
          email: user.email,
        },
      },
    });

    if (setupIntent && setupIntent.status === "succeeded") {
      toast.success("Card added successfully", { id: id, duration: 1500 });
      setShow(false);
    } else if (error) {
      toast.error(`${error.message}`, { id: id, duration: 1500 });
      setShow(false);
    }
    setIsProcessing(false);
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#ffffff", // Text color
        fontSize: "16px", // Font size
        iconColor: "#ffffff", // Color of the icon (e.g., the card icon)
        "::placeholder": {
          color: "#aab7c4", // Placeholder text color
        },
      },
      invalid: {
        color: "#fa755a", // Color when there is an error (invalid input)
        iconColor: "#fa755a", // Color of the icon in case of error
      },
    },
    hidePostalCode: true, // Optionally hide the postal code input field
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="mt-6 px-4 py-5 border-[0.45px] border-gray-600 rounded-lg">
        <CardElement id="card-element" options={cardElementOptions} />
      </div>

      <div className="">
        <button
          type=""
          style={{
            backgroundColor: "#6813f2",
          }}
          className="mt-4 py-3 border-1 flex justify-center items-center w-full rounded-xl text-[16px] font-semibold text-[white] tracking-tighter"
        >
          <span id="button-text">
            {isProcessing ? "Processing ... " : "Save Card"}
          </span>
        </button>
      </div>
      <button
        disabled={isProcessing || !stripe || !elements}
        id="submit"
      ></button>
      {/* Show any error or success messages */}
      {message && (
        <div className="prim_text_lg font-sora primary_text_color">
          {message}
        </div>
      )}
    </form>
  );
};
