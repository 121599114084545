import React from "react";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";

const TermsAndConditions = () => {
  return (
    <div className="bg-globalBg  min-h-screen text-white">
      <div className="bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="bg-darkk py-4 px-4 mt-4 mx-auto max-w-[720px]">
        <h1 className="text-[18px] mb-6 text-center">
          TERMS AND CONDITIONS OF USE
        </h1>

        <h2 className="text-[16px] mb-2">1. Introduction</h2>
        <p className="text-[13px] mb-4">
          Welcome to the Upvibez Platform, operated by UPVIBEZ PORTAL
          (“Upvibez”), a company registered under number 1404379, with its
          address at RMT RESIDENCE, Dubai, United Arab Emirates, and email
          contact inquires@upvibez.com.
        </p>
        <p className="text-[13px] mb-4">
          By accessing or using the Upvibez Platform, you agree to comply with
          and be bound by these Terms and Conditions of Use ("Terms"). If you do
          not agree with these Terms, please do not register or use the Upvibez
          Platform.
        </p>
        <p className="text-[13px] mb-4">
          We are a streaming platform focused on the upload of DJ sets, which
          will be cataloged according to their nature, mood, genre, musical
          type, beats per minute, and other relevant characteristics.
        </p>

        <h2 className="text-[16px] mb-2">2. Acceptance of Terms</h2>
        <p className="text-[13px] mb-4">
          By registering for, accessing, or using the Upvibez Platform, you
          acknowledge that you have read, understood, and agree to be bound by
          these Terms. You also agree to comply with all applicable laws and
          regulations. If you do not agree to these Terms, you are not
          authorized to use the Upvibez Platform.
        </p>

        <h2 className="text-[16px] mb-2">
          3. Purpose and Intellectual Property Respect
        </h2>
        <p className="text-[13px] mb-4">
          Upvibez is committed to respecting the intellectual property rights of
          third parties and aims to spread music worldwide. The Upvibez Platform
          allows users to create profiles, upload photos and descriptions, and
          share music and DJ sets.
        </p>
        <p className="text-[13px] mb-4">
          It is important for you to understand from the outset that a single
          piece of music can involve the rights of multiple parties, including
          authors, songwriters, composers, performers, music producers, record
          labels, distributors, and publishers. The rights to the composition
          and any potential recording may be managed by different individuals
          and/or companies.
        </p>
        <p className="text-[13px] mb-4">
          You acknowledge your awareness of this circumstance and commit to
          always seeking the necessary authorizations.
        </p>

        <h2 className="text-[16px] mb-2">4. User Content</h2>
        <p className="text-[13px] mb-4">
          4.1 Definition and Responsibility: Users may post, upload, or
          otherwise contribute content to the Upvibez Platform ("User Content").
          User Content includes all information, materials, DJs sets, music, and
          other content that is added, created, uploaded, submitted,
          distributed, or posted to the Upvibez Platform by users.
        </p>
        <p className="text-[13px] mb-4">
          4.2 Responsibility for User Content: You and your Subscribing Entity
          are solely responsible for all User Content that you post.
        </p>
        <p className="text-[13px] mb-4">
          4.3 User Promises: You promise that, with respect to any User Content
          you post on Upvibez, (1) you own or have the right to post such User
          Content; and (2) such User Content, or its use by Upvibez pursuant to
          the license granted below, does not: (i) violate these Terms,
          including the Upvibez User Guidelines, applicable law, or the
          intellectual property or other rights of any third party; or (ii)
          imply any affiliation with or endorsement of you or your User Content
          by Upvibez or any artist, band, label, or other individual or entity
          without the prior express written consent from Upvibez or such
          individual or entity.
        </p>
        <p className="text-[13px] mb-4">
          4.4 Public Accessibility: In posting or sharing User Content or other
          information on the Upvibez Platform, please keep in mind that content
          and other information will be publicly accessible and may be used and
          re-shared by others on the Upvibez Platform and across the web. Please
          use caution in posting or sharing on the Upvibez Platform, and be
          mindful of your account settings. Upvibez is not responsible for what
          you or others post or share on the Upvibez Platform.
        </p>
        <p className="text-[13px] mb-4">
          4.5 Monitoring User Content: Upvibez may, but has no obligation to,
          monitor or review User Content. Upvibez reserves the right to remove
          or disable access to any User Content for any or no reason. Upvibez
          may take these actions without prior notification to you.
        </p>

        <h2 className="text-[16px] mb-2">5. Licenses That You Grant to Us</h2>
        <p className="text-[13px] mb-4">
          5.1 User Content: Upvibez does not claim any ownership rights in the
          User Content you post to Upvibez. After posting User Content to the
          Service, as between you and Upvibez, you will continue to retain any
          rights you may have in your User Content, including any intellectual
          property rights or other proprietary rights associated with your User
          Content, subject to the license you grant to Upvibez in the next
          paragraph of this section.
        </p>
        <p className="text-[13px] mb-4">
          5.2 License Grant: By posting User Content to the Service, you, on
          behalf of your Subscribing Entity, grant Upvibez a non-exclusive,
          transferable, sub-licensable, royalty-free, fully paid, worldwide
          license to reproduce, make available, perform and display, translate,
          modify, create derivative works from, distribute, and otherwise use
          such User Content through any medium, whether alone or in combination
          with other content or materials, in any manner and by any means,
          method or technology, whether now known or hereafter created, in
          connection with Upvibez and Upvibez's music streaming service, the
          promotion and marketing of Upvibez and Upvibez's music streaming
          service.
        </p>
        <p className="text-[13px] mb-4">
          If you do not want Upvibez to use your User Content for these
          purposes, you should not post User Content to Upvibez. To the extent
          you provide User Content that contains your name, likeness or
          photograph, you further grant us the non-exclusive, fully paid,
          worldwide right to use such name, likeness, and photograph on
          Upvibez's music streaming service and in our marketing communications
          to advertise, market and promote the availability of your User Content
          on Upvibez's music streaming service.
        </p>

        <h2 className="text-[16px] mb-2">6. Clearance and Authorization</h2>
        <p className="text-[13px] mb-4">
          6.1. When uploading music or DJ sets, you are obligated to have
          obtained all necessary clearances and authorizations. Upvibez reserves
          the right to request, and you agree to provide, documentation proving
          such authorizations upon request.
        </p>
        <p className="text-[13px] mb-4">
          6.2. If we suspect a violation of third-party rights, we will contact
          you to address the issue. If we do not receive a response within a
          reasonable timeframe, we will proceed to remove the content.
        </p>

        <h2 className="text-[16px] mb-2">7. Declaration and Warranty</h2>
        <p className="text-[13px] mb-4">
          You declare and warrant that all information, content, images, or
          logos uploaded to Upvibez are fully owned or properly licensed by you,
          and that you have the full right to use, display, and exploit such
          materials without infringing on any third-party rights. You agree to
          indemnify and hold Upvibez harmless from any claims, damages, or
          expenses arising from any breach of this declaration and warranty.
        </p>

        {/* Add remaining sections similarly */}
      </div>

      <Footer />
    </div>
  );
};

export default TermsAndConditions;
