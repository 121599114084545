import React, { useEffect, useRef, useState } from "react";
import MusicSuggestion from "../component/home/MusicSuggestion";
import { linkArray } from "../assets/data/homeFooterLink";
import { useDispatch, useSelector } from "react-redux";
import { getFeaturedArtists, getFeaturedTracks } from "../redux/actions";
import { dot_gif } from "../assets/images";
import { genreData } from "../assets/data/genre";
import LeftNav from "../component/home/LeftNav";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";

const Home = () => {
  const dispatch = useDispatch();

  const [deviceScreenType, setDeviceScreenType] = useState("none");
  const [fullPageWidth, setFullPageWidth] = useState(-1);
  const [navRatio, setNavRatio] = useState(0.2);

  useEffect(() => {
    let fullPageWidth = window.innerWidth;
    setFullPageWidth(fullPageWidth);

    const handleResize = () => {
      if (window) {
        const fullPageWidth = window?.innerWidth;

        if (fullPageWidth < 768) {
          setDeviceScreenType("sm");
          setNavRatio(0);
        } else if (fullPageWidth < 1024) {
          setDeviceScreenType("md");
          setNavRatio(0.08);
        } else {
          setDeviceScreenType("lg");
          setNavRatio(0.2);
        }
      }
    };

    // Call the function on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);

  useEffect(() => {
    dispatch(getFeaturedArtists());
    dispatch(getFeaturedTracks(20));
  }, []);

  const song = useSelector((state) => state.song);

  return (
    <div className="bg-dark  min-h-screen ">
      {fullPageWidth !== -1 && deviceScreenType !== "none" ? (
        <div className="min-h-screen relative ">
          {/* NAV */}
          <Navbar />

          <div className="flex gap-0 mt-2 relative">
            {/* FIRST BOX */}
            <div
              style={{ width: window.innerWidth * navRatio }}
              className="lg:block md:block sm:hidden relative px-2"
            >
              <LeftNav
                deviceScreenType={deviceScreenType}
                linkArray={linkArray}
              />
            </div>
            <div className="border-2 border-globalBg lg:block md:block sm:hidden"></div>
            {/* SECOND BOX */}
            <div
              style={{ width: window.innerWidth * (1 - navRatio) }}
              className="rounded-lg "
            >
              {song?.loading ? (
                <div className="flex justify-center items-center min-h-screen ">
                  <img src={dot_gif} className="h-[100px]" />
                </div>
              ) : (
                <>
                  <MusicSuggestion
                    featuredArtists={song?.featuredArtists}
                    featuredTracks={song?.featuredTracks}
                    genres={genreData}
                    deviceScreenType={deviceScreenType}
                  />
                  <div className="bg-globalBg py-[2px] rounded-lg">
                    <Footer />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
