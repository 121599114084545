import React from "react";
import Navbar from "../component/shared/Navbar";
import Footer from "../component/shared/Footer";

const EULA = () => {
  return (
    <div className="bg-globalBg  min-h-screen text-white">
      <div className="bg-dark sticky top-0 z-50">
        <Navbar />
      </div>

      <div className="bg-darkk py-4 px-4 mt-4 mx-auto max-w-[720px]">
        <h1 className="text-[18px] mb-6 text-center">
          END USER LICENSE AGREEMENT (EULA) FOR UPVIBEZ PORTAL
        </h1>

        <p className="text-[13px] mb-4">
          This End User License Agreement ("Agreement") is a binding agreement
          between you ("End User" or "you") and UPVIBEZ PORTAL (“Upvibez”), a
          company registered under number 1404379, with its address at RMT
          RESIDENCE, Dubai, United Arab Emirates, and email contact
          inquires@upvibez.com (“Upvibez”, "Company," "we," or "us"). By
          downloading, installing, or using the Upvibez music streaming
          application ("App"), you agree to comply with and be bound by this
          Agreement. If you do not agree to the terms of this Agreement, do not
          download, install, or use the App.
        </p>

        <h2 className="text-[16px] mb-2">1. LICENSE GRANT</h2>
        <p className="text-[13px] mb-4">
          Upvibez grants you a limited, non-exclusive, non-transferable,
          revocable license to download, install, and use the App for personal,
          non-commercial use in accordance with the terms of this Agreement.
          This license may be brokered, sublicensed, and/or shared with app
          stores such as iOS, Android, or similar platforms as necessary to
          enable the distribution and operation of the application on
          third-party platforms.
        </p>

        <h2 className="text-[16px] mb-2">2. USER-GENERATED CONTENT</h2>
        <p className="text-[13px] mb-4">
          You may upload DJ sets and other music content ("Content") to the App,
          provided you have obtained the necessary rights, permissions, or
          licenses for such uploads. By uploading Content, you represent and
          warrant that:
        </p>
        <ul className="list-disc pl-6 text-[13px] mb-4">
          <li>
            You either own the copyright or have obtained the appropriate
            permissions from the copyright holder(s) to upload the Content.
          </li>
          <li>
            The Content does not infringe upon the intellectual property rights
            of any third party.
          </li>
          <li>
            The Content complies with all applicable laws and regulations.
          </li>
        </ul>

        <h2 className="text-[16px] mb-2">3. PAYMENTS</h2>
        <p className="text-[13px] mb-4">
          Upvibez utilizes Stripe for payment processing. You agree to provide
          valid credit card information to enable ongoing billing for access to
          the App. Payments are charged monthly or annually, as selected by you
          during the account setup process. Your account will be fully activated
          and access to all App features will be granted once the payment is
          confirmed and up to date. If your payment method fails, your account
          may be suspended until billing is resolved.
        </p>

        <h2 className="text-[16px] mb-2">4. INTELLECTUAL PROPERTY</h2>
        <p className="text-[13px] mb-4">
          All rights, title, and interest in the App, including but not limited
          to copyrights, trademarks, and trade secrets, are owned by Upvibez.
          This Agreement does not grant you any ownership rights in the App.
        </p>

        <h2 className="text-[16px] mb-2">5. RESTRICTIONS</h2>
        <p className="text-[13px] mb-4">You agree not to:</p>
        <ul className="list-disc pl-6 text-[13px] mb-4">
          <li>Modify, adapt, or create derivative works based on the App.</li>
          <li>Rent, lease, loan, sell, or redistribute the App.</li>
          <li>Reverse engineer, decompile, or disassemble the App.</li>
          <li>
            Upload Content that violates the intellectual property rights of
            others.
          </li>
          <li>Use the App to engage in illegal activities.</li>
          <li>Share your User account with others.</li>
        </ul>

        <h2 className="text-[16px] mb-2">6. TERMINATION</h2>
        <p className="text-[13px] mb-4">
          Upvibez reserves the right to terminate this Agreement and your access
          to the App at any time if you violate any term of this Agreement or
          for any other reason. Upon termination, your license to use the App
          will cease, and you must delete or remove the App from your devices.
        </p>

        <h2 className="text-[16px] mb-2">7. COPYRIGHT VIOLATIONS</h2>
        <p className="text-[13px] mb-4">
          If any Content you upload is found to infringe the rights of others,
          Upvibez reserves the right to remove such Content and terminate your
          account. You will be liable for any damages resulting from the
          infringement, including legal fees and costs incurred by Upvibez in
          defending against such claims.
        </p>

        <h2 className="text-[16px] mb-2">8. DISCLAIMER OF WARRANTIES</h2>
        <p className="text-[13px] mb-4">
          The App is provided "as is" and without warranties of any kind,
          whether express, implied, or statutory. Upvibez disclaims all
          warranties, including but not limited to implied warranties of
          merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>

        <h2 className="text-[16px] mb-2">9. LIMITATION OF LIABILITY</h2>
        <p className="text-[13px] mb-4">
          In no event will Upvibez or its affiliates be liable for any indirect,
          incidental, special, or consequential damages, or loss of profits,
          arising from or related to your use of the App or the Content
          uploaded, even if Upvibez has been advised of the possibility of such
          damages.
        </p>

        <h2 className="text-[16px] mb-2">10. GOVERNING LAW</h2>
        <p className="text-[13px] mb-4">
          This Agreement will be governed by and construed in accordance with
          the laws of Dubai, without regard to its conflict of law principles.
        </p>

        <h2 className="text-[16px] mb-2">11. CHANGES TO THIS AGREEMENT</h2>
        <p className="text-[13px] mb-4">
          Upvibez reserves the right to modify this Agreement at any time. Any
          changes will be effective upon posting the revised Agreement within
          the App or on the Upvibez website. Your continued use of the App after
          such posting constitutes your acceptance of the revised Agreement.
        </p>

        <h2 className="text-[16px] mb-2">12. CONTACT INFORMATION</h2>
        <p className="text-[13px] mb-4">
          For any questions about this Agreement, please contact Upvibez at
          inquires@upvibez.com.
        </p>

        {/* Add remaining sections similarly */}
      </div>

      <Footer />
    </div>
  );
};

export default EULA;
