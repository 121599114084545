import { AudioProvider } from "./audio";
import { ModalProvider } from "./modal";

const ContextProvider = ({ children }) => {
  return (
    <ModalProvider>
      <AudioProvider>{children}</AudioProvider>
    </ModalProvider>
  );
};

export default ContextProvider;
