export const BtnOutline = ({
  icon,
  text,
  onClick,
  width,
  textColor,
  bgColor,
  style,
  isDisabled,
  disabledColor,
}) => {
  return (
    <button
      onClick={onClick}
      className="px-10 py-2.5 text-white border border-gray-500 rounded-full hover:scale-95 ease-in-out"
      style={{ width: `${width || 320}px` }}
    >
      {icon ? (
        <div className="flex justify-center items-center gap-5 w-full">
          <img src={icon} height={24} width={24} className="w-[24px]" />
          <p className="font-semibold text-center text-[15px] w-full">{text}</p>
        </div>
      ) : (
        <p>{text}</p>
      )}
    </button>
  );
};
export const BtnFilled = ({
  icon,
  text,
  onClick,
  width,
  textColor,
  bgColor,
  style,
  isDisabled,
  disabledColor,
}) => {
  // console.log(isDisabled);

  return (
    <button
      onClick={onClick}
      className={
        isDisabled
          ? "px-10 py-2.5  rounded-full "
          : "px-10 py-2.5  rounded-full hover:scale-95 ease-in-out"
      }
      style={{
        width: `${width || 320}px`,
        color: textColor,
        backgroundColor: isDisabled
          ? disabledColor
            ? disabledColor
            : "#D3D3D3"
          : bgColor,
        cursor: isDisabled ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={isDisabled || false}
    >
      {icon ? (
        <div className="flex justify-center items-center gap-5 w-full">
          <img src={icon} height={24} width={24} className="w-[24px]" />
          <p className="font-semibold text-center w-full">{text}</p>
        </div>
      ) : (
        <p>{text}</p>
      )}
    </button>
  );
};

export const DivBtnFilled = ({
  icon,
  text,
  onClick,
  width,
  textColor,
  bgColor,
  style,
  isDisabled,
  disabledColor,
}) => {
  // console.log(isDisabled);

  return (
    <div
      onClick={onClick}
      className={
        isDisabled
          ? "px-10 py-2.5  rounded-full hover:cursor-pointer"
          : "px-10 py-2.5  rounded-full hover:cursor-pointer hover:scale-95 ease-in-out"
      }
      style={{
        width: `${width || 320}px`,
        color: textColor,
        backgroundColor: isDisabled
          ? disabledColor
            ? disabledColor
            : "#D3D3D3"
          : bgColor,
        cursor: isDisabled ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={isDisabled || false}
    >
      {icon ? (
        <div className="flex justify-center items-center gap-5 w-full">
          <img src={icon} height={24} width={24} className="w-[24px]" />
          <p className="font-semibold text-center w-full">{text}</p>
        </div>
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
};

export const BtnOutline1 = ({
  icon,
  text,
  onClick,
  width,
  textColor,
  bgColor,
  style,
  isDisabled,
  disabledColor,
}) => {
  // console.log(isDisabled);

  return (
    <button
      onClick={onClick}
      className={
        isDisabled
          ? "px-10 py-2.5 border border-gray-500  rounded-full "
          : "px-10 py-2.5 border border-gray-500  rounded-full hover:scale-95 ease-in-out"
      }
      style={{
        width: `${width || 320}px`,
        color: textColor,

        cursor: isDisabled ? "not-allowed" : "pointer",
        ...style,
      }}
      disabled={isDisabled || false}
    >
      {icon ? (
        <div className="flex justify-center items-center gap-5 w-full">
          <img src={icon} height={24} width={24} className="w-[24px]" />
          <p className="font-semibold text-center w-full">{text}</p>
        </div>
      ) : (
        <p>{text}</p>
      )}
    </button>
  );
};

export const IconButton = ({ btn }) => {
  return (
    <button
      onClick={btn?.handleOnClick}
      className="flex justify-center rounded items-center gap-1 px-2 border"
      style={{
        backgroundColor: btn?.bgColor !== "" ? btn?.bgColor : "#00FFFFFF.",
      }}
    >
      {btn?.svg
        ? btn?.svg
        : btn?.icon !== "" && (
            <img src={btn?.icon} height={14} width={14} alt="" />
          )}
      {btn?.name !== "" && (
        <p className="lg:text-[14px] md:text-[12px] sm:text-[10px] text-nowrap">
          {btn?.name}
        </p>
      )}
    </button>
  );
};
export const SmallIconButton = ({ btn }) => {
  return (
    <button
      onClick={btn?.handleOnClick}
      className="flex justify-center items-center gap-1 px-2 border rounded"
      style={{
        backgroundColor: btn?.bgColor !== "" ? btn?.bgColor : "#00FFFFFF.",
      }}
    >
      {btn?.svg
        ? btn?.svg
        : btn?.icon !== "" && (
            <img src={btn?.icon} height={10} width={10} alt="" />
          )}

      {btn?.name !== "" && <p className="text-[11.5px]">{btn?.name}</p>}
    </button>
  );
};

export const IconCount = ({ icon, count, fontSize, fontColor }) => {
  return (
    <div
      style={{ fontSize: fontSize, color: fontColor }}
      className="flex items-center gap-1"
    >
      {icon}
      <p>{count}</p>
    </div>
  );
};
