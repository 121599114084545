import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BtnOutline1 } from "../../component/button";

const SignOutEverywhere = () => {
  const navigate = useNavigate();
  const handleSignOut = () => {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white mt-8">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Sign out everywhere
        </h1>
        <div>
          <h2 className="text-[24px]   font-bold tracking-tighter ">
            Sign out everywhere
          </h2>
          <p className="text-[14px] mt-6">
            his logs you out of Upvibez everywhere you’re logged in, including
            the mobile, tablet, web player and desktop apps. This doesn't
            include partner devices (e.g. speakers, games consoles, and TVs), so
            for those go to your apps page and choose REMOVE ACCESS.
          </p>

          <p className="bg-bgLightDark p-4 rounded-lg my-6">
            Note: It can take up to 1 hour for sign out to take effect on the
            web player.
          </p>

          <BtnOutline1
            text={"Sign out everywhere"}
            onClick={handleSignOut}
            width={240}
            bgColor={"#6813f2"}
            textColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default SignOutEverywhere;
