import React, { useRef, useState } from "react";
import { useAudio } from "../context/audio";
import { useModal } from "../context/modal";
import { next, pause, play, previous, wave, wave_2 } from "../../assets/images";
import { useNavigate } from "react-router-dom";

/**
 * @author
 * @function AudioPlayer
 **/

export const AudioPlayer = (props) => {
  const {
    isPlaying,
    isLoaded,
    playAudio,
    pauseAudio,
    skipToNext,
    skipToPrevious,
    trackList,
    currentTrackIndex,
    audioRef,
    currentTime,
    duration,
    progress,
    seekTo,
  } = useAudio();

  const navigate = useNavigate();

  const progressBarRef = useRef();
  const progressBarRefMobile = useRef();

  const handleProgressClick = (e) => {
    const progressBar = progressBarRef.current;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / progressBar.offsetWidth) * duration;

    seekTo(newTime);
  };

  const handleProgressClickMobile = (e) => {
    const progressBar = progressBarRefMobile.current;
    const rect = progressBar.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const newTime = (clickX / progressBar.offsetWidth) * duration;

    seekTo(newTime);
  };

  const { isVisible, showModal, hideModal } = useModal();

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const circleSize = 12;
  // console.log(progress);

  const handleIsVisible = () => {
    if (isPlaying) {
      pauseAudio();
      hideModal();
    } else {
      hideModal();
    }
  };

  if (!isVisible) return null;
  return (
    <div className="z-40 sticky  w-full bottom-0 bg-dark">
      {/* FOR LARGE AND MEDIUM DEVICES */}
      <div className="mx-auto max-w-[1280px] w-full p-2 lg:flex md:hidden sm:hidden justify-between  items-center gap-4 min-h-[82px] ">
        {/* TRACK INFO, IMAGE/NAME/ARTIST-NAME */}
        <div className="flex gap-3 text-white w-3/12">
          <img
            src={trackList[currentTrackIndex]?.img}
            className="h-[50px] w-[50px] rounded"
          />
          <div>
            <p
              className="lg:text-sm md:text-sm sm:text-xs cursor-pointer"
              onClick={() =>
                navigate(`/track/${trackList[currentTrackIndex]?.permalink}`)
              }
            >
              {trackList[currentTrackIndex]?.title.slice(0, 35)}...
            </p>
            <p
              className="lg:text-xs md:text-xs sm:text-[10px] text-gray-400 cursor-pointer"
              onClick={() =>
                navigate(
                  `/public/artist/${trackList[currentTrackIndex]?.user?._id}`
                )
              }
            >
              {trackList[currentTrackIndex]?.user?.name}
            </p>
          </div>
        </div>

        {/* BASIC CONTROLLERS */}
        <div className="w-6/12">
          {/* PREVIOUS PLAY/PAUSE NEXT BTN */}
          <div className="w-full flex justify-center items-center gap-5 my-[10px]">
            {/* PREV */}
            <div
              onClick={() => skipToPrevious()}
              className="hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
            >
              <img src={previous} className="h-[15px] w-[15px]" />
            </div>

            {/* PLAY/PAUSE */}
            <div
              onClick={() => handlePlayPause()}
              className="bg-white  h-[30px] w-[30px] flex justify-center items-center hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
            >
              {!isPlaying ? (
                <img
                  src={play}
                  style={{ marginLeft: 3 }}
                  className="h-[15px] w-[15px]"
                />
              ) : (
                <img src={pause} className="h-[15px] w-[15px]" />
              )}
            </div>

            {/* NEXT */}
            <div
              onClick={() => skipToNext()}
              className="hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
            >
              <img src={next} className="h-[15px] w-[15px]" />
            </div>
          </div>

          {/* PROGRESS BAR WITH PLAY TIME AND TRACK DURATION */}
          <div className="w-full flex justify-center items-center gap-3">
            <div className="w-full flex gap-3">
              {/* PLAY TIME */}
              <span
                className="w-1/12"
                style={{ color: "#D3D3D3", fontSize: 15 }}
              >
                {formatTime(currentTime)}
              </span>
              {/* PROGRESSBAR */}
              <div
                ref={progressBarRef}
                onClick={handleProgressClick}
                style={{
                  maxWidth: "470px",
                  marginTop: 10,
                  height: 3,
                  backgroundColor: "white",
                  borderRadius: 5,
                  position: "relative",
                }}
                className="w-10/12"
              >
                <div
                  style={{
                    // width: `${progress * 100}%`,
                    width: `${(currentTime / duration) * 100}%`,
                    height: "100%",
                    backgroundColor: "#2F5C62",
                    borderRadius: 5,
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    top: -5,
                    width: circleSize,
                    height: circleSize,
                    borderRadius: "50%",
                    backgroundColor: "white",
                    // left: `${progress * 100}%`,
                    left: `${(currentTime / duration) * 100}%`,
                    marginLeft: -circleSize / 2,
                  }}
                />
              </div>
              {/* TRACK LENGTH */}
              <span
                className="w-1/12"
                style={{ color: "#D3D3D3", fontSize: 15 }}
              >
                {formatTime(duration)}
              </span>
            </div>
          </div>
        </div>

        {/* REPEAT OR CLOSE TRACK BTN */}
        <div className="flex justify-end gap-5 w-3/12">
          {/* <img src={wave_2} className="h-[60px] rounded" /> */}

          {/* <div className="hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3"
              />
            </svg>
          </div> */}
          <div
            className="hover:scale-90 hover:bg-red-500 rounded-full cursor-pointer transition-all ease-in-out"
            onClick={handleIsVisible}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      </div>

      {/* FOR SMALL AND MOBILE DEVICES */}
      <div className="mx-auto flex max-w-[1280px] w-full px-4 lg:hidden md:flex sm:flex-col  min-h-[84px] ">
        {/* TRACK INFO, IMAGE/NAME/ARTIST-NAME */}
        <div className=" flex justify-between  items-center gap-4">
          <div className="flex gap-3 items-center text-white w-8/12 mt-1">
            <img
              src={trackList[currentTrackIndex]?.img}
              className="h-[50px] w-[50px] rounded "
            />
            <div>
              <p className="lg:text-sm md:text-sm sm:text-xs">
                {trackList[currentTrackIndex]?.title.slice(0, 35)}...
              </p>
              <p className="lg:text-xs md:text-xs sm:text-[10px] text-gray-400">
                {trackList[currentTrackIndex]?.user?.name}
              </p>
            </div>
          </div>

          {/* BASIC CONTROLLERS */}
          <div className="w-4/12">
            {/* PREVIOUS PLAY/PAUSE NEXT BTN */}
            <div className="w-full flex justify-center items-center gap-5 my-[10px]">
              {/* PREV */}
              <div
                onClick={() => skipToPrevious()}
                className="hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
              >
                <img src={previous} className="h-[15px] w-[15px]" />
              </div>

              {/* PLAY/PAUSE */}
              <div
                onClick={() => handlePlayPause()}
                className="bg-white  h-[30px] w-[30px] flex justify-center items-center hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
              >
                {!isPlaying ? (
                  <img
                    src={play}
                    style={{ marginLeft: 3 }}
                    className="h-[15px] w-[15px]"
                  />
                ) : (
                  <img src={pause} className="h-[15px] w-[15px]" />
                )}
              </div>

              {/* NEXT */}
              <div
                onClick={() => skipToNext()}
                className="hover:scale-90 rounded-full cursor-pointer transition-all ease-in-out"
              >
                <img src={next} className="h-[15px] w-[15px]" />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col md:flex-row justify-center items-center px-2 mt-2">
          <div className="w-full flex items-center justify-center gap-3">
            {/* PLAY TIME */}
            <span
              className="w-1/12 text-xs md:text-sm lg:text-base"
              style={{ color: "#D3D3D3" }}
            >
              {formatTime(currentTime)}
            </span>

            {/* PROGRESS BAR */}
            <div
              ref={progressBarRefMobile}
              onClick={handleProgressClickMobile}
              style={{
                maxWidth: "470px",
                // marginTop: 10,
                height: 3,
                backgroundColor: "white",
                borderRadius: 5,
                position: "relative",
              }}
              className="w-9/12 sm:w-10/12"
            >
              <div
                style={{
                  width: `${(currentTime / duration) * 100}%`,
                  height: "100%",
                  backgroundColor: "#2F5C62",
                  borderRadius: 5,
                }}
              />

              <div
                style={{
                  position: "absolute",
                  top: -5,
                  width: circleSize,
                  height: circleSize,
                  borderRadius: "50%",
                  backgroundColor: "white",
                  left: `${(currentTime / duration) * 100}%`,
                  marginLeft: -circleSize / 2,
                }}
              />
            </div>

            {/* TRACK LENGTH */}
            <span
              className="w-1/12 text-xs md:text-sm lg:text-base"
              style={{ color: "#D3D3D3" }}
            >
              {formatTime(duration)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
