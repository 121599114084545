import React from "react";
import Navbar from "../../component/shared/Navbar";
import { icon_logo } from "../../assets/logo";
import { aexcard, jcbcard, mastercard, visacard } from "../../assets/images";

let plans = [
  {
    planName: "Mini",
    price: "BDT 45 for 1 week",
    features: [
      "1 mobile-only Premium account",
      "Offline listening of up to 30 songs on 1 device",
      "One-time payment",
      "Basic audio quality",
    ],
    notes: "Terms apply.",
    backgroundColor: "#CFF56A",
    btnName: "Get Premium Mini",
  },
  {
    planName: "Individual",
    price: "BDT 199 for 2 months",
    priceAfter: "BDT 199 / month after",
    features: [
      "1 Premium account",
      "Cancel anytime",
      "Subscribe or one-time payment",
    ],
    notes:
      "Offer only available if you haven't tried Premium before. Terms apply.",
    backgroundColor: "#FFC862",
    btnName: "Get Premium individual",
  },
  {
    planName: "Student",
    price: "Free for 1 month",
    priceAfter: "BDT 99 / month after",
    features: [
      "1 verified Premium account",
      "Discount for eligible students",
      "Cancel anytime",
    ],
    notes:
      "Offer available only to students at an accredited higher education institution and if you haven't tried Premium before. Terms apply.",
    backgroundColor: "#FFD2D7",
    btnName: "Try Free For 1 Month",
  },
  {
    planName: "Duo",
    price: "BDT 260 / month",
    features: [
      "2 Premium accounts",
      "Cancel anytime",
      "Subscribe or one-time payment",
    ],
    notes: "For couples who reside at the same address. Terms apply.",
    backgroundColor: "#C4B1D4",
    btnName: "Get Premium Duo",
  },
  {
    planName: "Family",
    price: "BDT 319 / month",
    features: [
      "Up to 6 Premium accounts",
      "Control content marked as explicit",
      "Cancel anytime",
      "Subscribe or one-time payment",
    ],
    notes:
      "For up to 6 family members residing at the same address. Terms apply.",
    backgroundColor: "#A5BBD1",
    btnName: "Get Premium Family",
  },
];
let allPlanIncludes = [
  "Ad-free music listening",
  "Download to listen offline",
  "Play songs in any order",
  "High audio quality",
  "Listen with friends in real time",
  "Organize listening queue",
];

let paymentIcons = [visacard, mastercard, aexcard, jcbcard];

const SubscriptionPlans = () => {
  return (
    <div className="min-h-screen">
      <div className=" bg-dark sticky top-0 z-50">
        <Navbar />
      </div>
      <div className="max-w-[1280px] mx-auto lg:px-0 sm:px-6 text-white my-16">
        <div className="flex flex-col justify-center items-center">
          <h1 className="lg:text-[36px] sm:text-[28px] text-center font-bold tracking-tighter">
            Affordable plans for any situation
          </h1>
          <p className="lg:text-[15px] text-[12px] max-w-[720px] text-center">
            Choose a Premium plan and listen to ad-free music without limits on
            your phone, speaker, and other devices. Pay in various ways. Cancel
            anytime.
          </p>

          <div className="flex flex-wrap  max-w-[720px] mt-12 gap-2">
            {paymentIcons?.map((card, index) => (
              <img
                key={index}
                src={card}
                height={64}
                width={64}
                alt=""
                className=" bg-white px-2 rounded"
              />
            ))}
          </div>

          <div className="flex lg:flex-row md:flex-row sm:flex-col items-center gap-10 mt-12">
            <p className="lg:text-[28px] sm:text-[24px] font-bold tracking-tighter">
              All Premium plans include
            </p>
            <ul>
              {allPlanIncludes?.map((plan, index) => (
                <li
                  key={index}
                  className="flex gap-2 lg:text-[14px] sm:text-[12px] items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    fill="currentColor"
                    className="size-3.5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14Zm3.844-8.791a.75.75 0 0 0-1.188-.918l-3.7 4.79-1.649-1.833a.75.75 0 1 0-1.114 1.004l2.25 2.5a.75.75 0 0 0 1.15-.043l4.25-5.5Z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {plan}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div
          style={{ alignItems: "stretch" }}
          className="flex flex-wrap justify-center mt-20 gap-6"
        >
          {plans?.map((subPlan, index) => {
            return (
              <div
                key={index}
                className="min-h-[480px]  flex flex-col justify-between rounded-[8px] w-[352px] bg-[#1F1F1F]"
              >
                {/* TITLE AND DESCRIPTION OF CARD */}

                <div>
                  {subPlan?.price && subPlan?.priceAfter && (
                    <div
                      className="rounded-tl-[8px] rounded-br-[8px] w-[188px] py-2 flex justify-center items-center"
                      style={{ backgroundColor: subPlan?.backgroundColor }}
                    >
                      <p className="text-[13px] text-black font-bold tracking-tighter">
                        {subPlan?.price}
                      </p>
                    </div>
                  )}
                  <div className="px-4 pt-4 ">
                    <div className="flex gap-0 items-center">
                      <img
                        src={icon_logo}
                        height={20}
                        width={20}
                        alt=""
                        className="mb-[-3px]"
                      />
                      <p className="text-[16px]  tracking-tighter">Premium</p>
                    </div>

                    <h1
                      style={{ color: subPlan?.backgroundColor }}
                      className="text-[36px] font-bold tracking-tighter"
                    >
                      {subPlan?.planName}
                    </h1>
                    <p className="text-[16px] font-bold ">{subPlan?.price}</p>
                    {subPlan?.priceAfter && (
                      <p className="text-[14px] text-gray-500 font-bold ">
                        {subPlan?.priceAfter}
                      </p>
                    )}

                    <div className="border-t border-t-gray-600 pl-4 mt-8 mb-8">
                      <ul className="list-disc mt-6">
                        {subPlan?.features?.map((feature, index) => (
                          <li key={index} className="text-[14px]">
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* END OF CARD */}
                <div className="px-4 pb-4 min-h-[150px]">
                  <button
                    style={{ backgroundColor: subPlan?.backgroundColor }}
                    className="py-3 hover:scale-95 transition-all ease-in-out w-full rounded-full text-black font-bold tracking-tighter text-[16px] flex justify-center items-center"
                  >
                    {subPlan?.btnName}
                  </button>
                  <div className="my-6">
                    <p className="text-[11px] text-gray-500 text-center">
                      {subPlan?.notes}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
