import React, { useEffect, useState } from "react";
import { icon_logo, logopngw } from "../assets/logo";
import { BtnFilled, BtnOutline } from "../component/button";
import {
  DateOfBirth,
  PasswordInputFieldWithCondotion,
  PolicyCheckout,
  RadioInputFieldWithSecondaryLabel,
  TextInputField1,
  TextInputFieldWithSecondaryLabel,
} from "../component/form/InputField";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserByToken, signUp } from "../redux/actions";
import toast from "react-hot-toast";
import axiosInstance from "../redux/helpers/axios";

const genderOptions = [
  {
    id: "Man",
    label: "Man",
    value: "Man",
    isDisabled: false,
  },
  {
    id: "Woman",
    label: "Woman",
    value: "Woman",
    isDisabled: false,
  },
  {
    id: "Prefer not to say",
    label: "Prefer not to say",
    value: "Prefer not to say",
    isDisabled: false,
  },
];

let userTypeOptions = [
  {
    id: "User",
    label: "User",
    value: "user",
    msg: "Enjoy streaming music, follow your favorite artists, and create personal playlists.",
    isDisabled: true,
  },
  {
    id: "Artist",
    label: "Artist",
    value: "artist",
    msg: "Share your music with the world, grow your audience, and track your streams.",
    isDisabled: false,
  },
  {
    id: "Business",
    label: "Business",
    value: "business",
    msg: "Promote your brand, curate music for your business, and engage with the community.",
    isDisabled: true,
  },
];

const Divider = () => {
  return <div className="border-t-[0.45px] border-t-gray-600 w-[320px] my-8" />;
};

const DividerWithText = ({ text }) => {
  return (
    <div className="flex justify-center items-center w-[320px] my-8">
      <div className="border-t-[0.45px] border-t-gray-600 w-full "></div>
      <p className="text-[14px] px-2">{text}</p>
      <div className="border-t-[0.45px] border-t-gray-600 w-full "></div>
    </div>
  );
};

const Signup = () => {
  const [searchParams] = useSearchParams();

  const [status, setStatus] = useState();
  const [code, setCode] = useState();
  const [type, setType] = useState();
  const [token, setToken] = useState("");
  const [referralCode, setReferralCode] = useState("");

  useEffect(() => {
    setStatus(searchParams.get("status"));
    setType(searchParams.get("type"));
    setCode(searchParams.get("code"));
    setToken(searchParams.get("token"));
    setReferralCode(searchParams.get("referralCode"));
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [conOne, setConeOne] = useState(false);
  const [conTwo, setConTwo] = useState(false);
  const [conThree, setConThree] = useState(false);
  const [stageNo, setStageNo] = useState(0);
  const [isEmailWrote, setEmailWrote] = useState(false);
  const [isNameWrote, setNameWrote] = useState(false);
  const [isDayWrote, setDayWrote] = useState(false);
  const [isMonthWrote, setMonthWrote] = useState(false);
  const [isYearWrote, setYearWrote] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);
  const [isAccountTypeSelected, setAccountTypeSelected] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    day: "",
    month: "",
    year: "",
    gender: "",
    policy_1: false,
    policy_2: false,
    usertype: "",
    refId: referralCode,
  });

  useEffect(() => {
    setFormData({ ...formData, ["refId"]: referralCode });
  }, [referralCode]);

  const handleSignup = () => {
    dispatch(signUp(formData));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "email") setEmailWrote((prevState) => true);
    if (name === "name") setNameWrote((prevState) => true);
    if (name === "password") setPasswordWrote((prevState) => true);
    if (name === "usertype") setAccountTypeSelected((prevState) => true);
    if (name === "day") setDayWrote((prevState) => true);
    if (name === "month") setMonthWrote((prevState) => true);
    if (name === "year") setYearWrote((prevState) => true);
  };

  useEffect(() => {
    if (status === "failed" && code === "409") {
      toast.error("Email already in use. Login instead.", { duration: 1500 });
    } else if (status === "success" && code === "201") {
      dispatch(getUserByToken(token));
    }
  }, [status]);

  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      setCode(undefined);
      setType(undefined);
      setStatus(undefined);
      setStatus(token);
      window.location.replace("/signup");
    }
  }, [performance]);

  const handleGoogle = () => {
    window.location.replace(
      `https://core.upvibez.com/api/v1/auth/user/google/signup?referralCode=${referralCode}`
    );
  };

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.authenticate) {
      navigate("/profile");
    }
  }, [auth]);

  let btns = [
    {
      text: "Signup with Google",
      icon: "https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/google_logo-google_icongoogle-512.png",
      handleOnClick: () => {
        handleGoogle();
      },
    },
    {
      text: "Signup with Facebook",
      icon: "https://upload.wikimedia.org/wikipedia/commons/6/6c/Facebook_Logo_2023.png",
    },
    {
      text: "Signup with Apple",
      icon: "https://static-00.iconduck.com/assets.00/apple-icon-1662x2048-d80o29ez.png",
    },
  ];

  const validateEmail = () => {
    const id = toast.loading("Loading...");
    const data = { email: formData.email };
    axiosInstance
      .post(`/auth/email/validate`, data)
      .then((res) => {
        if (res.status === 200) {
          setStageNo((prevStage) => prevStage + 1);
          toast.dismiss(id);
        }
      })
      .catch((err) => {
        const { data } = err?.response;
        toast.error(`${data?.msg}`, { duration: 1500, id: id });
      });
  };

  useEffect(() => {
    const minLength = 10;
    const letterRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    const numberOrSpecialCharRegex = /[0-9#?!&]/;

    if (formData.password.length < minLength) {
      setConThree(false);
    } else {
      setConThree(true);
    }

    if (!letterRegex.test(formData.password)) {
      setConeOne(false);
    } else {
      setConeOne(true);
    }
    if (!numberOrSpecialCharRegex.test(formData.password)) {
      setConTwo(false);
    } else {
      setConTwo(true);
    }
  }, [formData.password]);

  if (status === null && referralCode === null) {
    return (
      <div className=" min-h-screen flex flex-col justify-center items-center text-white">
        <img src={icon_logo} alt="" height={110} width={110} />
        <div className="mt-2 font-bold text-[48px]">
          This page is limited to specific users only.
        </div>
        <div className="uppercase text-[11px]">Try login instead.</div>
        <Link
          to={"/login"}
          className="mt-12 hover:scale-95 transition-all ease-in-out font-semibold text-black px-6 py-2 mx-auto rounded-full bg-white"
        >
          Login
        </Link>
      </div>
    );
  }

  return (
    <div className="bg-dark flex flex-col items-center text-white min-h-screen">
      <div className="flex flex-col justify-between items-center py-6 m-8 rounded-xl  w-6/12">
        <div className="flex flex-col justify-between items-center ">
          <img
            src={icon_logo}
            width={70}
            height={70}
            alt=""
            className="hover:cursor-pointer"
            onClick={() => navigate("/")}
          />
          {stageNo > 0 && (
            <div className="w-[420px] mt-3">
              <div className=" w-full grid grid-cols-4">
                <div
                  className="w-full border"
                  style={{
                    borderColor: stageNo > 0 ? "#6813f2" : "#6b7280",
                  }}
                ></div>
                <div
                  className="w-full border"
                  style={{
                    borderColor: stageNo > 1 ? "#6813f2" : "#6b7280",
                  }}
                ></div>
                <div
                  className="w-full border"
                  style={{
                    borderColor: stageNo > 2 ? "#6813f2" : "#6b7280",
                  }}
                ></div>
                <div
                  className="w-full border"
                  style={{
                    borderColor: stageNo > 3 ? "#6813f2" : "#6b7280",
                  }}
                ></div>
              </div>

              {/* STEP COUNT */}
              <div className="mt-5 flex gap-2 items-center w-full">
                <button
                  onClick={() => setStageNo((prevStage) => prevStage - 1)}
                  className="w-[50px] flex justify-end"
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>
                <div className="w-[320px]">
                  <p className="text-[16px] font-bold">Step {stageNo} of 4</p>
                  <p className="text-[16px] font-semibold">
                    {stageNo === 1
                      ? "Create a password"
                      : stageNo === 2
                      ? "You are creating account for"
                      : stageNo === 3
                      ? "Tell us about yourself"
                      : stageNo === 4
                      ? "Terms & Conditions"
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          )}
          {stageNo === 0 && (
            <div className="flex flex-col justify-between items-center ">
              <div className="flex flex-col justify-center items-center mt-4 mb-6">
                <h1 className="text-[48px] font-semibold text-center">
                  Sign up to start
                </h1>
                <h1 className="text-[48px] font-semibold mt-[-14px] text-center">
                  listening
                </h1>
              </div>
              <TextInputField1
                type={"email"}
                name={"email"}
                label={"Email address"}
                placeholder={"name@domain.com"}
                value={formData?.email}
                onChange={handleInputChange}
                warning={isEmailWrote && formData?.email === ""}
                warningText={
                  "This email is invalid. Make sure it's written like example@email.com"
                }
                style={{ marginBottom: 8 }}
              />

              <BtnFilled
                text={"Next"}
                onClick={() => validateEmail()}
                width={320}
                bgColor={"#6813f2"}
                textColor={"white"}
                isDisabled={!isEmailWrote}
              />
              <DividerWithText text={"or"} />
              <div className="flex flex-col gap-4 mt-0">
                {btns?.map((btn, ind) => {
                  return (
                    <BtnOutline
                      icon={btn?.icon}
                      text={btn?.text}
                      onClick={btn.handleOnClick}
                      width={320}
                    />
                  );
                })}
              </div>
              <Divider />
              <div className="text-[16px] flex justify-center gap-1 mb-8">
                <p className="text-gray-500">Already have an account? </p>{" "}
                <Link to={`/login`} className="underline">
                  Log in here.
                </Link>
              </div>
            </div>
          )}
          {stageNo === 1 && (
            <div className="mt-6">
              <div className="flex flex-col justify-between items-center ">
                <PasswordInputFieldWithCondotion
                  // type={"password"}
                  name={"password"}
                  label={"Password"}
                  placeholder={"Password"}
                  value={formData?.password}
                  onChange={handleInputChange}
                  con1={conOne}
                  con2={conTwo}
                  con3={conThree}
                  style={{ marginBottom: 12 }}
                  warning={isPasswordWrote && formData?.password === ""}
                  warningText={"Please choose a password."}
                />

                <BtnFilled
                  text={"Next"}
                  onClick={() => setStageNo((prevStage) => prevStage + 1)}
                  width={320}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  isDisabled={!isPasswordWrote}
                />
              </div>
            </div>
          )}
          {stageNo === 2 && (
            <div className="mt-6">
              <div className="flex flex-col justify-between items-center ">
                <RadioInputFieldWithSecondaryLabel
                  name={"usertype"}
                  label={"Select an account type:"}
                  placeholder={""}
                  value={formData?.usertype}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={userTypeOptions}
                  // isOptionsHorizontal={true}
                  warning={isAccountTypeSelected && formData?.usertype === ""}
                  warningText={"Please choose a password."}
                />

                <BtnFilled
                  text={"Next"}
                  onClick={() => setStageNo((prevStage) => prevStage + 1)}
                  width={320}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  isDisabled={!isAccountTypeSelected}
                />
              </div>
            </div>
          )}
          {stageNo === 3 && (
            <div className="mt-6">
              <div className="flex flex-col justify-between items-center ">
                <TextInputFieldWithSecondaryLabel
                  // type={"password"}
                  name={"name"}
                  label={"Name"}
                  label2={"This name will appear on your profile"}
                  placeholder={""}
                  value={formData?.name}
                  onChange={handleInputChange}
                  warning={isNameWrote && formData?.name === ""}
                  warningText={"Enter a name for your profile."}
                  style={{ marginBottom: 12 }}
                />

                <DateOfBirth
                  // type={"password"}
                  name_dd={"day"}
                  name_mm={"month"}
                  name_yy={"year"}
                  label={"Date of birth"}
                  label2={"Why do we need your date of birth? Learn more."}
                  placeholder_dd={"dd"}
                  placeholder_mm={"month"}
                  placeholder_yy={"yyyy"}
                  value_dd={formData?.day}
                  value_mm={formData?.month}
                  value_yy={formData?.year}
                  onChange={handleInputChange}
                  warning_dd={isDayWrote && formData?.day === ""}
                  warning_mm={isMonthWrote && formData?.month === ""}
                  warning_yy={isYearWrote && formData?.year === ""}
                  warningText_dd={"Please enter your date of birth."}
                  warningText_mm={"Select your birth month."}
                  warningText_yy={
                    "Please enter the year of your birth date using four digits (e.g., 1990)."
                  }
                  style={{ marginBottom: 12 }}
                />

                <RadioInputFieldWithSecondaryLabel
                  name={"gender"}
                  label={"Gender"}
                  label2={
                    "We use your gender to help personalize our content recommendations and ads for you."
                  }
                  placeholder={""}
                  value={formData?.gender}
                  onChange={handleInputChange}
                  style={{ marginBottom: 28 }}
                  options={genderOptions}
                  isOptionsHorizontal={true}
                />

                <BtnFilled
                  text={"Next"}
                  onClick={() => setStageNo((prevStage) => prevStage + 1)}
                  width={320}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  isDisabled={
                    !isDayWrote && !isMonthWrote && !isYearWrote && !isNameWrote
                  }
                />
              </div>
            </div>
          )}
          {stageNo === 4 && (
            <div className="mt-6">
              <div className="flex flex-col justify-between items-center ">
                <PolicyCheckout
                  // type={"password"}
                  name={"policy_1"}
                  label={
                    "I would prefer not to receive marketing messages from Upvibez"
                  }
                  value={formData?.policy_1}
                  onChange={handleInputChange}
                  style={{ marginBottom: 12 }}
                  textColor={""}
                />
                <PolicyCheckout
                  // type={"password"}
                  name={"policy_2"}
                  label={
                    "Share my registration data with Upvibez's content providers for marketing purposes."
                  }
                  value={formData?.policy_2}
                  onChange={handleInputChange}
                  style={{ marginBottom: 12 }}
                  textColor={""}
                />

                <div className="w-[320px] text-[14px] mt-6">
                  <p>
                    By clicking on sign-up, you agree to Upvibez's{" "}
                    <Link
                      target="_blank"
                      to={"/terms-and-condition"}
                      className="text-primary underline"
                    >
                      {" "}
                      Terms and Conditions of Use.
                    </Link>
                    {/* <a href=""></a>  */}
                  </p>
                  <p className="mt-4">
                    To learn more about how Upvibez collects, uses, shares and
                    protects your personal data, please see{" "}
                    <Link
                      target="_blank"
                      to={"/privacy-policy"}
                      className="text-primary underline"
                    >
                      {" "}
                      Upvibez's Privacy Policy.
                    </Link>
                    {/* <a href="" className="text-primary underline">
                     
                    </a> */}
                  </p>
                </div>

                <BtnFilled
                  text={"Signup"}
                  onClick={handleSignup}
                  width={320}
                  bgColor={"#6813f2"}
                  textColor={"white"}
                  style={{ marginTop: 24 }}
                  // isDisabled={!(formData?.policy_2 && formData?.policy_1)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-6">
          <p className="text-[11px] w-[320px] text-center text-gray-500">
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signup;
