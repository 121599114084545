import { subConstant } from "../actions/constants";

const initState = {
  plan: {},
  loading: false,
  error: null,
  subDetails: {},
  status: null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case subConstant.SUB_PLAN_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
        status: null,
      };
      break;
    case subConstant.SUB_PLAN_SUCCESS:
      state = {
        ...state,
        loading: false,
        plan: action.payload,
        error: null,
        status: null,
      };
      break;
    case subConstant.SUB_PLAN_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        status: null,
      };
      break;
    case subConstant.SUB_CREATE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
        status: null,
      };
      break;
    case subConstant.SUB_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        plan: action.payload,
        error: null,
        status: null,
      };
      break;
    case subConstant.SUB_CREATE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        status: null,
      };
      break;
    case subConstant.SUB_STRIPE_CREATE_REQUEST:
      state = {
        ...state,
        loading: true,
        error: null,
        status: null,
      };
      break;
    case subConstant.SUB_STRIPE_CREATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        error: null,
        subDetails: action.payload,
        status: "success",
      };
      break;
    case subConstant.SUB_STRIPE_CREATE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
        status: null,
      };
      break;
  }
  return state;
};
