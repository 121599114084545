import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const ManageYourPlan = () => {
  const navigate = useNavigate();
  const currentPlan = {
    planName: "Upvibez Free",
    features: [
      "1 Free account",
      "Music listening with ad breaks",
      "Streaming only",
      "Songs play in shuffle",
      "Basic audio quality",
    ],
    price: "Free",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white ">
        <h1 className="lg:text-[50px] sm:text-[36px]  font-bold tracking-tighter mt-2 mb-4">
          Manage your subscription
        </h1>
        <div className="my-12">
          <div className="bg-[#1F1F1F] rounded-[8px]">
            <div className="bg-[#B3B3B3] p-6 flex items-center h-[144px] rounded-tl-[8px] rounded-tr-[8px]">
              <h1 className="lg:text-[36px] sm:text-[30px] font-bold tracking-tighter text-black">
                {" "}
                {currentPlan?.planName}
              </h1>
            </div>
            <div className="">
              <ul className="px-12 py-8 list-disc">
                {currentPlan?.features?.map((feature, index) => (
                  <li key={index} className="mb-1">
                    {feature}
                  </li>
                ))}
              </ul>
              <div className="border-t border-t-gray-700 mx-2">
                <h2 className="lg:text-[24px] sm:text-[20px] p-6 font-bold tracking-tighter">
                  {currentPlan?.price}
                </h2>
              </div>
            </div>
          </div>

          <div className="mt-6 flex">
            <Link
              to={"/subscription/purchase"}
              className=" rounded-full px-8 py-3 border border-gray-200 text-[14px] font-bold"
            >
              Join Premium
            </Link>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageYourPlan;
