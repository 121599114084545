import React from "react";
import { Link } from "react-router-dom";

import { icon_logo } from "../assets/logo";
import Navbar from "../component/shared/Navbar";

const NotFound = () => {
  return (
    <>
      <Navbar />
      <div className=" min-h-screen flex flex-col justify-center items-center text-white">
        <img src={icon_logo} alt="" height={110} width={110} />
        <div className="mt-2 font-bold text-[48px]">Page not found</div>
        <div className="uppercase text-[11px]">
          We can’t seem to find the page you are looking for.
        </div>
        <Link
          to={"/profile"}
          className="mt-12 hover:scale-95 transition-all ease-in-out font-semibold text-black px-6 py-2 mx-auto rounded-full bg-white"
        >
          Profile
        </Link>
      </div>
    </>
  );
};

export default NotFound;
