import toast from "react-hot-toast";
import axiosInstance from "../helpers/axios";
import { playlistConstant } from "./constants";

export const createPlaylist = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: playlistConstant.CREATE_PLAYLIST_REQUEST });
      const res = await axiosInstance.post(`/user/playlist/create`, data);

      if (res.status === 201) {
        dispatch({
          type: playlistConstant.CREATE_PLAYLIST_SUCCESS,
          payload: res?.data?.playlist,
        });
        dispatch(getPlaylist());
      }
    } catch (error) {
      dispatch({ type: playlistConstant.CREATE_PLAYLIST_FAILURE });
      toast.error("Can't perform this action. Try again later.");
    }
  };
};

export const getPlaylist = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: playlistConstant.GET_PLAYLIST_REQUEST });
      const res = await axiosInstance.get(`/user/playlist/get`);

      if (res.status === 200) {
        dispatch({
          type: playlistConstant.GET_PLAYLIST_SUCCESS,
          payload: res?.data?.playlists,
        });
      }
    } catch (error) {
      dispatch({ type: playlistConstant.GET_PLAYLIST_FAILURE });
      toast.error("Can't perform this action. Try again later.");
    }
  };
};

export const getPlaylistById = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: playlistConstant.GET_PUBLIC_PLAYLIST_REQUEST });
      const res = await axiosInstance.get(`/public/playlist/get?id=${id}`);

      if (res.status === 200) {
        dispatch({
          type: playlistConstant.GET_PUBLIC_PLAYLIST_SUCCESS,
          payload: res?.data,
        });
      }
    } catch (error) {
      dispatch({ type: playlistConstant.GET_PUBLIC_PLAYLIST_FAILURE });
      toast.error("Can't perform this action. Try again later.");
    }
  };
};
