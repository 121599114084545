import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axiosInstance from "../../redux/helpers/axios";
import { BtnFilled } from "../../component/button";
import ReactModal from "react-modal";
import { AddCard } from "../../component/payment/add-card";
import {
  aexcard,
  dot_gif,
  jcbcard,
  mastercard,
  visacard,
} from "../../assets/images";

const SavedPaymentCards = () => {
  const navigate = useNavigate();

  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(window.localStorage.getItem("user"));

  useEffect(() => {
    axiosInstance
      .get(`/payment/stripe/get-publishable-key`)
      .then(async (res) => {
        const { publishableKey } = await res.data;
        setStripePromise(loadStripe(publishableKey));
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = { customer: user.stripeId };
    axiosInstance
      .post(`/payment/stripe/get-payment-methods`, data)
      .then(async (res) => {
        const { methods } = await res.data;
        setPaymentMethods(methods);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAddCardModal = () => {
    const data = { customer: user.stripeId, email: user.email };
    axiosInstance
      .post(`/payment/stripe/create-setup-intent`, data)
      .then(async (res) => {
        const { clientSecret } = await res.data;
        setClientSecret(clientSecret);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="">
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <div className="h-[52px] w-[52px] rounded-full hover:scale-110 transition-all ease-in-out flex justify-center items-center text-white bg-[#2A2A2A] hover:bg-[#1F1F1F]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="size-7"
          >
            <path
              fillRule="evenodd"
              d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </button>
      <div className="text-white max-w-[480px] mx-auto">
        <h1 className="text-[36px] font-bold tracking-tighter mt-2 mb-0">
          Saved payment cards
        </h1>
        <p className="text-[13px] mt-2">
          Manage your payment details for one-time purchases. To manage payment
          details for your monthly subscription, go to{" "}
          <Link to={"/account/overview"} className="underline">
            Account overview
          </Link>{" "}
          .
        </p>
        {loading ? (
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        ) : (
          <div className="mt-12">
            <div className="flex justify-between items-center">
              <h2 className="text-[18px] font-bold">My cards</h2>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
            </div>
            {paymentMethods.length > 0 &&
              paymentMethods?.map((method, index) => (
                <div className="mt-6 px-4 py-5 border-[0.45px] border-gray-600 rounded-lg flex justify-between items-center">
                  <div className="flex items-center gap-2 font-semibold tracking-tighter text-white">
                    {method?.card?.brand === "mastercard" ? (
                      <img
                        src={mastercard}
                        height={34}
                        width={34}
                        alt=""
                        className=" bg-white px-1 rounded"
                      />
                    ) : (
                      <img
                        src={visacard}
                        height={34}
                        width={34}
                        alt=""
                        className=" bg-white px-1 rounded"
                      />
                    )}
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                    />
                  </svg> */}
                    <p>**** **** **** {method?.card?.last4}</p>
                    {/* <p>|</p> */}
                    <p>
                      {method?.card?.exp_month}/{method?.card?.exp_year}
                    </p>
                    {/* <p>|</p>
                  <p>{method?.card?.funding?.toUpperCase()}</p> */}
                  </div>
                  <button className="text-primary font-bold text-[16px] tracking-tighter">
                    Remove
                  </button>
                </div>
              ))}

            <div className="flex justify-center items-center">
              <BtnFilled
                text={"Add New Card"}
                onClick={() => handleAddCardModal()}
                width={320}
                bgColor={"#6813f2"}
                textColor={"white"}
                style={{ marginTop: "18px" }}
              />
            </div>
          </div>
        )}
      </div>
      <ReactModal
        isOpen={show}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            // backgroundColor: "#1A1A1A",
            backgroundColor: "#070033",
            borderRadius: 8,
            border: "none",
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          },
        }}
        onRequestClose={() => {
          setShow(false);
        }}
      >
        <div className="h-[290px] w-[590px] px-4 py-2 text-white">
          <div className="flex  justify-between w-full">
            <p className="text-[22px]">Add a new card</p>

            <svg
              onClick={() => {
                setShow(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="size-6 p-1 cursor-pointer hover:scale-90 hover:bg-red-500 rounded-full  transition-all ease-in-out"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18 18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div className="p-6 rounded-xl">
            {stripePromise && clientSecret && (
              <Elements
                stripe={stripePromise}
                options={{ clientSecret: clientSecret }}
              >
                <AddCard
                  clientSecret={clientSecret}
                  user={user}
                  setShow={setShow}
                />
              </Elements>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default SavedPaymentCards;
