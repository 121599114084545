import React, { useEffect, useState } from "react";
import Navbar from "../component/shared/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { icon_logo, logopngw } from "../assets/logo";
import {
  PasswordInputField1,
  PasswordInputFieldWithCondotion,
  TextInputField1,
} from "../component/form/InputField";
import { BtnFilled, BtnOutline1 } from "../component/button";

const NewPassword = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [isPasswordChanged, setPasswordChanged] = useState(false);
  const [conOne, setConeOne] = useState(false);
  const [conTwo, setConTwo] = useState(false);
  const [conThree, setConThree] = useState(false);
  const [isPasswordWrote, setPasswordWrote] = useState(false);
  const [match, setMatch] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "password") setPasswordWrote((prevState) => true);
  };

  useEffect(() => {
    if (formData.password !== formData.confirm_password) {
      setMatch(false);
    } else {
      setMatch(true);
    }
  }, [formData]);

  const handleCreateNewPasswordAndLogin = () => {
    setPasswordChanged(false);
  };
  const handleBackToHome = () => {
    navigate("/home");
  };

  if (auth.authenticate) {
    navigate("/profile");
  }

  useEffect(() => {
    const minLength = 10;
    const letterRegex = /^(?=.*[a-z])(?=.*[A-Z]).*$/;
    const numberOrSpecialCharRegex = /[0-9#?!&]/;

    if (formData.password.length < minLength) {
      setConThree(false);
    } else {
      setConThree(true);
    }

    if (!letterRegex.test(formData.password)) {
      setConeOne(false);
    } else {
      setConeOne(true);
    }
    if (!numberOrSpecialCharRegex.test(formData.password)) {
      setConTwo(false);
    } else {
      setConTwo(true);
    }
  }, [formData.password]);

  return (
    <div className="bg-dark  min-h-screen flex flex-col justify-between items-center text-white">
      <div className=" flex flex-col justify-center max-w-[320px] mt-4">
        <div className="flex justify-center items-center mt-12">
          <img src={icon_logo} alt="" height={70} width={70} />
        </div>
        {!isPasswordChanged ? (
          <>
            <h1 className="mt-2 font-bold text-[30px]">Create new password</h1>

            <p className="text-[14px] mt-2 mb-6">
              Please enter your new password below for your Upvibez account.
            </p>

            <PasswordInputFieldWithCondotion
              // type={"password"}
              name={"password"}
              label={"New Password"}
              placeholder={""}
              value={formData?.password}
              onChange={handleInputChange}
              con1={conOne}
              con2={conTwo}
              con3={conThree}
              style={{ marginBottom: 36 }}
              warning={isPasswordWrote && formData?.password === ""}
              warningText={"Please choose a password."}
            />

            <PasswordInputField1
              // type={"password"}
              name={"confirm_password"}
              label={"Confirm New Password"}
              placeholder={""}
              value={formData?.confirm_password}
              onChange={handleInputChange}
              warning={!match}
              warningText={"Password should match."}
              style={{ marginBottom: 12 }}
            />

            <Link to={"/support"} className="text-[13px] underline mb-6">
              Need Support?
            </Link>

            <BtnFilled
              text={"Create Password"}
              onClick={() => handleCreateNewPasswordAndLogin()}
              width={320}
              bgColor={"#6813f2"}
              textColor={"white"}
              style={{ marginTop: 4 }}
              isDisabled={!match}
            />
          </>
        ) : (
          <>
            <h1 className="mt-2 font-bold text-[32px]">You're all set</h1>

            <p className="text-[14px] mt-2 mb-6">
              Your password has been successfully updated, you are now logged
              in.
            </p>

            <BtnFilled
              text={"Start listening"}
              onClick={() => handleBackToHome()}
              width={320}
              bgColor={"#6813f2"}
              textColor={"white"}
              style={{ marginTop: 4 }}
            />
          </>
        )}
      </div>
      <div className="mt-4 bg-dark py-9 flex justify-center items-center w-full px-4">
        <a href="" className="text-[11px] text-gray-500">
          This site is protected by reCAPTCHA and the Google Privacy Policy and
          Terms of Service apply.
        </a>
      </div>
    </div>
  );
};

export default NewPassword;
