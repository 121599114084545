import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  return (
    <ModalContext.Provider
      value={{
        isVisible,
        showModal,
        hideModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
