import React, { useEffect, useState } from "react";
import { linkArray } from "../../assets/data/homeFooterLink";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeaturedTracks,
  getPlaylistById,
  getTracksByGenre,
} from "../../redux/actions";
import { dot_gif } from "../../assets/images";
import LeftNav from "../../component/home/LeftNav";
import Navbar from "../../component/shared/Navbar";
import PlaylistSection from "../../component/playlist/PlaylistSection";
import Footer from "../../component/shared/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useAudio } from "../../component/context/audio";
import { useModal } from "../../component/context/modal";
import ReactModal from "react-modal";
import { TextInputField1 } from "../../component/form/InputField";
import toast from "react-hot-toast";
import axiosInstance from "../../redux/helpers/axios";

const LikedPlaylist = () => {
  const {
    isPlaying,
    setTrackList,
    setCurrentTrackIndex,
    playAudio,
    pauseAudio,
    currentTrack,
  } = useAudio();

  const { showModal } = useModal();

  const dispatch = useDispatch();
  const location = useLocation();

  const tracks = location.state.tracks;

  const [deviceScreenType, setDeviceScreenType] = useState("none");
  const [fullPageWidth, setFullPageWidth] = useState(-1);
  const [navRatio, setNavRatio] = useState(0.2);
  const [show, setShow] = useState(false);

  useEffect(() => {
    let fullPageWidth = window.innerWidth;
    setFullPageWidth(fullPageWidth);

    const handleResize = () => {
      if (window) {
        const fullPageWidth = window?.innerWidth;

        if (fullPageWidth < 768) {
          setDeviceScreenType("sm");
          setNavRatio(0);
        } else if (fullPageWidth < 1024) {
          setDeviceScreenType("md");
          setNavRatio(0.08);
        } else {
          setDeviceScreenType("lg");
          setNavRatio(0.2);
        }
      }
    };

    // Call the function on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window?.innerWidth]);

  const playlist = useSelector((state) => state.playlist);
  const song = useSelector((state) => state.song);

  return (
    <div className="bg-dark  min-h-screen ">
      {fullPageWidth !== -1 && deviceScreenType !== "none" ? (
        <div className="min-h-screen relative ">
          {/* NAV */}
          <Navbar />

          <div className="flex gap-0 mt-2 relative min-h-screen">
            {/* FIRST BOX */}
            <div
              style={{ width: window.innerWidth * navRatio }}
              className="lg:block md:block sm:hidden relative px-2 "
            >
              <LeftNav
                deviceScreenType={deviceScreenType}
                linkArray={linkArray}
              />
            </div>
            <div className="border-2 border-globalBg lg:block md:block sm:hidden"></div>
            {/* SECOND BOX */}
            <div
              style={{ width: window.innerWidth * (1 - navRatio) }}
              className="rounded-lg "
            >
              <PlaylistSection
                data={{
                  img: "https://upvibez.s3.me-central-1.amazonaws.com/_mg_9158-(1).jpg",
                  name: "Liked Song",
                  artists: ["Liked Song"],
                  saves: 0,
                  playtime: "3 hr 45 min",
                  songs: [...tracks],
                  bg: { hex: "#B764E2" },
                  type: "Liked",
                }}
                //temporary
                // song={song?.tracksByGenre}
                deviceScreenType={deviceScreenType}
                isPlaying={isPlaying}
                setTrackList={setTrackList}
                setCurrentTrackIndex={setCurrentTrackIndex}
                playAudio={playAudio}
                pauseAudio={pauseAudio}
                showModal={showModal}
                currentTrack={currentTrack}
                setShow={setShow}
              />
              <div className="bg-globalBg py-[2px] rounded-lg">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center mt-4">
            <img src={dot_gif} className="h-[100px]" />
          </div>
        </>
      )}
    </div>
  );
};

export default LikedPlaylist;
